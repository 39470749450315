import qs from 'query-string'
import {useLocation,useHistory} from 'react-router-dom'

export function useParameter(){
  const {search} = useLocation()
  return qs.parse(search)
}

export function useCustomHistory(){
  const history = useHistory()
  return history
}                                    