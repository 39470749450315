const NavigationReducer = (prevState={
  show:false,
},action)=>{
  var newStete = {...prevState}
  switch(action.type){
    case "NAVIGATIONHIDE" :
      newStete.show = action.value
      return newStete 
    case "NAVIGATIONSHOW" :
      newStete.show = action.value
      return newStete
    default:
      return newStete
  }
}
export default NavigationReducer