// Transition
import './index.less';
import {useState,useEffect,useRef} from 'react'
import {useNavigation} from '@/hooks/useNavigation.js'
import api from '@/api'
import {useWalletBill} from '@/hooks/user.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TopHead from '@/components/TopHead'
import { useInView } from "react-cool-inview";
import {useHistory,Link} from 'react-router-dom'
export default function Transition(){
  useNavigation()
  const histroy = useHistory()
  const {acquire} = useWalletBill()
  const [value,setValue] = useState(0)
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const [formData,setFormData] = useState({
    page:1,
    limit:10,
  })
  useEffect(()=>{
    handleWalletBills()
  },[formData,value])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
      handleWalletBills()
    },
  });
  const handleWalletBills = async ()=>{ 
    const {data,code,msg,total:count} = await acquire({...formData,bills_type:value})
    setTotal(count)
    if(data.length == undefined) {
      return 
    } 
    setList([...list,...data])
  }
  const handlePush = (e)=>{
    if(e.source_pay == 'tran')return 
    histroy.push(
      `/user/check/?bills_id=${e.bills_id}&type=${e.source_pay}&id=${e.source_id}`
    )
  }
  const handleChange = (event, newValue) => {
    setList([])
    setValue(newValue);
    var obj = {...formData}
    obj.page = 1
    setFormData(obj)
  };
  return (
    <div className='transition'>
      <TopHead title='記錄' />
      <div className="transition-main">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="已獲得的時分" value={1} />
          <Tab label="已使用的時分" value={0}/>
        </Tabs>
        <div className="transition-main_list">
          {list.map((item,index)=><div ref={index === list.length - 1 ? observe : null} key={item.bills_id} className="list-item" onClick={()=>handlePush(item)}>
            {/*  to={`/user/check/?id=${item.bills_id}`} */}
            <div className="list-item_left">
              <div className="left-date">
                {item.create_time}
              </div>
              <div className="left-text">
                {item.msg}
              </div>
            </div>
            <div className="list-item_right">
              {item.re_tb}時分
            </div>
          </div>)}
        </div>
      </div>
    </div>
  )
}

