// personal
import './index.less';
import React ,{useState,useEffect,useImperativeHandle} from 'react'
import { useLocation,useHistory } from 'react-router-dom';
import api from '@/api'
import {useUserInfo} from '@/hooks/user.js'
import Avatar from '@mui/material/Avatar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconSchool from '@/assets/images/user/school.svg'
import IconPets from '@/assets/images/user/pets.svg'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import {mainType} from '@/utils/district.js'
import {addTime} from '@/utils/shifttime.js'
const drawerBleeding = 0;
import ProhibitBack from '@/components/ProhibitBack'
const Personal = (props,ref)=>{
  const {acquire} = useUserInfo()
  const dispatch = useDispatch()
  const [open,setOpen] = useState(false)
  const [detail,setDetail] = useState()
  // 社区
  const [community,setCommunity] = useState()
  // 证书
  const [certList,setCertList] = useState([])
  const [info,setInfo] = useState(Object)
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      if(!e.uid)return
      setOpen(true)
      handleInfoView(e.uid)
    }
  }))
  const handleInfoView = async(uid)=>{
    const {data,code,msg} = await acquire(uid)
    var type = mainType.filter(item=>item.value == data.user_type)[0].type
    setCommunity(type)
    setInfo(data)
    var imgObj = [];
    for(var i in data.cert_list){
      imgObj.push(api.url + data.cert_list[i].cert_img)
    }
    setCertList(imgObj)
  }
  const handleClose = (newOpen)=>{
    setOpen(newOpen)
    if(!props.close)return 
    props?.close()
  }
  // 预览
  const handlePreview = (index)=>{
    if(window.plus){
      window.plus.nativeUI.previewImage([...certList],{
        current:index,
        loop:true,
        onLongPress:function(e){
          outLine('onLongPress: '+JSON.stringify(e));
          showActionSheet();
        }
      });
    }
  }
  return (
    <>
      <ProhibitBack open={open} close={()=>handleClose(false)} />
      <SwipeableDrawer
        className="personal-dialog"
        // container={container}
        anchor="bottom"
        open={open}
        onClose={()=>handleClose(false)}
        onOpen={()=>handleClose(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="personal-main">
          <div className="personal-main_top">
          </div>
          <div className="personal-main_card">
            <div className="card-left">
              <Avatar src={api.url + info?.image} />
              <div className="card-name">
                {info?.username}
              </div>
            </div>
            <div className="card-right">
              <div className="card-list">
                <div className="card-list_item">
                  <div className="item-type">
                    {community}會員
                  </div>
                </div>
                <div className="card-list_item">
                  <div className="item-text">
                    {certList.length || 0}個
                  </div>
                  <div className="item-des">
                    已獲機構親自認證的資歷
                  </div>
                </div>
                <div className="card-list_item">
                  <div className="item-text">
                    {addTime(info?.create_time)}
                  </div>
                  <div className="item-des">
                    加入時間
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="personal-main_info">
            <div className="info-item">
              <div className="info-item_label">
                <img className="info-item_img" src={IconSchool} alt="" />
                <div className="info-item_text">
                  {/* Studied in abc where-ever school */}
                  {info?.edu_level}
                </div>
              </div>
            </div>
            {/* <div className="info-item">
              <div className="info-item_label">
                <img className="info-item_img" src={IconDistance} alt="" />
                <div className="info-item_text">
                  {info?.address}
                </div>
              </div>
            </div> */}
            <div className="info-item">
              <div className="info-item_label">
                <img className="info-item_img" src={IconPets} alt="" />
                <div className="info-item_text">
                  {info?.personal_skills}
                </div>
              </div>
            </div>
          </div>
          <div className="personal-main_intro">
            {info?.introduction}
          </div>
          <div className="personal-mian_read">
            閱讀更多
          </div>
          <div className="personal-mian_img">
            {certList.map((item,index)=><img key={index} src={item} alt="" onClick={()=>handlePreview(index)} />)}
          </div>
          <div className="personal-main_btn">
            {props.children}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  )
}

export default Personal