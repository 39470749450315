import request,{url} from './request'
const user = {
  getCommitPlatForm :params => request({url:'/common/platform',method: 'get', params}),
  // Login
  PostUserLogin :data => request({url:'/user/login',method: 'post', data}),
  // Get verification code
  GetUseSmsCode :params => request({url:'/user/sms/code',method: 'get', params}),
  // Sign in
  PostUserRegister :data => request({url:'/user/register',method: 'post', data}),
  // user/forget/password
  PostUserForgetPassword :data => request({url:'/user/forget/password',method: 'post', data}),
  // Get information
  GetUserInfo :params => request({url:'/user/info',method: 'get', params}),
  // upload
  PostCommonFile :data => request({url:'/common/file',method: 'post', data}),
  // Modify data
  PostUserInfo :data => request({url:'/user/info',method: 'post', data}),
  // Get the contact list
  GetUserFamily :params => request({url:'/user/family',method: 'get', params}),
  // Add Contact Information
  PostUserFamily :data => request({url:'/user/family',method: 'post', data}),
  // Wallet balance
  GetUserWallet :params => request({url:'/user/wallet',method: 'get', params}),
  // collect
  PostUserCollect :data => request({url:'/user/collect',method: 'post', data}),
  // user/collect/event
  GetUserCollectEvent :params => request({url:'/user/collect/event',method: 'get', params}),
  // user/family/detail
  GetUserFamilyDetail :params => request({url:'/user/family/detail',method: 'get', params}),
  // user/family
  PutUserFamily :data => request({url:'/user/family',method: 'put', data}),
  // user/collect/active
  GetUserCollectActive :params => request({url:'/user/collect/active',method: 'get', params}),
  // user/wallet/qr
  GetUserWalletQr :params => request({url:'/user/wallet/qr',method: 'get', params}),
  // user/wallet/tran
  PostUserWalletTran :data => request({url:'/user/wallet/tran',method: 'post', data}),
  // user/exchange/tb
  PostUserExchangeTb :data => request({url:'/user/exchange/tb',method: 'post', data}),
  PostUserExchangeSysTb :data => request({url:'/user/exchange/sys/tb',method: 'post', data}),
  // common/rates
  GetCommonRates :params => request({url:'/common/rates',method: 'get', params}),
  // 獲取證書 user/cert
  GetUserCert :params => request({url:'/user/cert',method: 'get', params}),
  /**
  * 添加證書
  * @description PostUserCert 
  */
  PostUserCert :data => request({url:'/user/cert',method: 'post', data}),
  // 用户在线时长记录
  PostUserOnlineLog :data => request({url:'/user/online/log',method: 'post', data}),
  // 修改密碼
  PostUserPassword :data => request({url:'/user/password',method: 'post', data}),
  // 刪除聯係人
  DeleteUserFamily:data => request({url:'/user/family',method: 'delete', data}),
};
const home = {
  // event list
  GetCommonEvent :params => request({url:'/common/event',method: 'get', params}),
  // product
  GetCommonProduct :params => request({url:'/common/product',method: 'get', params}),
  // event details 
  GetCommonEventDetail :params => request({url:'/common/event/detail',method: 'get', params}),
  // active details 
  GetCommonEventActive :params => request({url:'/common/user/active',method: 'get', params}),
  GetCommonEventActiveDetail :params => request({url:'/common/user/active/detail',method: 'get', params}),
  // 获取用户区区通消费使用情况
  GetUserPublicMoneyLimit :params => request({url:'/user/public/money/limit',method: 'get', params}),
  // 混合列表common/product/union
  GetCommonProductUnion :params => request({url:'/common/product/union',method: 'get', params}),
  // 获取产品详情
  GetCommonProductDetail :params => request({url:'/common/product/detail',method: 'get', params}),
  // 获取混合活动
  GetCommonEventUnion :params => request({url:'/common/event/union',method: 'get', params}),
  // 获取活动列表
  GetAdminProduct :params => request({url:'/common/product',method: 'get', params}),
}
const shop = {
  // shop cart list
  GetCart :params => request({url:'/cart',method: 'get', params}),
  // add shop cart
  PostCart :data => request({url:'/cart',method: 'post', data}),
  PutCart :data => request({url:'/cart',method: 'put', data}),
  DeleteCart :data => request({url:'/cart',method: 'delete', data}),
  // payment
  PostCartPay :data => request({url:'/cart/pay',method: 'post', data}),
  // user/wallet/bills
  GetUserWalletBills :params => request({url:'/user/wallet/bills',method: 'get', params}),
  // cart/bills
  GetCartBills :params => request({url:'/cart/bills',method: 'get', params}),
  // 获取我的购物车购买详情
  GetCartBuyDetail :params => request({url:'/cart/buy/detail',method: 'get', params}),
}

const shear = {
  // user/event
  PostUserEvent :data => request({url:'/user/event',method: 'post', data}),
  GetUserEvent :params => request({url:'/user/event',method: 'get', params}),
  PutUserEvent :data => request({url:'/user/event',method: 'put', data}),
  PostUserEventJoin :data => request({url:'/user/event/join',method: 'post', data}),
  PostUserInfoView :data => request({url:'/user/info/view',method: 'post', data}),
  // 活動支付
  PostUserEventPay :data => request({url:'/user/event/pay',method: 'post', data}),
  // 請求活動支付
  PostUserRequestPayment :data => request({url:'/user/event/request/payment',method: 'post', data}),
  PostUserEventJoinStart :data => request({url:'/user/event/join/start',method: 'post', data}),
  PostUserEventJoinEnd :data => request({url:'/user/event/join/end',method: 'post', data}),
  GetUserEventJoinList :params => request({url:'/user/event/join/list',method: 'get', params}),
  GetUserEventServiceList :params => request({url:'/user/event/service/list',method: 'get', params}),
  // user/event/join/score
  PostUserEventJoinScore :data => request({url:'/user/event/join/score',method: 'post', data}),
  // 獲取報名參加用戶列表
  GetUserEventUserList :params => request({url:'/user/event/user/list',method: 'get', params}),
  // 選擇參加活動的用戶 選擇還是拒絕
  PostUserEventJoinChoose :data => request({url:'/user/event/join/choose',method: 'post', data}),
  // 取消交易
  PostUserEventJoinCancel :data => request({url:'/user/event/join/cancel',method: 'post', data}),
  // 获取交易活动详情
  GetUserEventJoinDetail :params => request({url:'/user/event/join/detail',method: 'get', params}),
}
export default {
  user,
  home,
  shop,
  shear,
  url
}