// 主題色
import { useEffect,useState } from "react";
import { useSelector } from 'react-redux';
export default function useSubject(user_type){
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const [subject,setSubject] = useState('#F16526')
  useEffect(()=>{
    var type = user_type || redusxUser.user_type;
    var main,background,border,card;
    if(type == 2){ // 香港聖公會麥理浩夫人中心
      main = '#056E00'
      background = '#E6FFE6'
      border = '#84DA7F'
      card = '#E6FFDC'
    }else if(type == 3){ // 關注草根生活聯盟
      main = '#A71D6B'
      background = '#FBE9F3'
      border = '#E7A4C9'
      card = '#FFE4EC'
    }else{ // 香港婦女中心協會
      main = '#F16526'
      background = '#FFEDE7'
      border = '#D8C2BC'
      card = '#FDEEE7'
    }
    setSubject(main)
    document.documentElement.style.setProperty(
      "--primary-color",
      main,
    );
    document.documentElement.style.setProperty(
      "--primary-background",
      background
    );
    document.documentElement.style.setProperty(
      "--primary-border",
      border
    );
    document.documentElement.style.setProperty(
      "--primary-card-background",
      card
    );
  },[redusxUser,user_type])
  return {subject}
}