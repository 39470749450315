import './index.less'
import {forwardRef,useRef} from 'react'
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import TopHead from '@/components/TopHead'
import api from '@/api'
import {MethodOmit} from '@/utils/custom'
import WcIcon from '@/assets/images/home/WC.svg'
import Wc2Icon from '@/assets/images/home/WC2.svg'
import Wc3Icon from '@/assets/images/home/WC3.svg'
import TransferDialog from './components/Transfer'
export default function Transfer(){
  const TransferDiv = forwardRef(TransferDialog)
  const TransferRef = useRef(null)
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const redusxSysAddress = useSelector((state)=>{
    return state.UserRd.address
  })
  const handleTransfer = ()=>{
    TransferRef.current.handleOpen()
  }
  return (
    <div className="transfer">
      <TopHead title='轉帳給機構' />
      <div className="transfer-main">
        <div className="transfer-main_item">
          <div className="transfer-title">
            由你的時分錢包
          </div>
          <div className="transfer-card">
            <Avatar src={api.url + redusxUser.image} alt={redusxUser.username}/>
            <div className="transfer-card_info">
              <div className="transfer-card_info-name">
                {redusxUser.username}
              </div>
              <div className="transfer-card_info-address">
                {MethodOmit(redusxSysAddress)}
              </div>
            </div>
          </div>
        </div>
        <div className="transfer-main_item" onClick={handleTransfer}>
          <div className="transfer-title">
            轉帳至
          </div>
          <div className="transfer-card">
            {redusxUser.user_type == 1 &&<Avatar src={WcIcon}/>}
            {redusxUser.user_type == 2 &&<Avatar src={Wc2Icon}/>}
            {redusxUser.user_type == 3 &&<Avatar src={Wc3Icon}/>}
            <div className="transfer-card_info">
              <div className="transfer-card_info-name">
                {redusxUser.user_type == 1 && '香港婦女中心協會 (麗閣邨)'}
                {redusxUser.user_type == 2 && '關注草根生活聯盟'}
                {redusxUser.user_type == 3 && '聖公會麥理浩夫人'}
              </div>
            </div>
            <div className="transfer-card_right">
              
            </div>
          </div>
        </div>
      </div>
      <TransferDiv ref={TransferRef} />
    </div>
  )
}