import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import './index.less';
export default function CodeMD({url}){
  return (
    <div className="code-md">
      <QRCodeCanvas
        value={url}
        className='qrcode'
        style={{
          width: 304,
          height: 304,
          zIndex: 296,
          position: 'relative'
        }}
        level={'L'} // 可选，可以接受7,15,25,30程度的容错级别，例如'L', 'M', 'Q', 'H'
        bgColor='transparent' // 设置二维码背景为透明
      />
    </div>
  )
}