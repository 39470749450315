import './index.less'
import React,{useEffect,useState,useImperativeHandle } from 'react'
import { useSelector } from 'react-redux';
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import Dialog from '@mui/material/Dialog';
import { useApplicant } from '@/hooks/sharing.js'
import api from '@/api'
import {useHistory,Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux'
import IntegerInput from '@/components/IntegerInput'
import {tipsReveal} from '@/redux/actions'
function PaymentDialog(props,ref){
  const {open,setOpen,refresh,price} = props
  const {payment,requestPayment} = useApplicant()
  const histroy = useHistory()
  const paramter = useParameter()
  const dispatch = useDispatch()
  const [formData,setFormData] = useState({
    wait_pay_money:Number,
    join_id:paramter.join_id
  })
  // useImperativeHandle(ref,()=>({
  //   handleOpen:(e) => {
  //     setFormData({...formData,['wait_pay_money']:e})
  //     setOpen(true)
  //   }
  // }))
  useEffect(()=>{
    setFormData({...formData,['wait_pay_money']:price})
  },[price])
  const handleClose = ()=>{
    setOpen(false)            
  }
  const handleNext = async()=>{
    const {data,code,msg} = await requestPayment(formData)
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    handleClose()
    refresh()
  }
  return (
    <>
      <Dialog className="transfer-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="transfer-top"></div>
        <div className="transfer-main">
        <IntegerInput label={'時分*'}  value={formData.wait_pay_money} name="wait_pay_money" formData={formData} setFormData={setFormData} />
          <Button disabled={!formData.wait_pay_money} variant="contained" color="primary" onClick={handleNext}>
            確定
          </Button>
        </div>
      </Dialog>
    </>
  )
}
export default React.memo(PaymentDialog)