import './index.less'
import {useParameter} from '@/hooks/useParameter.js'
import TopHead from '@/components/TopHead'
export default function OtherCode(){
  const paramter = useParameter()
  return (
    <div className="other-code">
      <TopHead title='掃描結果' />
      <div className="other-code_main">
        {paramter.value}
      </div>
    </div>
  )
}