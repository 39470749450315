import './index.less'
import React,{useEffect,useState,useImperativeHandle } from 'react'
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import api from '@/api'
import {useHistory,Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import {tipsReveal,BalanceAction} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import {mainType} from '@/utils/district.js'
const TransferDialog = (props,ref) => {
  const histroy = useHistory()
  const [open,setOpen] = useState(false)
  const dispatch = useDispatch()
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const [formData,setFormData] = useState({
    wallet_id:'',
    money:'',
  })
  useEffect(()=>{
    setFormData({...formData,['wallet_id']:redusxUser.user_type})
  },[redusxUser])
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setOpen(true)
    }
  }))
  const handleClose = ()=>{
    var obj = formData
    obj.money = ''
    setFormData(obj)
    // setFormData({ ...formData, ['money']: '' })
    setOpen(false)            
  }
  const handleNext = async()=>{
    if(!formData.wallet_id)return 
    if(!formData.money)return 
    const {data,code,msg} = await api.user.PostUserWalletTran(formData)
    console.log(data,code,msg)
    if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:'Successful',type:'success'}))
    dispatch(BalanceAction())
    handleClose()
  }
  return (
    <>
      <Dialog className="transfer-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="transfer-top"></div>
        {/* <div className="transfer-title">
          轉賬
        </div> */}
        <div className="transfer-main">
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-label">
              轉賬機構*
            </InputLabel>
            <Select
              name='gender'
              value={formData.wallet_id}
              disabled
              onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            >
              {mainType.map(item=><MenuItem key={item.value} value={item.value}>{item.type}</MenuItem>)}
              <MenuItem value={'女'}>女</MenuItem>
            </Select>
            {formData.gender || formData?'':<FormHelperText>
              請選擇轉賬機構
            </FormHelperText>}
          </FormControl>
          <TextField
            label={`金額*`}
            name='money'
            type="number"
            variant="outlined"
            
            defaultValue={formData.money}
            onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            helperText={!formData.money && '请输入转账金额'}
          />
          <Button disabled={(formData.money <= 0 || !formData.money)} variant="contained" color="primary" onClick={handleNext}>
            轉賬
          </Button>
        </div>
        
      </Dialog>
    </>
  )
}
export default TransferDialog