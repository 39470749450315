import {useState,useEffect,useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useJsqr} from '@/hooks/user.js'
import {useParameter} from '@/hooks/useParameter.js'
import './index.less'
import api from '@/api'
import ScanCodeMD from '@/components/ScanCodeMD'
import TopHead from '@/components/TopHead'
export default function ScanCode(){
  const paramter = useParameter()
  const histroy = useHistory()
  const { recognize } = useJsqr()
  const fileRef = useRef(null)
  useEffect(()=>{
    console.log(paramter.id)
  },[])
  const feedback = (e)=>{
    histroy.replace(`/user/scanResult/?value=${e}&id=${paramter.id}`)
  }
  const handleRecognize = async (event)=>{
    const code = await recognize(event);
    histroy.push(`/user/scanResult/?value=${code}&id=${paramter.id}`)
  }
  // 選擇二維碼
  const handlePhotoAlbum = ()=>{
    if(window.plus){
      window.plus.gallery.pick((path) => {
        window.plus.barcode.scan(
          path,
          (type,code, result) => {
            result = result.replace(/\n/g, "");
            histroy.push(`/user/scanResult/?value=${result}`)
          },
          () => {
            plus.nativeUI.toast("未找到二維碼", {
              duration: "short",
            });
          }
        );
      }),
        { filters: "images" };
    }else{
      fileRef.current.click()
    }
  }
  const handleQrCode = ()=>{
    histroy.push(`/user/qrCode`)
  }
  return (
    <div className="scanCode">
      <TopHead title='掃描QR code'>
        {window.plus && <span onClick={handlePhotoAlbum}>相冊</span>}
      </TopHead>
      {/* <video ref={videoRef} autoPlay style={{ width: 300 }} /> */}
      <ScanCodeMD feedback={feedback} />
      <canvas id="qrCanvas" ></canvas>
      <input accept="image/*" id="icon-banner-file" className="icon-button-file" ref={fileRef} type="file" onChange={handleRecognize}/>
      <div className="scan-code_btn">
        <div htmlFor="icon-banner-file" onClick={handlePhotoAlbum}>
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="35" fill="#FFEDE7"/>
            <g clip-path="url(#clip0_123_36647)">
            <path d="M47 13V31H29V13H47ZM47 10H29C27.35 10 26 11.35 26 13V31C26 32.65 27.35 34 29 34H47C48.65 34 50 32.65 50 31V13C50 11.35 48.65 10 47 10ZM34.25 24.505L36.785 27.895L40.505 23.245L45.5 29.5H30.5L34.25 24.505ZM20 16V37C20 38.65 21.35 40 23 40H44V37H23V16H20Z" fill="#CE4C09"/>
            </g>
            <path d="M27.725 51.765H33.26V53.055H27.725V51.765ZM27.74 55.365H33.275V56.655H27.74V55.365ZM27.725 58.965H33.275V60.255H27.725V58.965ZM27.035 48.195H33.95V61.095H32.525V49.545H28.4V61.155H27.035V48.195ZM20.72 50.505H26.435V51.855H20.72V50.505ZM23.045 47.34H24.41V61.26H23.045V47.34ZM22.97 51.39L23.855 51.69C23.345 54.135 22.265 57.03 21.065 58.56C20.93 58.17 20.585 57.6 20.36 57.255C21.47 55.92 22.52 53.445 22.97 51.39ZM24.29 52.89C24.725 53.31 26.24 55.11 26.585 55.53L25.745 56.685C25.31 55.89 24.14 54.225 23.615 53.55L24.29 52.89ZM36.995 48.24H47.48V49.635H38.39V61.305H36.995V48.24ZM46.73 48.24H48.14V59.655C48.14 60.345 48.02 60.75 47.6 60.99C47.18 61.23 46.595 61.26 45.71 61.26C45.665 60.885 45.47 60.255 45.275 59.88C45.815 59.91 46.34 59.895 46.505 59.895C46.685 59.895 46.73 59.82 46.73 59.64V48.24ZM35.54 53.655H49.49V55.035H35.54V53.655ZM40.22 48.69H41.54V61.08H40.22V48.69ZM43.445 48.69H44.78V61.08H43.445V48.69Z" fill="#CE4C09"/>
            <defs>
            <clipPath id="clip0_123_36647">
            <rect width="36" height="36" fill="white" transform="translate(17 7)"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <svg onClick={handleQrCode} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="35" cy="35" r="35" fill="#EFFFEA"/>
          <path d="M18 24.6667V10H32.6667V24.6667H18ZM21.6667 21H29V13.6667H21.6667V21ZM18 43V28.3333H32.6667V43H18ZM21.6667 39.3333H29V32H21.6667V39.3333ZM36.3333 24.6667V10H51V24.6667H36.3333ZM40 21H47.3333V13.6667H40V21ZM47.3333 43V39.3333H51V43H47.3333ZM36.3333 32V28.3333H40V32H36.3333ZM40 35.6667V32H43.6667V35.6667H40ZM36.3333 39.3333V35.6667H40V39.3333H36.3333ZM40 43V39.3333H43.6667V43H40ZM43.6667 39.3333V35.6667H47.3333V39.3333H43.6667ZM43.6667 32V28.3333H47.3333V32H43.6667ZM47.3333 35.6667V32H51V35.6667H47.3333Z" fill="#056E00"/>
          <path d="M23.87 53.07H33.665V54.27H23.87V53.07ZM23.495 55.62H34.28V56.835H23.495V55.62ZM25.97 51.765H27.275V56.235H25.97V51.765ZM29.96 51.765H31.295V56.22H29.96V51.765ZM22.025 48.03H23.465V52.455C23.465 55.05 23.24 58.83 21.665 61.29C21.38 61.08 20.75 60.705 20.405 60.57C21.905 58.23 22.025 54.855 22.025 52.455V48.03ZM22.895 48.03H33.425V51.765H22.895V50.535H32V49.245H22.895V48.03ZM29.12 56.085C29.945 58.155 31.79 59.58 34.46 60.09C34.175 60.375 33.8 60.9 33.62 61.275C30.77 60.555 28.925 58.89 27.935 56.355L29.12 56.085ZM32.54 56.79L33.605 57.525C32.795 58.14 31.73 58.74 30.92 59.145L30.02 58.455C30.8 58.035 31.91 57.315 32.54 56.79ZM24.77 61.305L24.71 60.255L25.265 59.805L29.225 58.965C29.165 59.325 29.12 59.865 29.135 60.15C25.565 60.99 25.07 61.125 24.77 61.305ZM24.77 61.32C24.695 60.99 24.425 60.42 24.23 60.15C24.515 59.985 24.98 59.595 24.98 58.89V56.175L26.3 56.22V59.85C26.3 59.85 24.77 60.81 24.77 61.32ZM41.75 52.785H43.295V59.52C43.295 60.375 43.1 60.78 42.5 61.02C41.885 61.23 40.955 61.26 39.635 61.26C39.575 60.825 39.35 60.195 39.125 59.76C40.085 59.805 41.105 59.805 41.39 59.79C41.66 59.79 41.75 59.715 41.75 59.49V52.785ZM38.255 54.735L39.74 55.125C39.035 56.955 37.88 58.755 36.74 59.895C36.455 59.64 35.795 59.235 35.435 59.04C36.605 58.005 37.655 56.37 38.255 54.735ZM45.17 55.275L46.49 54.735C47.54 56.13 48.68 58.005 49.115 59.28L47.66 59.925C47.285 58.68 46.205 56.73 45.17 55.275ZM37.205 48.39H47.795V49.8H37.205V48.39ZM35.855 52.02H49.16V53.43H35.855V52.02Z" fill="#056E00"/>
        </svg>
      </div>
    </div>
  )
}