import api from '@/api'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'

// 活動
export function usePublishEvent(){
  const dispatch = useDispatch()
  // 發佈
  const publish = async(formData)=>{
    const {data,code,msg} = await api.shear.PostUserEvent(formData)
    if(code != 0) dispatch(tipsReveal({message:'發佈失敗',type:'error'}))
    if(code == 0) dispatch(tipsReveal({message:'發佈成功',type:'success'}))
    return {data,code,msg}
  }
  // 修改
  const edit = async(formData)=>{
    const {data,code,msg} = await api.shear.PutUserEvent(formData)
    if(code != 0) dispatch(tipsReveal({message:'發佈失敗',type:'error'}))
    if(code == 0) dispatch(tipsReveal({message:'發佈成功',type:'success'}))
    return {data,code,msg}
  }
  return {publish,edit}
}

// 申請人
export function useApplicant(){
  const dispatch = useDispatch()
  /**
   * 請求申請人列表
   * @param {object} formData - 配置对象
   * @param {string} formData.event_id - 活動id
   * @param {number} formData.is_choose - 0 报名还未审核的列表 1 审核通过的列表
   */
  const request = async(formData)=>{
    const data = await api.shear.GetUserEventUserList(formData);
    return {...data}
  }
  /**
   * 處理申請人
   * @param {object} formData - 配置对象
   * @param {string} formData.event_id - 活動id
   * @param {number} formData.uid_list - 申請人id列表
   * @param {number} formData.is_choose - 拒绝2同意1
   */
  const handle = async(formData)=>{
    const {data,code,msg} = await api.shear.PostUserEventJoinChoose(formData);
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    return {data,code,msg} 
  }
  /**
   * 報名參加活動
   * @param {string} event_id - 活動id
   */
  const singup = async(event_id)=>{
    const {data,code,msg} = await api.shear.PostUserEventJoin({
      event_id:event_id
    })
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    return {data,code,msg}
  }
  /**
   * 取消參加活動
   * @param {string} join_id - 加入id
   */
   const cancel = async(join_id)=>{
    const {data,code,msg} = await api.shear.PostUserEventJoinCancel({
      join_id:join_id
    })
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    return {data,code,msg}
  }
  /**
   * 活動支付
   * @param {string} join_id - 加入id
   */
  const payment = async(formData)=>{
    const {data,code,msg} = await api.shear.PostUserEventPay(formData)
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    if(code == 0) dispatch(tipsReveal({message:'請求成功',type:'success'}))
    return {data,code,msg}
  }
  /**
   * 請求支付
   * @param {string} join_id - 加入id
   * @param {string} wait_pay_money - 待付款金额
   */
  const requestPayment = async(formData)=>{
    const {data,code,msg} = await api.shear.PostUserRequestPayment(formData)
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    return {data,code,msg}
  }
  /**
   * 获取加入活动详情
   */
  const joinDetail = async(formData)=>{
    const {data,code,msg} = await api.shear.GetUserEventJoinDetail(formData)
    return {data,code,msg}
  }
  return {request,handle,singup,cancel,payment,requestPayment,joinDetail}
  
}