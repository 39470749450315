
import { useEffect,useState } from "react"
import api from '@/api'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import './index.less';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import IconClose from '@/assets/images/shop/close.svg'
import subtract from '@/assets/images/shop/subtract.svg'
import add from '@/assets/images/shop/add.svg'
import Precise from '@/utils/precise'
import TopHead from '@/components/TopHead'
import Vegetable from '@/views/sharing/details'
export default function Check(){
  const paramter = useParameter()
  const [list,setList] = useState([])
  useEffect(()=>{
    if(paramter.type == 'event'){
      console.log(12334)
    }else{
      handleCartBills()
    }
  },[])
  const handleCartBills = async ()=>{
    const {data,code,msg} = await api.shop.GetCartBills({
      bills_id:paramter.bills_id,
    })
    console.log(data,code,msg)
    if(data.length == undefined)return 
    setList([...data])
  }
  const handlePrice = (e)=>{
    console.log(e)
    if(e.is_veg == 0){
      var num = Precise(e.price , Math.floor(e.num))
      return num
    }else{
      if(e.per_units == 'jin'){
        return Number(e.num * e.price)
      }else if(e.per_units == 'jin_liang'){
        return Number(e.num * 10 * e.price)
      }
    }
    
  }
  return (
    <>
      <div className="check">
        <TopHead title='消费明细' />
        {paramter.type == 'event' && <div className="check-event"><Vegetable /></div>}
        {paramter.type != 'event' && <div className="check-main">
          {list.map((item,index)=>item.is_veg == 0 ?<ProductBox key={item.product_id} item={item} handlePrice={handlePrice} />:<VegetableBox key={item.product_id} item={item} handlePrice={handlePrice}/>)}
        </div>}
      </div>
    </>
  )
}

function ProductBox({item,handlePrice}){
  return (
  <>
    <div key={item.product_id} className="cart-content-item">
      {/* <img src={IconClose} alt="" className='content-item_close'  /> */}
      <div className="content-item_left">
        <img className="content-item_left-img" src={api.url + item.banner} alt="" />
        <div className="content-item_left-info">
          <div className="item-info-title">
            <span>{item.vegetable_name}</span>
          </div>
          <div className="item-info-container">
            <IconButton color="primary" disabled={item.amount<=1}>
              <img src={subtract} alt="" />
            </IconButton>
            <div className="container_amount">
              {Math.floor(item.num)}
            </div>
            <IconButton color="primary">
              <img src={add} alt="" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="content-item_right">
        <div className="content-item_right-money">
          {handlePrice(item)} <span className='right-money_unit'>時分</span>
        </div>
      </div>
    </div>
  </>
  )
}

function VegetableBox({item,handlePrice}){
  const [jin,setJin] = useState(0)
  const [liang,setLiang] = useState(0)
  useEffect(()=>{
    var count = Number(item.num)
    setJin(count)
    // var jin_string = String(count).split('.')[0]
    // var liang_string = String(count).split('.')[1]
    // setJin(Number(jin_string))
    // setLiang(Number(liang_string))
  },[item])
  const handleChange = (event)=>{
    var num;
    if(event.target.name == 'jin'){
      if(Number(liang) == 0){
        num = Number(event.target.value);
      }else{
        num = Number(event.target.value) + Number(liang) / 10;
      }
    }else{
      if(event.target.value > 10){
        num = jin + 9 / 10;
      }else{
        num = jin + Number(event.target.value) / 10;
      }
    }
  }
  return (
    <>
      <div className="vegetable-card">
        <div className="vegetable-card_title">
          <span>{item.product_type}</span>
        </div>
        <div className="vegetable-card_main">
          <div className="vegetable-card_main-input">
          <FormControl>
              <Input
                id="standard-adornment-weight"
                endAdornment={<InputAdornment position="end">
                  {item.per_units == 'jin' ? '斤' : '兩'}
                </InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="jin"
                type="number"
                value={jin}
                onChange={handleChange}
                disabled
              />
            </FormControl>
            {/* <FormControl>
              <Input
                id="standard-adornment-weight"
                endAdornment={<InputAdornment position="end">斤</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="jin"
                type="number"
                value={jin}
                onChange={handleChange}
                disabled
              />
            </FormControl>
            <FormControl>
              <Input
                id="standard-adornment-weight"
                endAdornment={<InputAdornment position="end">兩</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="liang"
                type="number"
                maxRows={9}
                value={liang}
                onChange={handleChange}
                disabled
              />
            </FormControl> */}
          </div>
          <div className="vegetable-card_main-money">
            {/* {Number(item.num * item.price)} */}
            {handlePrice(item)}
            <span>時分</span>
          </div>
        </div>
      </div>
    </>
  )
}