import React from 'react'
import TextField from '@mui/material/TextField';
const IntegerInput = ({label,value,name,status,formData,setFormData})=>{
  const handleChange = (event)=>{
    const newValue = event.target.value;
    const newName = event.target.name
    if (newValue === '' || /^[0-9]+$/.test(newValue)) {
      setFormData({ ...formData, [newName]: newValue })
    }
  }
  return (
    <>
      <TextField
        label={label}
        variant="outlined"
        type="number"
        inputProps={{
          inputMode: 'numeric', // 使键盘为数字模式
          pattern: '[0-9]*', // 允许的输入模式
        }}
        name={name}
        value={value}
        onChange={(event)=>handleChange(event)}
        helperText={value || status?'':`請輸入${label}`}
      />
    </>
  )
}

export default IntegerInput