import {useEffect} from 'react'
import Mrouter from '@/router'
import './App.less'
import {Prompt,useLocation } from 'react-router-dom'
import Navigation from '@/components/Navigation'
import AlertTips from '@/components/AlertTips'
import { createTheme,ThemeProvider } from '@mui/material/styles';
import '@/i18n/config'; // 引用配置文件
import { useDispatch,useSelector } from 'react-redux';
import useSubject from '@/hooks/useSubject.js'
import {UserAction,ShopAction,BalanceAction} from '@/redux/actions'
import {getToken,removeToken} from '@/utils/auth'
import BurialPoint from '@/utils/burialPoint'
var theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#F16526',
    },
  },
});
function App() {
  const dispatch = useDispatch()
  const {subject} = useSubject()
  theme.palette.primary.main = subject
  const redusxData = useSelector((state)=>{
    return state.NavigationRD.show
  })
  useEffect(() => {
    document.body.classList.toggle('dark');
    if(getToken()){
      dispatch(UserAction())
      dispatch(BalanceAction())
      dispatch(ShopAction())
      BurialPoint()
    }
  }, [getToken()]);
  const handleleave = ()=>{
    console.log(1234)
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <AlertTips />
        <Mrouter>
          <Prompt
            when={false}
            message={() =>handleleave()}
          />
          {redusxData && <Navigation />}
        </Mrouter>
      </ThemeProvider>
    </>
  )
}

export default App
