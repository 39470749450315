import React ,{useState,useEffect,useImperativeHandle} from 'react'
import {useLocation,useHistory,Prompt  } from 'react-router-dom'
export default function ProhibitBack({close,open}){
  const location = useLocation();
  const history = useHistory()
  useEffect(() => {
    const preventBackNavigation = (e) => {
      e.preventDefault();
      history.push(location.pathname + location.search);
    };
    if(open){
      window.addEventListener('popstate', preventBackNavigation);
    }
    return () => {
      window.removeEventListener('popstate', preventBackNavigation);
    };
  }, [history,open]);
  return (
    <Prompt
      when={open} message={(location, action)=>close()}/>
  )
}

// export default React.memo(ProhibitBack)