import api from '@/api'
import './index.less';
import { useEffect,useState } from 'react'
import {useNavigation} from '@/hooks/useNavigation.js'
import {useHistory} from 'react-router-dom'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import Avatar from '@mui/material/Avatar';
import Card from '@/views/home/Card'
import ProductCard from '@/components/ProductCard'
import TopHead from '@/components/TopHead'
import { useInView } from "react-cool-inview";
export default function Collect (){
  useNavigation()
  const paramter = useParameter()
  const histroy = useCustomHistory()
  const [toggle,setToggle] = useState("list") // grid
  const [total,setTotal] = useState(0)
  const [list,setList] = useState([])
  const [value, setValue] = useState(paramter.active?Number(paramter.active) : 0);
  const [formData,setFormData] = useState({
    page:1,
    limit:10,
  })
  useEffect(()=>{
    handleDetailList()
  },[formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      console.log(1234)
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  const handleToggle = (e)=>{
    setToggle(e)
  }
  const handleDetailList = ()=>{
    if(value == 0){
      handleEventList()
    }else{
      handleActiveList()
    }
  }
  const handleEventList = async ()=>{
    const {data,code,msg,total:count} = await api.user.GetUserCollectEvent({...formData})
    console.log(data,code,msg)
    setTotal(count)
    if(data.length == undefined)return setList([])
    setList([...list,...data])
  }
  const handleActiveList = async ()=>{
    const {data,code,msg,total:count} = await api.user.GetUserCollectActive({...formData})
    setTotal(count)
    if(data.length == undefined)return setList([])
    setList([...list,...data])
  }
  const handleChange = (event, newValue) => {
    histroy.replace(`/user/collect/?active=${newValue}`)
    setValue(newValue);
  };
  return (
    <>
      <div className="collect">
        <TopHead title='我的最愛' />
        <div className="collect-tap">
          <span className={toggle == 'list'?'collect-tap_active':''} onClick={()=>handleToggle('list')}>
            <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 1.5H24" stroke="#B1B1B1" stroke-width="3" stroke-linecap="round"/>
              <path d="M2 11.5H24" stroke="#B1B1B1" stroke-width="3" stroke-linecap="round"/>
              <path d="M2 21.5H24" stroke="#B1B1B1" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </span>
          <span className={toggle == 'grid'?'collect-tap_active':''} onClick={()=>handleToggle('grid')}>
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_106_35733" fill="white">
              <rect width="13.1143" height="13.1143" rx="3"/>
              </mask>
              <rect width="13.1143" height="13.1143" rx="3" stroke="#B1B1B1" stroke-width="8" mask="url(#path-1-inside-1_106_35733)"/>
              <mask id="path-2-inside-2_106_35733" fill="white">
              <rect x="13.8857" width="13.1143" height="13.1143" rx="3"/>
              </mask>
              <rect x="13.8857" width="13.1143" height="13.1143" rx="3" stroke="#B1B1B1" stroke-width="8" mask="url(#path-2-inside-2_106_35733)"/>
              <mask id="path-3-inside-3_106_35733" fill="white">
              <rect x="13.8857" y="13.8857" width="13.1143" height="13.1143" rx="3"/>
              </mask>
              <rect x="13.8857" y="13.8857" width="13.1143" height="13.1143" rx="3" stroke="#B1B1B1" stroke-width="8" mask="url(#path-3-inside-3_106_35733)"/>
              <mask id="path-4-inside-4_106_35733" fill="white">
              <rect y="13.8857" width="13.1143" height="13.1143" rx="3"/>
              </mask>
              <rect y="13.8857" width="13.1143" height="13.1143" rx="3" stroke="#B1B1B1" stroke-width="8" mask="url(#path-4-inside-4_106_35733)"/>
            </svg>
          </span>
        </div>
        {/* <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="商品" value={0} />
          <Tab label="活动" value={1} />
        </Tabs> */}
        <div className="collect-main">
          <div className="list-container">
            {list.map((item,index)=><div ref={index === list.length - 1 ? observe : null} key={item.event_id}>
              {toggle == 'list' && <ListContainer data={item}/>}
              {toggle == 'grid' && <ProductCard data={item}/>}
            </div> )}
          </div>
        </div>
      </div>
    </>
  )
}

function ListContainer({data}){
  const history = useHistory()
  const handleDetail = ()=>{
    if(data.type == 'event' || data.is_share != undefined){
      history.push(`/sharing/details/?id=${data.event_id || data.id}`)
    }else if(data.type == 'product'){
      history.push(`/shopping/productDetail/?id=${data.id}`)
    }else{
      history.push(`/shopping/vegetable/?id=${data.event_id}`)
    }
  }
  return (
    <div className="list-container_item" onClick={handleDetail}>
      <Avatar src={api.url + data?.banner} alt={data?.title} variant="square" sx={{ width: 56, height: 56 }}/>
      <div className="item-info">
        <div className="item-info_name">
          {data.title}
        </div>
        <div className="item-info_date">
          {data.create_time}
        </div>
      </div>
    </div>
  )
}

function GridContainer({data}){
  return (
    <div className="list-container">
      {data.map((row)=><Card key={row.event_id} data={row}/>)}
    </div>
  )
}