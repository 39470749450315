// 付款
import './index.less'
import api from '@/api'
import {useState,useEffect,useRef,forwardRef} from 'react'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import { useApplicant } from '@/hooks/sharing.js'
import TopHead from '@/components/TopHead'
import { useInView } from "react-cool-inview";
import Personal from '@/components/Personal'
import wtsIcon from '@/assets/images/icon/wts.png'
import Avatar from '@mui/material/Avatar';
import {whatsContact} from '@/utils/custom.js'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
export default function payment(){
  const dispatch = useDispatch()
  const paramter = useParameter()
  const histroy = useCustomHistory()
  const {request,cancel} = useApplicant()
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const [formData,setFormData] = useState({
    limit:10,
    page:1,
    event_id:paramter.id,
    is_choose:1
  })
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  useEffect(()=>{
    requestList()
  },[paramter.id,formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  const requestList = async()=>{
    const {data,code,msg,total:count} = await request({
      ...formData,
    })
    setTotal(count)
    if(data.length == undefined)return setList([])
    setList([...list,...data])
  }
  const refresh = (e)=>{
    var new_list
    console.log(e.length)
    for(var i in e){
      new_list = list.filter(item => item.uid !== e[i])
      console.log(new_list)
    }
    setTotal(total-e.length)
    setList(new_list)
  }
  // 申请者详情
  const handlePersonal = (e)=>{
    personalRef.current.handleOpen(e)
  }
   // 取消交易
   const handleCancel = async(e)=>{
    const {data,code,msg} = await cancel(e.join_id)
    if(code != 0)return 
    refresh([e.uid])
    // requestList()
  }
  // 聯絡買家
  const handleContact = (e)=>{
    if(e.bills_id)return dispatch(tipsReveal({message:'交易已完成',type:'error'}))
    // whatsContact(e.phone)
    // /sharing/send/payment
    histroy.push(`/sharing/send/payment/?id=${e.event_id}&join_id=${e.join_id}&is_share=${paramter.is_share}`)
  }
  return (
    <>
      <PersonalDiv ref={personalRef} />
      <div className="payment">
        <TopHead title={`${paramter.is_share == '1' ? '請求' :''}付款`} />
        <div className='payment-main'>
          <div className="payment-main_list">
            {list.map((item,index)=><div className="main-list_card" ref={index === list.length - 1 ? observe : null}>
              <Avatar alt={item?.username} src={api.url+item.avatar} onClick={()=>handlePersonal(item)}/>
              <div className="main-list_card-info">
                <div className="main-list_card-info-name">
                  {item.username}
                </div>
              </div>
              <div className="main-list_card-btn">
                <div onClick={()=>handleCancel(item)}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2.1875L20 19.8772" stroke="#056E00" stroke-width="3" stroke-linecap="round"/>
                    <path d="M2 20.1875L20 2.49785" stroke="#056E00" stroke-width="3" stroke-linecap="round"/>
                  </svg>
                  <span>取消交易</span>
                </div>
                <div onClick={()=>handleContact(item)}>
                  <svg className="icon-code" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_280_81310" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                    <rect width="25" height="25" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_280_81310)">
                    <path d="M3.125 11.4583V3.125H11.4583V11.4583H3.125ZM5.20833 9.375H9.375V5.20833H5.20833V9.375ZM3.125 21.875V13.5417H11.4583V21.875H3.125ZM5.20833 19.7917H9.375V15.625H5.20833V19.7917ZM13.5417 11.4583V3.125H21.875V11.4583H13.5417ZM15.625 9.375H19.7917V5.20833H15.625V9.375ZM19.7917 21.875V19.7917H21.875V21.875H19.7917ZM13.5417 15.625V13.5417H15.625V15.625H13.5417ZM15.625 17.7083V15.625H17.7083V17.7083H15.625ZM13.5417 19.7917V17.7083H15.625V19.7917H13.5417ZM15.625 21.875V19.7917H17.7083V21.875H15.625ZM17.7083 19.7917V17.7083H19.7917V19.7917H17.7083ZM17.7083 15.625V13.5417H19.7917V15.625H17.7083ZM19.7917 17.7083V15.625H21.875V17.7083H19.7917Z" fill="#056E00"/>
                    </g>
                  </svg>
                  <span>付款碼</span>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </>
  )
}