// vegetable
import './index.less'
import {useState,useEffect,useRef,forwardRef} from 'react'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useShearDetail,useCollect} from '@/hooks/home.js'
import {useApplicant} from '@/hooks/sharing.js'
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import Back from '@/assets/images/shop/back.svg'
import IconClose from '@/assets/images/shear/close.svg'
import Personal from '@/components/Personal'
import api from '@/api'
import {shiftTime} from '@/utils/shifttime'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TopHead from '@/components/TopHead'
import ApplyFor from './applyFor'
import {ServiceType} from '@/utils/custom.js'
export default function Vegetable(){
  const { t } = useTranslation()
  const {cancel} = useApplicant()
  const dispatch = useDispatch()
  const histroy = useCustomHistory()
  const paramter = useParameter()
  const { collect } = useCollect()
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  const ApplyForDiv = forwardRef(ApplyFor)
  const ApplyForRef = useRef(null)
  const [dateList,setDateList] = useState([])
  const {details,refresh} = useShearDetail(paramter.id)
  const [userData,setUserData] = useState({
    event_id:'',
    uid:''
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    if(!details.key)return
    console.log(details.key)
    // console.log(JSON.parse(details.key))
    setDateList(JSON.parse(details?.key))
  },[details])
  const handleShopping = ()=>{
    console.log(details.event_type)
    if(details.event_type == "vegetable"){
      histroy.push(`/shopping/food/?id=${details.event_id}`)
    }else{
      histroy.push(`/shopping/shop/?id=${details.event_id}`)
    }
  }
  useEffect(()=>{
    personalRef.current.handleOpen(userData)
  },[userData])
  const handlePersonal = (e)=>{
    personalRef.current.handleOpen(details)
  }
  const handleClose = ()=>{
    window.history.go(-1)
  }
  const handleEdit = () =>{
    histroy.push(`/sharing/shearForm/?type=${details?.type}&id=${details?.is_share}&event_id=${details.event_id}`)
  }
  // 收藏
  const handleCollect = async()=>{
    const {data,msg,code} = await collect(details)
  }
  // 申請交易及聯絡
  const handleApplyFor = ()=> {
    ApplyForRef.current.handleAccreditOpen()
  }
  // 查看申請者
  const handleApplicant = ()=>{
    histroy.push(`/sharing/applicant/?id=${details.event_id}`)
  }
  // 取消交易
  const handleCancel = async()=>{
    console.log(details.join_id)
    const {data,code,msg} = await cancel(details.join_id)
    if(code != 0)return 
    refresh()
  }
  // 數量
  const handleNumberLabel = () =>{
    {/*is_share是1則是分享  is_product如果是1則是產品，如果是2則是活動服務*/}
    var type = details?.type && ServiceType(details?.type)
    var name;
    if(type == '產品' && details?.is_share == 1) name = '剩餘數量'
    else if(type == '服務' && details?.is_share == 1) name = '可報名人數'
    // {/* is_share是0則是請求 */}
    else if (type == '服務' && details?.is_share == 0) name = '可報名人數'
    else if(type == '產品' || details?.is_share == 0) name = '請求的物品數量'
    return name
  }
  const handleDateLabel = ()=>{
    var type = details?.type && ServiceType(details?.type)
    var name;
    if(type == '產品' && details?.is_share == 1) name = '截止購買時間'
    else if(type == '服務' && details?.is_share == 1) name = '截止請求時間'
    // {/* is_share是0則是請求 */}
    else if (type == '服務' && details?.is_share == 0) name = '截止申請時間'
    else if(type == '產品' || details?.is_share == 0) name = '截止申請時間'
    return name
  }
  return (
    <>
      <TopHead/>
      <div className='sharing-details'>
        <PersonalDiv ref={personalRef}/>
        <div className="drawer-main">
          <div className="preview-head">
            <div className="preview-head_avatar">
              <Avatar src={api.url + details?.avatar} alt={details?.username} onClick={handlePersonal} />
              <div className="avatar-name">
                {details.username}
              </div>
            </div>
          </div>
          <div className="preview-title">
            {details?.title}
          </div>
          <div className="preview-tag">
            <span>{details?.is_share == 0 && '請求'}{details?.type && ServiceType(details?.type,'name')}</span>
            <Divider orientation="vertical" flexItem />
            <span>{details?.price} {t('my.hm')}</span>
            <Divider orientation="vertical" flexItem />
            <span>{shiftTime(details?.create_time || details?.start_date)}</span>
          </div>
          <div className="preview-img">
            {details.banner && <img src={api.url + details.banner} alt="" />}
          </div>
          {details?.image && <div className="preview-img">
            {details?.image.split(",").map(item=>item && 
            <img key={item} src={api.url + item}/>)}
          </div>}
          <div className="preview-content">
            {details?.detail}
          </div>
          <div className="preview-list">
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                價格:
              </label>
              <div className="list-card_value">
                {details?.price} 時分
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {handleNumberLabel()}：
              </label>
              <div className="list-card_value">
                {details?.num_of_people}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                交收地點：
              </label>
              <div className="list-card_value">
                {details?.location}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {handleDateLabel()}：
              </label>
              <div className="list-card_value">
                {details?.end_time}
              </div>
            </div>
            {details?.is_share == 1 && <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {details?.type && ServiceType(details?.type) == '產品' ? '產品期限' : '活動期限'}：
              </label>
              <div className="list-card_value">
                {dateList[0]?.start_time} - {dateList[0]?.start_date}
              </div>
            </div>}
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.selectTime')}:
              </label>
              <div className="list-card_value">
                {details?.start_date}
              </div>
            </div>
          </div>
        </div>
      </div>
      {details.uid && <div className="sharing-details_btn">
        {redusxUser.uid != details.uid &&<>
          <Button className="button-icon" variant="contained" color="primary" onClick={handleCollect}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_143_23532" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
              </mask>
              <g mask="url(#mask0_143_23532)">
              <path d="M12 21.0004L10.55 19.7004C8.86667 18.1837 7.475 16.8754 6.375 15.7754C5.275 14.6754 4.4 13.6879 3.75 12.8129C3.1 11.9379 2.64583 11.1337 2.3875 10.4004C2.12917 9.66706 2 8.91706 2 8.15039C2 6.58372 2.525 5.27539 3.575 4.22539C4.625 3.17539 5.93333 2.65039 7.5 2.65039C8.36667 2.65039 9.19167 2.83372 9.975 3.20039C10.7583 3.56706 11.4333 4.08372 12 4.75039C12.5667 4.08372 13.2417 3.56706 14.025 3.20039C14.8083 2.83372 15.6333 2.65039 16.5 2.65039C18.0667 2.65039 19.375 3.17539 20.425 4.22539C21.475 5.27539 22 6.58372 22 8.15039C22 8.91706 21.8708 9.66706 21.6125 10.4004C21.3542 11.1337 20.9 11.9379 20.25 12.8129C19.6 13.6879 18.725 14.6754 17.625 15.7754C16.525 16.8754 15.1333 18.1837 13.45 19.7004L12 21.0004Z" fill="white"/>
              </g>
            </svg>
          </Button>
          {/*
            is_choose報名狀態 空 未報名 1 賣家同意 2 賣家拒絕
           */}
          {(details.is_choose !== 0 && details.is_choose !== 1 || details.is_choos==='') && <Button className="button-flex-2" variant="contained" color="primary" onClick={()=>handleApplyFor()}>
            申請交易及聯絡
          </Button>}
          {(details.is_choose === 0 || details.is_choose === 1) && <Button className="button-flex-2" variant="contained" color="primary" onClick={()=>handleCancel()}>
            取消交易
          </Button>}
        </>
        }
        {redusxUser.uid == details.uid && <>
          <Button variant="outlined" color="primary" onClick={handleEdit}>
            修改資料
          </Button>
          <Button disabled={details.is_approve == 2} variant="contained" color="primary" onClick={handleApplicant}>
            查看申請者
          </Button>
        </>}
      </div>}
      <ApplyForDiv ref={ApplyForRef} refresh={refresh} />
    </>
  )
}

