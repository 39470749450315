import {useState,useEffect,useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useJsqr} from '@/hooks/user.js'
import jsQR from 'jsqr'
import { useSelector } from 'react-redux';
import './index.less'
import api from '@/api'
import TopHead from '@/components/TopHead'
import CodeMD from '@/components/CodeMD'
export default function QrCode(){
  const histroy = useHistory()
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const [qrCodeData, setQrCodeData] = useState(redusxUser.uid);
  const { recognize } = useJsqr()
  const fileRef = useRef(null)
  useEffect(()=>{
    setQrCodeData('https://?' + redusxUser.uid + '?addFamily')
  },[redusxUser])
  useEffect(()=>{
    // handleQrCode()
  },[])
  const handleQrCode = async ()=>{
    const {data,code,msg} = await api.user.GetUserWalletQr()
    // setQrCodeData(data.qr)
  }
  const handlePush = (e)=>{
    histroy.push(e)
  }
  const handleRecognize = async (event)=>{
    const code = await recognize(event);
    histroy.push(`/user/scanResult/?value=${code}`)
  }
  // 選擇二維碼
  const handlePhotoAlbum = ()=>{
    if(window.plus){
      window.plus.gallery.pick((path) => {
        console.log(path)
        window.plus.barcode.scan(
          path,
          (type,code, result) => {
            console.log(code)
            result = result.replace(/\n/g, "");
            histroy.push(`/user/scanResult/?value=${code}`)
          },
          (error) => {
            console.log(error.message,error.code)
            plus.nativeUI.toast("未找到二維碼", {
              duration: "short",
            });
          }
        );
      }),
        { filters: "images" };
    }else{
      fileRef.current.click()
    }
  }
  return (
    <div className="qrCode">
      <TopHead title='我的QR code' />
      <canvas id="qrCanvas" ></canvas>
      <input accept="image/*" ref={fileRef} id="icon-banner-file" className="icon-button-file" type="file" onChange={handleRecognize}/>
      {/* <video ref={videoRef} autoPlay style={{ width: 300 }} /> */}
      <div className="qr-code_main">
        <CodeMD url={qrCodeData} />
      </div>
      <div className="qr-code_btn">
        <div onClick={handlePhotoAlbum}>
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="35" fill="#FFEDE7"/>
            <g clip-path="url(#clip0_123_36647)">
            <path d="M47 13V31H29V13H47ZM47 10H29C27.35 10 26 11.35 26 13V31C26 32.65 27.35 34 29 34H47C48.65 34 50 32.65 50 31V13C50 11.35 48.65 10 47 10ZM34.25 24.505L36.785 27.895L40.505 23.245L45.5 29.5H30.5L34.25 24.505ZM20 16V37C20 38.65 21.35 40 23 40H44V37H23V16H20Z" fill="#CE4C09"/>
            </g>
            <path d="M27.725 51.765H33.26V53.055H27.725V51.765ZM27.74 55.365H33.275V56.655H27.74V55.365ZM27.725 58.965H33.275V60.255H27.725V58.965ZM27.035 48.195H33.95V61.095H32.525V49.545H28.4V61.155H27.035V48.195ZM20.72 50.505H26.435V51.855H20.72V50.505ZM23.045 47.34H24.41V61.26H23.045V47.34ZM22.97 51.39L23.855 51.69C23.345 54.135 22.265 57.03 21.065 58.56C20.93 58.17 20.585 57.6 20.36 57.255C21.47 55.92 22.52 53.445 22.97 51.39ZM24.29 52.89C24.725 53.31 26.24 55.11 26.585 55.53L25.745 56.685C25.31 55.89 24.14 54.225 23.615 53.55L24.29 52.89ZM36.995 48.24H47.48V49.635H38.39V61.305H36.995V48.24ZM46.73 48.24H48.14V59.655C48.14 60.345 48.02 60.75 47.6 60.99C47.18 61.23 46.595 61.26 45.71 61.26C45.665 60.885 45.47 60.255 45.275 59.88C45.815 59.91 46.34 59.895 46.505 59.895C46.685 59.895 46.73 59.82 46.73 59.64V48.24ZM35.54 53.655H49.49V55.035H35.54V53.655ZM40.22 48.69H41.54V61.08H40.22V48.69ZM43.445 48.69H44.78V61.08H43.445V48.69Z" fill="#CE4C09"/>
            <defs>
            <clipPath id="clip0_123_36647">
            <rect width="36" height="36" fill="white" transform="translate(17 7)"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <svg onClick={()=>handlePush('/user/scancode')} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="35" cy="35" r="35" fill="#FFEDE7"/>
          <path d="M22.1977 19.7881V13.7184C22.1743 10.9544 23.0546 10.1103 25.9831 10H32.4406" stroke="#CE4C09" stroke-width="3"/>
          <path d="M22.1977 29.2803V35.35C22.1743 38.114 23.0546 38.9581 25.9831 39.0684H32.4406" stroke="#CE4C09" stroke-width="3"/>
          <path d="M47.9192 19.7881V13.7184C47.9427 10.9544 47.0624 10.1103 44.1338 10H37.6763" stroke="#CE4C09" stroke-width="3"/>
          <path d="M47.9192 29.2803V35.35C47.9427 38.114 47.0624 38.9581 44.1338 39.0684H37.6763" stroke="#CE4C09" stroke-width="3"/>
          <line x1="16" y1="25.0259" x2="53.872" y2="25.0259" stroke="#CE4C09" stroke-width="3"/>
          <path d="M26.525 47.835H33.17V52.62H26.39V51.555H31.835V48.9H26.525V47.835ZM25.655 49.725H32.51V50.685H25.655V49.725ZM32.33 49.665H34.205V50.715H32.33V49.665ZM25.145 53.25H34.13V56.13H32.855V54.39H26.375V56.145H25.145V53.25ZM26.135 55.68H32.705V56.775H27.395V60.21H26.135V55.68ZM32.06 55.68H33.275V58.995C33.275 59.55 33.2 59.895 32.81 60.09C32.405 60.285 31.85 60.285 31.07 60.285C31.025 59.94 30.875 59.475 30.725 59.145C31.25 59.16 31.7 59.16 31.865 59.16C32.015 59.145 32.06 59.115 32.06 58.965V55.68ZM28.985 54.36H30.26V61.26H28.985V54.36ZM20.36 55.02C21.515 54.72 23.225 54.225 24.89 53.715L25.07 55.005C23.57 55.5 21.965 55.995 20.705 56.385L20.36 55.02ZM20.585 50.295H25.235V51.6H20.585V50.295ZM22.34 47.355H23.645V59.7C23.645 60.42 23.51 60.795 23.09 61.005C22.7 61.23 22.085 61.275 21.125 61.275C21.08 60.93 20.915 60.345 20.735 59.955C21.35 59.985 21.905 59.985 22.085 59.97C22.265 59.97 22.34 59.91 22.34 59.7V47.355ZM40.97 52.62H48.875V61.17H47.51V53.865H42.26V61.245H40.97V52.62ZM41.69 55.86H48.11V57.09H41.69V55.86ZM41.72 59.22H48.14V60.465H41.72V59.22ZM44.21 53.205H45.485V60.18H44.21V53.205ZM35.36 55.185C36.68 54.885 38.63 54.345 40.49 53.835L40.655 55.11C38.93 55.635 37.085 56.16 35.645 56.58L35.36 55.185ZM35.585 50.28H40.49V51.6H35.585V50.28ZM37.55 47.34H38.915V59.715C38.915 60.435 38.765 60.81 38.33 61.02C37.895 61.23 37.235 61.275 36.215 61.26C36.17 60.915 35.99 60.345 35.81 59.985C36.455 60 37.07 60 37.265 60C37.46 60 37.55 59.925 37.55 59.715V47.34ZM40.235 49.2H44.48V50.505H40.235V49.2ZM42.005 47.34H43.4V51.915H42.005V47.34ZM46.205 47.34H47.615V51.915H46.205V47.34ZM45.035 49.17H49.46V50.475H45.035V49.17Z" fill="#CE4C09"/>
        </svg>

      </div>
    </div>
  )
}