import api from '@/api'
import {useEffect,useState,useCallback} from 'react'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux';
export function useShopping(){
  const dispatch = useDispatch()
  // 刪除購物車内容
  const deletes = useCallback(async(id)=>{
    const {data,code,msg} = await api.shop.DeleteCart({
      product_id:id
    })
    if(code != 0) dispatch(tipsReveal({message:'Deletion failure',type:'error'}))
    return {data,code,msg}
  },[])
  // 更新購物車内容
  const upload = useCallback(async(id,updatedItem)=>{
    const {data,code,msg} = await api.shop.PutCart({
      product_id:id,
      ...updatedItem,
    })
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    return {data,code,msg}
  },[])
  return {deletes,upload}
}