import api from '@/api'
import {useEffect,useState,useCallback} from 'react'
import { useSelector } from 'react-redux'

// 本區市集
// is_publid 0 本區 1 區區通
export function useLocalList(){
  const [product,setProduct] = useState({
    data:[]
  })
  const [event,setEvent] = useState({
    data:[]
  })
  const [request,setRequest] = useState({
    data:[]
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    if(!redusxUser.user_type)return 
    getData()
  },[redusxUser])
  const getData = async()=>{
    const products = await api.home.GetCommonProductUnion({
      platform_id:redusxUser.user_type
    });
    if(products.data.length == undefined) 
      setProduct({data:[]})
    else 
      setProduct(products)
    const events = await api.home.GetCommonEventUnion({
      platform_id:redusxUser.user_type,
      // is_share:1,
      // is_publid:0
    });
    if(events.data.length == undefined) 
      setEvent({data:[]})
    else
      setEvent(events)
    const requests = await api.home.GetCommonEventActive({
      platform_id:redusxUser.user_type,
      is_share:0,
      is_publid:0
    });
    if(requests.data.length == undefined) 
      setRequest({data:[]})
    else
      setRequest(requests)
  }
  return {product,event,request}
}
// 區區通市集
export function useAllList(type){
  const [community1,setCommunity1] = useState({
    data:[]
  })
  const [community2,setCommunity2] = useState({
    data:[]
  })
  const [community3,setCommunity3] = useState({
    data:[]
  })
  const [product,setProduct] = useState({
    data:[]
  })
  useEffect(()=>{
    if(type == 'institution'){
      getDataInstitution()
    }else{
      setProduct([])
      getDataUser()
    }
  },[type])
  const getDataInstitution = async()=>{
    const communitys1 = await api.home.GetCommonEvent({
      platform_id:1
    });
    if(communitys1.data.length == undefined) setCommunity1({data:[]})
    else setCommunity1(communitys1)
    const communitys2 = await api.home.GetCommonEvent({
      platform_id:2
    });
    if(communitys2.data.length == undefined) setCommunity1({data:[]}) 
    else setCommunity2(communitys2)
    const communitys3 = await api.home.GetCommonEvent({
      platform_id:3
    });
    if(communitys3.data.length == undefined) setCommunity3({data:[]}) 
    else setCommunity3(communitys3)
    const products = await api.home.GetAdminProduct();
    if(products.data.length == undefined) setProduct({data:[]}) 
    else setProduct(products)

  }
  const getDataUser = async()=>{
    const communitys1 = await api.home.GetCommonEventActive({
      platform_id:1,
      is_share:2
    });
    if(communitys1.data.length == undefined) 
      setCommunity1({data:[]})
    else
      setCommunity1(communitys1)
    const communitys2 = await api.home.GetCommonEventActive({
      platform_id:2,
      is_share:2
    });
    if(communitys2.data.length == undefined) 
      setCommunity2({data:[]})
    else
      setCommunity2(communitys2)
    const communitys3 = await api.home.GetCommonEventActive({
      platform_id:3,
      is_share:2
    });
    if(communitys3.data.length == undefined) 
      setCommunity3({data:[]})
    else
      setCommunity3(communitys3)
  }
  return {community1,community2,community3,product}
}
// 获取消费使用情况
export function useConsumption(){
  const [consumption,setConsumption] = useState({})
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    if(!redusxUser.user_type)return 
    handleConsumption()
  },[redusxUser])
  const handleConsumption = async()=>{
    const {data,code,msg} = await api.home.GetUserPublicMoneyLimit()
    setConsumption(data)
  }
  return {consumption}
}
// 活動、商品收藏
export function useCollect(){
  const collect = useCallback(async(detail)=>{
    var collect_type = 0
    if(detail.is_share == undefined){
      collect_type =0
    }else{
      collect_type = 1
    }
    const {data,code,msg} = await api.user.PostUserCollect({
      event_id:detail.event_id,
      collect_type
    })
    return {data,code,msg}
  },[])
  return {collect}
}
// 商品詳情
export function useEventDetail(id){
  const [detail,setDetail] = useState({})
  useEffect(()=>{
    AcquireEventDetail()
  },[id])
  const AcquireEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventDetail({
      event_id:id
    })
    setDetail(data)
  }
  return detail
}
// 活動詳情
export function useShearDetail(id){
  const [details,setDetail] = useState({})
  useEffect(()=>{
    refresh()
  },[id])
  const refresh = ()=>{
    AcquireEventDetail()
  }
  const AcquireEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventActiveDetail({
      event_id:id
    })
    setDetail(data)
  }
  return {details,refresh}
}

// 產品詳情
export function useProductDetail(id){
  const [detail,setDetail] = useState({})
  useEffect(()=>{
    refresh()
  },[id])
  const refresh = ()=>{
    AcquireEventDetail()
  }
  const AcquireEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonProductDetail({
      product_id:id
    })
    setDetail(data)
  }
  return {detail,refresh}
}