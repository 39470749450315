// my
import './index.less'
import {useEffect,useState,forwardRef,useRef,useCallback  } from 'react'
import {useNavigation} from '@/hooks/useNavigation.js'
import {useHistory} from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch,useSelector } from 'react-redux';
import api from '@/api'
import Iconbutton from '@/assets/images/user/Iconbutton.svg'
import IconCode from '@/assets/images/user/code.svg'
import IconState from '@/assets/images/user/state.svg'
import IconTips from '@/assets/images/user/tips.svg'
import Iconlike from '@/assets/images/user/like.svg'
import IconAdd from '@/assets/images/user/add.svg' 
import IconRight from '@/assets/images/user/right.svg' 
import Hongkong from '@/assets/images/user/Hongkong.svg'
import UnitedKingdom from '@/assets/images/user/UnitedKingdom.svg'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import {getI18n,setI18n} from '@/utils/auth'
import {UserAction,ShopAction,BalanceAction} from '@/redux/actions'
import {tipsReveal} from '@/redux/actions'
import {MethodOmit} from '@/utils/custom'
import Personal from '@/components/Personal'
import countryObj from '@/utils/countryCodeAndPhoneCode.json'
import PhoneNumber from '@/components/PhoneNumber'
import Assist from '@/components/Assist'
import useLogout from '@/hooks/useLogout.js'
export default function My(){
  useNavigation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const histroy = useHistory()
  const [value, setValue] = useState(0);
  const [familyList,setFamilyList] = useState([])
  const [connectedList,setConnectedList] = useState([])
  const [deleteType,setDeleteType] = useState()
  const [datail,setDelete] = useState()
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  useEffect(()=>{
    handleFamilyList()
    handleContactList()
    dispatch(BalanceAction())
  },[])
  useEffect(()=>{
    if(deleteType) personalRef.current.handleOpen(datail)
  },[deleteType])
  const handleFamilyList = async()=>{
    const {data,code,msg} = await api.user.GetUserFamily({
      relation_type:1
    })
    if(data.length == undefined)return setFamilyList([])
    setFamilyList(data)
  }
  const handleContactList = async()=>{
    const {data,code,msg} = await api.user.GetUserFamily({
      relation_type:2
    })
    if(data.length == undefined)return setConnectedList([])
    setConnectedList(data)
  }
  const handleTransition = () => {
    histroy.push('/user/transition')
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleUser = (e)=>{
    histroy.push(`/user/new/?progress=${e}`)
  }
  const handleFamily = (e)=>{
    histroy.push(`/user/contact?id=${e}`)
  }
  const handleFamilyEdit = (item,e)=>{
    setDeleteType(e)
    setDelete(item)
  }
  const handleClose = ()=>{
    setDeleteType('')
  }
  const handleCopy = async (e)=>{
    try {
      dispatch(tipsReveal({message:'已複製',type:'success',icon:<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.18941 1.05263V11.5789H2.65285H3.82358H8.21378V3.42105V2.36842V1.05263H1.18941ZM1.18941 0H8.21378C8.85768 0 9.3845 0.473684 9.3845 1.05263V5V5.78947V11.5789C9.3845 12.1579 8.85768 12.6316 8.21378 12.6316H2.06797H1.18941C0.545505 12.6316 0.0186768 12.1579 0.0186768 11.5789V1.05263C0.0186768 0.473684 0.545505 0 1.18941 0Z" fill="#CE4C09"/>
      <path d="M3.82358 11.5789V13.9474H10.8479V3.42105H8.21378V2.36842H10.8479C11.4918 2.36842 12.0187 2.84211 12.0187 3.42105V7.36842V8.15789V13.9474C12.0187 14.5263 11.4918 15 10.8479 15H4.70214H3.82358C3.17968 15 2.65285 14.5263 2.65285 13.9474V11.5789H3.82358Z" fill="#CE4C09"/>
      </svg>}))
      await navigator.clipboard.writeText(e);
    } catch (err) { 
      dispatch(tipsReveal({message:'Failed to copy',type:'error'}))
    }
  }
  // 刪除聯係人
  const handleFamilyDelete = async()=>{
    const {data,code,msg} = await api.user.DeleteUserFamily({
      relation_type: deleteType, 
      relation_uid: datail.uid
    })
    if(code != 0 )return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:'清除成功',type:'success'}))
    if(deleteType == 1){
      handleFamilyList()
    }else{
      handleContactList()
    }
  }
  return (
    <div className='my'>
      <PersonalDiv ref={personalRef} close={handleClose}>
        <Button variant="outlined" color="primary" onClick={()=>handleFamilyDelete()}>清除{deleteType == 2 ? '街坊' : '家人'}</Button>
      </PersonalDiv>
      <div className="my-avatar" onClick={()=>handleUser(0)}>
        <Avatar alt={redusxUser?.username} src={api.url + redusxUser?.image} />
      </div>
      {/* <div className="my-address" onClick={()=>handleCopy(redusxSysAddress)}>
        {handleOmit(redusxSysAddress)}
      </div> */}
      <div className="my-account">
        <div className="my-account_name">
          {redusxUser?.username}
        </div>
        <Divider orientation="vertical" width="1" flexItem />
        <div className="my-account_hm" onClick={handleTransition}>
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_346_21990" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
            <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_346_21990)">
            <path d="M5.5 21C4.95 21 4.47917 20.8042 4.0875 20.4125C3.69583 20.0208 3.5 19.55 3.5 19V5C3.5 4.45 3.69583 3.97917 4.0875 3.5875C4.47917 3.19583 4.95 3 5.5 3H19.5C20.05 3 20.5208 3.19583 20.9125 3.5875C21.3042 3.97917 21.5 4.45 21.5 5H13.5C12.3167 5 11.3542 5.37083 10.6125 6.1125C9.87083 6.85417 9.5 7.81667 9.5 9V15C9.5 16.1833 9.87083 17.1458 10.6125 17.8875C11.3542 18.6292 12.3167 19 13.5 19H21.5C21.5 19.55 21.3042 20.0208 20.9125 20.4125C20.5208 20.8042 20.05 21 19.5 21H5.5ZM13.5 17C12.95 17 12.4792 16.8042 12.0875 16.4125C11.6958 16.0208 11.5 15.55 11.5 15V9C11.5 8.45 11.6958 7.97917 12.0875 7.5875C12.4792 7.19583 12.95 7 13.5 7H20.5C21.05 7 21.5208 7.19583 21.9125 7.5875C22.3042 7.97917 22.5 8.45 22.5 9V15C22.5 15.55 22.3042 16.0208 21.9125 16.4125C21.5208 16.8042 21.05 17 20.5 17H13.5ZM16.5 13.5C16.9333 13.5 17.2917 13.3583 17.575 13.075C17.8583 12.7917 18 12.4333 18 12C18 11.5667 17.8583 11.2083 17.575 10.925C17.2917 10.6417 16.9333 10.5 16.5 10.5C16.0667 10.5 15.7083 10.6417 15.425 10.925C15.1417 11.2083 15 11.5667 15 12C15 12.4333 15.1417 12.7917 15.425 13.075C15.7083 13.3583 16.0667 13.5 16.5 13.5Z" fill="#CE4C09"/>
            </g>
          </svg>
          <span className="my-account_hm-price">{redusxBalance}</span>
          {t('my.hm')}
            &nbsp;
          {/* {redusxSysBalance} {t('my.hm')} */}
        </div>
      </div>
      <div className="my-id">
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8.49999" cy="3.9071" r="3.7401" fill="#CE4C09"/>
          <path d="M8.5 8.46924C4.34755 8.46924 0.981323 11.8355 0.981323 15.9879V18.833H16.0187V15.9879C16.0187 11.8355 12.6525 8.46924 8.5 8.46924Z" fill="#CE4C09"/>
        </svg>
        <span>會員編號 :</span>
        <span>{redusxUser.uid}</span>
        <svg onClick={()=>handleCopy(redusxUser.uid)} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.18941 1.05263V11.5789H2.65285H3.82358H8.21378V3.42105V2.36842V1.05263H1.18941ZM1.18941 0H8.21378C8.85768 0 9.3845 0.473684 9.3845 1.05263V5V5.78947V11.5789C9.3845 12.1579 8.85768 12.6316 8.21378 12.6316H2.06797H1.18941C0.545505 12.6316 0.0186768 12.1579 0.0186768 11.5789V1.05263C0.0186768 0.473684 0.545505 0 1.18941 0Z" fill="#CE4C09"/>
          <path d="M3.82358 11.5789V13.9474H10.8479V3.42105H8.21378V2.36842H10.8479C11.4918 2.36842 12.0187 2.84211 12.0187 3.42105V7.36842V8.15789V13.9474C12.0187 14.5263 11.4918 15 10.8479 15H4.70214H3.82358C3.17968 15 2.65285 14.5263 2.65285 13.9474V11.5789H3.82358Z" fill="#CE4C09"/>
        </svg>
      </div>
      <div className="my-icon">
        <div className="my-icon_item" onClick={()=>{histroy.push('/user/qrcode')}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_337_3507" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_337_3507)">
            <path d="M3 11V3H11V11H3ZM5 9H9V5H5V9ZM3 21V13H11V21H3ZM5 19H9V15H5V19ZM13 11V3H21V11H13ZM15 9H19V5H15V9ZM19 21V19H21V21H19ZM13 15V13H15V15H13ZM15 17V15H17V17H15ZM13 19V17H15V19H13ZM15 21V19H17V21H15ZM17 19V17H19V19H17ZM17 15V13H19V15H17ZM19 17V15H21V17H19Z" fill="#056E00"/>
            </g>
          </svg>
        </div>
        <div className="my-icon_item" onClick={()=>{histroy.push('/user/scancode')}}>
          <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.24982 8.71184V4.54975C4.23375 2.65443 4.83736 2.07563 6.84554 2H11.2735" stroke="#CE4C09" stroke-width="2.5"/>
            <path d="M4.24982 15.2208V19.3829C4.23375 21.2782 4.83736 21.857 6.84554 21.9326H11.2735" stroke="#CE4C09" stroke-width="2.5"/>
            <path d="M21.8875 8.71184V4.54975C21.9036 2.65443 21.3 2.07563 19.2918 2H14.8638" stroke="#CE4C09" stroke-width="2.5"/>
            <path d="M21.8874 15.2208V19.3829C21.9035 21.2782 21.2998 21.857 19.2917 21.9326H14.8637" stroke="#CE4C09" stroke-width="2.5"/>
            <line y1="12.082" x2="25.9694" y2="12.082" stroke="#CE4C09" stroke-width="2.5"/>
          </svg>

          {/* <img src={IconState} alt="" onClick={()=>handleTransfer()}/> */}
        </div>
        <div className="my-icon_item" onClick={()=>{histroy.push('/user/transfer')}}>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 7H23" stroke="#CE4C09" stroke-width="2.5" stroke-linecap="round" />
            <path d="M23 19H3" stroke="#CE4C09" stroke-width="2.5" stroke-linecap="round" />
            <path d="M19 14L24 19L19 24" stroke="#CE4C09" stroke-width="2.5" stroke-linecap="round" />
            <path d="M7 2L2 7L7 12" stroke="#CE4C09" stroke-width="2.5" stroke-linecap="round" />
          </svg>
        </div>
        <div className="my-icon_item" onClick={()=>{histroy.push('/user/intro')}}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#CE4C09"/>
          </svg>
        </div>
        <div className="my-icon_item" onClick={()=>{histroy.push('/user/collect')}}>
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18.9999L8.55 17.6999C6.86667 16.1832 5.475 14.8749 4.375 13.7749C3.275 12.6749 2.4 11.6874 1.75 10.8124C1.1 9.9374 0.645833 9.13324 0.3875 8.3999C0.129167 7.66657 0 6.91657 0 6.1499C0 4.58324 0.525 3.2749 1.575 2.2249C2.625 1.1749 3.93333 0.649902 5.5 0.649902C6.36667 0.649902 7.19167 0.833236 7.975 1.1999C8.75833 1.56657 9.43333 2.08324 10 2.7499C10.5667 2.08324 11.2417 1.56657 12.025 1.1999C12.8083 0.833236 13.6333 0.649902 14.5 0.649902C16.0667 0.649902 17.375 1.1749 18.425 2.2249C19.475 3.2749 20 4.58324 20 6.1499C20 6.91657 19.8708 7.66657 19.6125 8.3999C19.3542 9.13324 18.9 9.9374 18.25 10.8124C17.6 11.6874 16.725 12.6749 15.625 13.7749C14.525 14.8749 13.1333 16.1832 11.45 17.6999L10 18.9999Z" fill="#CE4C09"/>
          </svg>
        </div>
      </div>
      <div className="my-family">
        <div className="my-family_title">
          家人
        </div>
        <div className="my-family_main">
          {familyList.map(item=><Avatar key={item.family_id} alt={item.nickname} src={api.url + item.image} onClick={()=>handleFamilyEdit(item,1)}/>)}
          <div className='my-family_mian_add' onClick={()=>{histroy.push('/user/scancode/?id=1')}}>
            <img src={IconAdd} alt="" />
          </div>
        </div>
        
      </div>
      <div className="my-family">
        <div className="my-family_title">
          街坊
        </div>
        <div className="my-family_main">
          {connectedList.map(item=><Avatar key={item.family_id} alt={item.nickname} src={api.url + item.image} onClick={()=>handleFamilyEdit(item,2)}/>)}
          <div className='my-family_mian_add' onClick={()=>{histroy.push('/user/scancode/?id=2')}}>
            <img src={IconAdd} alt="" />
          </div>
        </div>
      </div>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="個人資料" />
        <Tab label="設定" />
      </Tabs>
      <div className="my-content">
        {value == 0 &&<PersonalDetails handleCopy={handleCopy} user={redusxUser} handleUser={handleUser} />}
        {value == 1 &&<Settings />}
      </div>
    </div>
  )
}

function PersonalDetails({user,handleCopy,handleUser}){
  const { t } = useTranslation()
  const redusxSysAddress = useSelector((state)=>{
    return state.UserRd.address
  })
  return (
    <>
      <div className="personal-details">
        <div className="personal-details_title">
          {t('new.basicMessage')}
        </div>
        <div className="personal-details_address">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_346_21990" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
            <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_346_21990)">
            <path d="M5.5 21C4.95 21 4.47917 20.8042 4.0875 20.4125C3.69583 20.0208 3.5 19.55 3.5 19V5C3.5 4.45 3.69583 3.97917 4.0875 3.5875C4.47917 3.19583 4.95 3 5.5 3H19.5C20.05 3 20.5208 3.19583 20.9125 3.5875C21.3042 3.97917 21.5 4.45 21.5 5H13.5C12.3167 5 11.3542 5.37083 10.6125 6.1125C9.87083 6.85417 9.5 7.81667 9.5 9V15C9.5 16.1833 9.87083 17.1458 10.6125 17.8875C11.3542 18.6292 12.3167 19 13.5 19H21.5C21.5 19.55 21.3042 20.0208 20.9125 20.4125C20.5208 20.8042 20.05 21 19.5 21H5.5ZM13.5 17C12.95 17 12.4792 16.8042 12.0875 16.4125C11.6958 16.0208 11.5 15.55 11.5 15V9C11.5 8.45 11.6958 7.97917 12.0875 7.5875C12.4792 7.19583 12.95 7 13.5 7H20.5C21.05 7 21.5208 7.19583 21.9125 7.5875C22.3042 7.97917 22.5 8.45 22.5 9V15C22.5 15.55 22.3042 16.0208 21.9125 16.4125C21.5208 16.8042 21.05 17 20.5 17H13.5ZM16.5 13.5C16.9333 13.5 17.2917 13.3583 17.575 13.075C17.8583 12.7917 18 12.4333 18 12C18 11.5667 17.8583 11.2083 17.575 10.925C17.2917 10.6417 16.9333 10.5 16.5 10.5C16.0667 10.5 15.7083 10.6417 15.425 10.925C15.1417 11.2083 15 11.5667 15 12C15 12.4333 15.1417 12.7917 15.425 13.075C15.7083 13.3583 16.0667 13.5 16.5 13.5Z" fill="#CE4C09"/>
            </g>
          </svg>
          <span>{MethodOmit(redusxSysAddress)}</span>
          <svg onClick={()=>handleCopy(redusxSysAddress)} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.18941 1.05263V11.5789H2.65285H3.82358H8.21378V3.42105V2.36842V1.05263H1.18941ZM1.18941 0H8.21378C8.85768 0 9.3845 0.473684 9.3845 1.05263V5V5.78947V11.5789C9.3845 12.1579 8.85768 12.6316 8.21378 12.6316H2.06797H1.18941C0.545505 12.6316 0.0186768 12.1579 0.0186768 11.5789V1.05263C0.0186768 0.473684 0.545505 0 1.18941 0Z" fill="#CE4C09"/>
            <path d="M3.82358 11.5789V13.9474H10.8479V3.42105H8.21378V2.36842H10.8479C11.4918 2.36842 12.0187 2.84211 12.0187 3.42105V7.36842V8.15789V13.9474C12.0187 14.5263 11.4918 15 10.8479 15H4.70214H3.82358C3.17968 15 2.65285 14.5263 2.65285 13.9474V11.5789H3.82358Z" fill="#CE4C09"/>
          </svg>
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.fullName')}:
              </label>
              <div className="mian-card_info-value">
                {user?.username} {user?.first_name} {user?.last_name}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.first_name?25:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.nickname')}:
              </label>
              <div className="mian-card_info-value">
                {user?.username}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.username?25:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.sex')}:
              </label>
              <div className="mian-card_info-value">
                {user?.gender}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.gender?25:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.dateOfBirth')}:
              </label>
              <div className="mian-card_info-value">
                {user?.birth}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.birth?25:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <div className="personal-details_text">
          {t('new.contactInformation')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.residentialAddress')}:
              </label>
              <div className="mian-card_info-value">
                {user?.district} {user?.region} {user?.address}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.district?50:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.mobilePhoneNumber')}:
              </label>
              <div className="mian-card_info-value">
                {user?.phone_number}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.phone_number?50:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.electronicMail')}:
              </label>
              <div className="mian-card_info-value">
                {user?.email}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.email?50:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <div className="personal-details_text">
          {t('new.educationAndIncome')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.educationalLevel')}:
              </label>
              <div className="mian-card_info-value">
                {user?.edu_level}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.edu_level?75:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.on-the-jobStatus')}:
              </label>
              <div className="mian-card_info-value">
                {user?.employment_status}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.employment_status?75:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <div className="personal-details_text">
          {t('new.familyDetails')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('my.familyMonthlyIncome')}:
              </label>
              <div className="mian-card_info-value">
                {user?.household_income}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.household_income?100:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.maritalStatus')}:
              </label>
              <div className="mian-card_info-value">
                {user?.marital_status}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.marital_status?100:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.numberOfFamilyMembers')}:
              </label>
              <div className="mian-card_info-value">
                {user?.of_members}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.of_members?100:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.familyMembersAgeDistributionAnd')}:
              </label>
              <div className="mian-card_info-value">
                {user?.age_distribution}
              </div>
            </div>
            <div className="main-card_right" onClick={()=>handleUser(user?.age_distribution?100:0)}>
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Settings(){
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {logout} = useLogout()
  const assistRef = useRef(null)
  const AssistDiv = forwardRef(Assist)
  const [status,setStatus] = useState(true)
  const [formData,setFormData] = useState({
    phone_code:'852',
    phone_number:'',
    password:'',
    re_password:''
  })
  const [value, setValue] = useState(getI18n()||'zh');
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    var obj = {...formData};
    obj.phone_number = redusxUser?.phone_number
    if(redusxUser?.phone_number?.length == 11){
      obj.phone_code = '86'
    }else{
      obj.phone_code = '852'
    }
    setFormData({...obj})
  },[redusxUser])
  const handleChange = (event)=>{
    setValue((event.target).value);
    setI18n((event.target).value)
    i18n.changeLanguage((event.target).value)
  }
  // 修改密碼
  const handleSubmit = async() =>{
    const {data,code,msg} = await api.user.PostUserPassword(formData)
    if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
    logout()
  }
  // 取消
  const handleCancel = ()=>{
    var obj = {...formData};
    obj.password = '';
    obj.re_password = '';
    setFormData({...obj})
  }
  const handleAssist = () =>{
    assistRef.current.handleOpen()
  }
  const handleLogout = useCallback(()=>{
    logout()
  },[])
  return (
    <div className="settings">
      <RadioGroup value={value} onChange={handleChange} row>
        <FormControlLabel value="zh" control={<Radio color="primary"/>} label={<><img src={Hongkong} alt="" />繁體中文</>} labelPlacement="start"/>
        <FormControlLabel value="en" control={<Radio color="primary"/>} label={<><img src={UnitedKingdom} alt="" />English</>} labelPlacement="start"/>
      </RadioGroup>
      <div className="settings-title">
        更改密碼
      </div>
      <div className="settings-form">
        <form action="">
          <PhoneNumber disabled code={formData.phone_code} phone={formData.phone_number} status={status} formData={formData} setFormData={setFormData}/>
          <TextField
            id="outlined-textarea"
            label="密碼"
            name='password'
            multiline
            variant="outlined"
            value={formData.password}
            defaultValue={formData.password}
            onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            // error={state.phone?true:false}
            // helperText={state.phone?'':'請輸入手機號碼'}
          />
          <TextField
            id="outlined-textarea"
            label="再次輸入密碼"
            name='re_password'
            multiline
            variant="outlined"
            value={formData.re_password}
            defaultValue={formData.re_password}
            onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            // error={state.phone?true:false}
            // helperText={state.phone?'':'請輸入手機號碼'}
          />
        </form>
        <div className="settings-form-btn">
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            取消
          </Button>
          <Button disabled={!formData.re_password || !formData.password} variant="contained" color="primary" onClick={handleSubmit}>
            儲存
          </Button>
        </div>
      </div>
      <div className="settings-title">
        支援
      </div>
      <div className="settings-main">
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                服務條款
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                關於
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                幫助 / FAQ
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card" onClick={handleAssist}>
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                聯絡我們
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card" onClick={handleLogout}>
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                退出登錄
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <AssistDiv ref={assistRef} />
    </div>
  )
}
