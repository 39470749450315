export const mainType = [
  {
    type:'香港婦女中心協會',
    value:1,
  },
  {
    type:'香港聖公會麥理浩夫人中心',
    value:3,
  },
  {
    type:'關注草根生活聯盟',
    value:2,
  },
]
export const region = ["香港島","九龍半島","新界"]
export const district = {
  "香港島":["中西區","東區","南區","灣仔區"],
  "九龍半島":["九龍城區","觀塘區","深水埗區","黃大仙區","油尖旺區"],
  "新界":["離島區","葵青區","北區","西貢區","沙田區","大埔區","荃灣區","屯門區","元朗區"],
}
export const education = ["幼兒教育","小學教育","初中教育","高中教育","基礎課程文憑","副學士或同等學歷","學士學位或同等學歷","碩士或同等學歷","博士或同等學歷"]
export const otjStatus = ["全職","兼職","自僱人士","家庭照顧者","失業","退休"]
export const income = ["20,000元以下","20,001 - 40,000元","40,001 - 60,000元","60,001 - 80,000元","80,001元及以上"]
export const matrimony = ["未婚","已婚","同居","離婚","喪偶"]
export const ageDistribution = ["0-14歲","15-24歲","25-34歲","35-44歲","45-54歲","55-64歲","65歲及以上"]
export const membership = ["1人","2人","3人","4人","5人","6人或以上"]
export const shareObj = [
  {
    label: '物品分享',
    type:'產品',
    value: '2nd hand',
    sort:1,
  },
  {
    label: '自家製',
    type:'產品',
    value: 'Hand made',
    sort:1,
  },
  {
    label: '開班/工作坊',
    type:'活動',
    value: 'Class/Workshops',
    sort:0,
  },
  {
    label: '照顧服務',
    type:'服務',
    value: 'Care Service',
    sort:0,
  },
  {
    label: '家居服務',
    type:'服務',
    value: 'Residential Service',
    sort:0,
  },
  {
    label: '個人服務',
    type:'服務',
    value: 'Personal Service',
    sort:0,
  },
  // {
  //   label: '其他',
  //   type:'其他',
  //   value: 'other',
  // },
];
export const requestObj = [
  {
    label: '請求物品分享',
    type:'產品',
    value: '2nd hand',
    sort:1,
  },
  {
    label: '請求照顧服務',
    type:'服務',
    value: 'Care Service',
    sort:0,
  },
  {
    label: '請求家居服務',
    type:'服務',
    value: 'Residential Service',
    sort:0,
  },
  {
    label: '請求個人服務',
    type:'服務',
    value: 'Personal Service',
    sort:0,
  },
  // {
  //   label: '其他',
  //   type:'其他',
  //   value: 'other',
  // },
];