// shear
import './index.less';
import {useState,useEffect,useRef,useCallback} from 'react'
import {useNavigation} from '@/hooks/useNavigation.js'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next'
import IconAdd from '@/assets/images/shear/add.svg'
import ShearDialog from './components/ShearDialog';
import Participate from './components/Participate'
import Service from './components/Service'
import Shear from './components/Shear'
import Request from './components/Request'
export default function ShearHome(){
  useNavigation()
  const { t } = useTranslation()
  const paramter = useParameter()
  const history = useCustomHistory()
  const [value, setValue] = useState(paramter.active?Number(paramter.active) : 1);
  const [shearOpen,setShearOpen] = useState(false)
  useEffect(()=>{

  },[])
  const handleChange = (event, newValue) => {
    history.replace(`/sharing/home/?active=${newValue}`)
    setValue(newValue);
  };
  const handleDialogDetails = useCallback((e)=>{
    setShearOpen(e)
  },[])
  return (
    <div className='shear'>
      <div className="shear-title">
        {t('sharing.title')}
      </div>
      <>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="自己的分享" value={1} />
          <Tab label="提出請求" value={0} />
          <Tab label="我的購買" value={2} />
          <Tab label="我的幫助" value={3} />
        </Tabs>
        <div className="shear-main">
          {value == 1  && <Shear handleDialogDetails={handleDialogDetails} />}
          {value == 0  && <Request handleDialogDetails={handleDialogDetails}/>}
          {value == 2 && <Participate/>}
          {value == 3 && <Service />}
        </div>
        
        {/* {value != 2  && value!= 3 && <div className="shear-btn" onClick={()=>handleDialogDetails(true)}>
          <div className="shear-btn_add">
            <img src={IconAdd} alt="" />
          </div>
        </div>} */}
      </>
      <ShearDialog value={value} shearOpen={shearOpen} handleDialogDetails={handleDialogDetails} />
    </div>
  )
}

