import './index.less'
import {useState,useEffect,useRef,useImperativeHandle,forwardRef} from 'react'
import {useHistory} from 'react-router-dom'
import {useShopping} from '@/hooks/shopping.js'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {tipsReveal} from '@/redux/actions'
import { useDispatch,useSelector } from 'react-redux';
import Back from '@/assets/images/shop/back.svg'
import subtract from '@/assets/images/shop/subtract.svg'
import add from '@/assets/images/shop/add.svg'
import reserve from '@/assets/images/shop/reserve.svg'
import scene from '@/assets/images/shop/scene.svg'
import {ShopAction,BalanceAction} from '@/redux/actions'
import IconClose from '@/assets/images/shop/close.svg'
import api from '@/api'
import Precise from '@/utils/precise'
import { useTranslation } from 'react-i18next'
import {useNavigation} from '@/hooks/useNavigation.js'
import WeightInput from '@/components/WeightInput'
export default function Shear(){
  useNavigation()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const histroy = useHistory()
  const {deletes,upload} = useShopping()
  const [money,setMoney] = useState(0)
  const [RadioGroupValue,setRadioGroupValue] = useState('')
  const redusxShop = useSelector((state)=>{
    return state.ShoppingCartRd.list
  })
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  const [list,setList] = useState([])
  const WayDialogDiv = forwardRef(WayDialog)
  const wayDialogRef = useRef(null)
  useEffect(()=>{
    setList(redusxShop)
  },[redusxShop])
  useEffect(()=>{
    dispatch(ShopAction())
    dispatch(BalanceAction())
  },[])
  useEffect(()=>{
    setMoney(redusxBalance)
  },[redusxBalance])
  const handleBack = () =>{
    window.history.go(-1)
  }
  const handleUpload = async (id,updatedItem)=>{
    const {dat,code,msg} = await upload(id,updatedItem)
    if(code != 0)return 
    setList(list.map(item => (item.product_id === id ? { ...item, ...updatedItem } : item)));
  }
  const handleDelete = async (id)=>{
    const {data,code,msg} = await deletes(id)
    if(code!=0)return
    list.splice(list.findIndex(item => item.product_id=== id), 1)
    setList([...list])
  }
  const handleSubmit = ()=>{
    if(money - handleStatistics() < 0){
      dispatch(tipsReveal({message:'Insufficient balance',type:'error'}))
      return 
    }
    if(!RadioGroupValue)return dispatch(tipsReveal({message:'请选择商品',type:'error'}))
    wayDialogRef.current.handleOpen(RadioGroupValue)
  }
  const handlePrice = (e)=>{
    // if(e.is_veg == 0){
      var num = Precise(e.price , Math.floor(e.num))
      return Math.ceil(Number(num))
    // }else{
    //   if(e.per_units == 'jin'){
    //     return Math.ceil(Number((e.num * e.price)))
    //   }else if(e.per_units == 'jin_liang'){
    //     return Math.ceil(e.num * 10 * e.price)
    //   }
    // }
  }
  const handleStatistics = ()=>{
    if(RadioGroupValue){
      // return list.reduce((sum, item) => sum + handlePrice(item), 0)
      var appoint = list.find(item => item.buy_id === RadioGroupValue)
      return handlePrice(appoint)
    }else{
      return 0
    }
  }
  const handleRadioGroupChange = (event) => {
    // let lastChar = event.target.value.slice(-1);
    // if(lastChar != 1)return dispatch(tipsReveal({message:'该商品未通过审核',type:'error'}))
    // console.log(lastChar,event.target.value.slice(0, -1))
    setRadioGroupValue(event.target.value);
  };
  return (
    <div className='cart'>
      <div className="cart-head">
        <div className="cart-head_left" onClick={handleBack}>
          <img src={Back} alt="" />
          <span className='head-left_text'></span>
        </div>
        <div className="cart-head_right">
          <span>{t('shopping.youHave')}</span>
          <span className="head-right_money">{money}</span>
          <span className="head-right_unit">{t('my.hm')}</span>
        </div>
      </div>
      <div className="cart-content">
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={RadioGroupValue}
          onChange={handleRadioGroupChange}
        >
          {list.map((item,index)=>
          <div className="cart-content_RadioGroup" key={item.buy_id
          }>
            <FormControlLabel value={item.buy_id} control={<Radio />}></FormControlLabel>
            {item.is_veg == 0 ?
            <ProductBox key={item.product_id} item={item} handleClose={handleDelete} handlePrice={handlePrice} handleUpload={handleUpload} />
            :<VegetableBox key={item.product_id} item={item} handleClose={handleDelete} handlePrice={handlePrice} handleUpload={handleUpload}/>}
          </div>)}
        </RadioGroup>
      </div>
      <div className="cart-compute">
        <div className="cart-compute_statistics">
          <div className="cart-compute_statistics-label">
            {t('shopping.total')}
          </div>
          <div className="cart-compute_statistics-money">
            <span className="statistics-money_amount">
              {handleStatistics()}
            </span>
            <span className="statistics-money_unit">
              {t('my.hm')}
            </span>
          </div>
        </div>
        <Divider />
        <div className="cart-compute_Remaining">
          <div className="cart-compute_statistics-label">
            {t('shopping.residualTime')}
          </div>
          <div className="cart-compute_statistics-money">
            <span className="statistics-money_amount">
              {Math.ceil((money - handleStatistics()))}
            </span>
            <span className="statistics-money_unit">
              {t('my.hm')}
            </span>
          </div>
        </div>
      </div>
      <div className="cart-button">
        <Button variant="outlined" color="primary" onClick={handleBack}>返回</Button>
        <Button variant="contained" color="primary" onClick={()=>handleSubmit()}>確認</Button>
      </div>
      <WayDialogDiv ref={wayDialogRef} />
    </div>
  )
}


function ProductBox({item,handleClose,handlePrice,handleUpload}){
  const { t } = useTranslation()
  return (
  <>
    <div key={item.product_id} className="cart-content-item">
      <img src={IconClose} alt="" className='content-item_close' onClick={()=>handleClose(item.product_id)} />
      <div className="content-item_left">
        <img className="content-item_left-img" src={api.url + item.banner} alt="" />
        <div className="content-item_left-info">
          <div className="item-info-title">
            <span>{item.vegetable_name}</span>
          </div>
          <div className="item-info-container">
            <IconButton color="primary" disabled={item.num<=1} onClick={()=>handleUpload(item.product_id,{num:item.num-1})}>
              <img src={subtract} alt="" />
            </IconButton>
            <div className="container_amount">
              {Math.floor(item.num)}
            </div>
            <IconButton color="primary" onClick={()=>handleUpload(item.product_id,{num:Number(item.num)+1})}>
              <img src={add} alt="" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="content-item_right">
        <div className="content-item_right-money">
          {handlePrice(item)} <span className='right-money_unit'>{t('my.hm')}</span>
        </div>
      </div>
    </div>
  </>
  )
}

function VegetableBox({item,handleClose,handlePrice,handleUpload}){
  const { t } = useTranslation()
  const [formData,setFormData] = useState({
    jin:'',
    two:'',
  })
  useEffect(()=>{
    var count = Number(item.num)
    if(item.per_units == 'jin'){
      setFormData({ ...formData, ['jin']: count })
    }else{
      setFormData({ ...formData, ['two']: count })
    }
  },[item])
  const handleChange = (newValue)=>{
    handleUpload(item.product_id,{num:newValue})
    // var num;
    // if(event.target.name == 'jin'){
    //   if(Number(liang) == 0){
    //     num = Number(event.target.value);
    //   }else{
    //     num = Number(event.target.value) + Number(liang) / 10;
    //   }
    // }else{
    //   if(event.target.value > 10){
    //     num = jin + 9 / 10;
    //   }else{
    //     num = jin + Number(event.target.value) / 10;
    //   }
    // }
    // handleUpload(item.product_id,{num:num})
  }
  return (
    <>
      <div className="vegetable-card">
        <div className="vegetable-card_title">
          <span>{item.product_type}</span>
          <img src={IconClose} alt="" className='content-item_close' onClick={()=>handleClose(item.product_id)} />
        </div>
        <div className="vegetable-card_main">
          <div className="vegetable-card_main-input">
            {item.per_units == 'jin' && <WeightInput label="斤" name="jin" formData={formData} setFormData={setFormData} max={item.purchase_limit} value={formData.jin} change={handleChange}/>}
            {item.per_units != 'jin' && <WeightInput label="兩" name="two" formData={formData} setFormData={setFormData} max={item.purchase_limit} value={formData.two} change={handleChange}/>}
            {/* <FormControl>
              <Input
                id="standard-adornment-weight"
                endAdornment={<InputAdornment position="end">{t('shopping.jin')}</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="jin"
                type="number"
                value={jin}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <Input
                id="standard-adornment-weight"
                endAdornment={<InputAdornment position="end">{t('shopping.two')}</InputAdornment>}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="liang"
                type="number"
                maxRows={9}
                value={liang}
                onChange={handleChange}
              />
            </FormControl> */}
          </div>
          <div className="vegetable-card_main-money">
            {/* {Number(item.num * item.price)} */}
            {handlePrice(item)}
            <span>{t('my.hm')}</span>
          </div>
        </div>
      </div>
    </>
  )
}

const WayDialog = (props,ref)=> {
  const histroy = useHistory()
  const [wayOpen,setwayOpen] = useState(false)
  const [buyId,setBuyId] = useState('')
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      console.log(e)
      setBuyId(e)
      setwayOpen(true)
    }
  }))

  const handleClose= ()=>{
    setwayOpen(false)
  }
  const handleReserve = (e)=>{
    histroy.push(`/shopping/order/?buyid=${buyId}&type=${e}`)
  }
  return (
    <Dialog className="way-contact" onClose={handleClose} aria-labelledby="simple-dialog-title" open={wayOpen}>
      <div className="way-contact-list">
        <div className="list-item" onClick={()=>handleReserve('reserve')}>
          <div className="list-item_img">
            <img src={reserve} alt="" />
          </div>
          <div className="list-item_text">
            <div className="list-item_text-title">
              預訂貨品
            </div>
            <div className="list-item_text-des">
              訂單已確認，機構會為你確定，不設更改訂單 。
            </div>
          </div>
        </div>
        <div className="list-item" onClick={()=>handleReserve('scene')}>
          <div className="list-item_img">
            <img src={scene} alt="" />
          </div>
          <div className="list-item_text">
            <div className="list-item_text-title">
              即場交易
            </div>
            <div className="list-item_text-des">
              已身處活動場地，可即場交易。
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}