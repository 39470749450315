// 發送及付款
import './index.less';
import {useState,useEffect,useRef,forwardRef,useCallback} from 'react'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useShearDetail} from '@/hooks/home.js'
import { useApplicant } from '@/hooks/sharing.js'
import { useSelector } from 'react-redux';
import api from '@/api'
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import TopHead from '@/components/TopHead'
import ScanCodeMD from '@/components/ScanCodeMD'
import subtract from '@/assets/images/shop/subtract.svg'
import add from '@/assets/images/shop/add.svg'
import CodeMD from '@/components/CodeMD'
import PaymentDialog from './PaymentDialog'
var timer;
export default function SendPayment(){
  const paramter = useParameter()
  const histroy = useCustomHistory()
  const {joinDetail} = useApplicant()
  const {details,refresh} = useShearDetail(paramter.id)
  const [joinDetails,setJoinDetails] = useState({})
  const [value,setValue] = useState(1)
  const [open,setOpen] = useState(false)
  useEffect(()=>{
    handleJoinDetail()
    if(paramter.is_share == '1'){
      timer = setInterval(()=>{
        handleJoinDetail()
      },2000)
    }
    return(()=>{
      clearInterval(timer)
    })
  },[])
  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  },[]);
  const handleJoinDetail = useCallback(async()=>{
    const {data,code,msg} = await joinDetail({
      join_id:paramter.join_id
    })
    if(data.bills_id) histroy.replace(`/sharing/paymentStatus/?is_share=${1}`)
    setJoinDetails(data)
  },[])
  const handleAdjust = useCallback(async()=>{
    setOpen(true)
  },[])
  return (
    <div className="sand-payment">
      <TopHead title={`${paramter.is_share == '1' ? '請求' :'發送'}付款`} />
      <div className="sand-payment_main">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="面對面交易" />
          <Tab label="線上交易" />
        </Tabs>
        <div className="sand-payment_content">
          {value == 0 && <OfflineTransaction is_share={paramter.is_share} details={details} joinDetails={joinDetails} refresh={handleJoinDetail} adjust={handleAdjust}/>}
          {value == 1 && <OnlineTransaction is_share={paramter.is_share} details={details} joinDetails={joinDetails} refresh={handleJoinDetail} adjust={handleAdjust} />}
        </div>
        <PaymentDialog open={open} setOpen={setOpen} price={joinDetails.wait_pay_money} refresh={handleJoinDetail} />
      </div>
    </div>
  )
}
// 綫上交易
function OfflineTransaction({is_share,details,joinDetails,refresh,adjust}){
  if(is_share == 0){
    return <ScanCode />
  }else if(is_share == 1){
    return <Collection is_share={is_share} details={details}  joinDetails={joinDetails} refresh={refresh} adjust={adjust}/>
  }
}
// 扫码付款
function ScanCode(){
  const histroy = useCustomHistory()
  const feedback = (e)=>{
    histroy.replace(`/user/scanResult/?value=${e}`)
  }
  return (
    <>
      <div className="scan-code">
        <div className="scan-code_title">
          <span>請掃描申請者的二維碼</span>
        </div>
        <ScanCodeMD feedback={feedback} />
      </div>
    </>
  )
}
// 收款
function Collection({is_share,details,joinDetails,refresh,adjust}){
  const paramter = useParameter()
  const {payment,requestPayment} = useApplicant()
  const [num,setNum] = useState(1)
  // 计算金额
  useEffect(()=>{
    console.log(details)
    setNum((joinDetails.wait_pay_money / details.price) || 1)
  },[details])
  const handlePrice = ()=>{
    return details.price * num
  }
  // 调整时分
  const handleSubmit = async()=>{
    adjust()
  }
  return (
    <>
      <div className="collection">
        <div className="collection_title">
          <span>請讓買家掃描付款碼</span>
        </div>
        <div className="collection_code">
          <CodeMD url={`https://?${paramter.join_id}?payment?${is_share}`} />
        </div>
        <div className="collection_main">
          <div className="collection_main-container">
            <div className="collection_main-title">
              {details.title}
            </div>
            <div className="collection_main-right">
              <div className="collection_main-right-num">
                {/* <IconButton color="primary" disabled={num<=1} onClick={()=>setNum(num-1)}>
                  <img src={subtract} alt="" />
                </IconButton>
                <div className="container_amount">
                  {num}
                </div>
                <IconButton color="primary" onClick={()=>setNum(num+1)}>
                  <img src={add} alt="" />
                </IconButton> */}
              </div>
              <div className="collection_main-right-price">
                {joinDetails?.wait_pay_money}
                <span>時分</span>
              </div>
            </div>
          </div>
          <Divider />
          <div className="collection_main-statistics">
            <div className="collection_main-statistics-title">
              總計
            </div>
            <div className="collection_main-statistics-price">
              {joinDetails?.wait_pay_money}
              <span>時分</span>
            </div>
          </div>
          <div className="collection_main-btn">
            <Button variant="outlined" color="primary"  onClick={()=>handleSubmit()}>調整時分</Button>
          </div>
        </div>
      </div>
    </>
  )
}
// 线上交易
function OnlineTransaction({is_share,details,joinDetails,refresh,adjust}){
  const paramter = useParameter()
  const histroy = useCustomHistory()
  const {payment,requestPayment} = useApplicant()
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  const [num,setNum] = useState(1)
  useEffect(()=>{
    setNum((details.joinDetails / details.price) || 1)
  },[details])
  // 计算金额
  const handlePrice = ()=>{
    // return details.price * num
    return Number(joinDetails.wait_pay_money)
  }
  // 計算剩餘金額 
  const handleRemaining = () =>{
    if(is_share == 0){
      return redusxBalance - handlePrice()
    }else{
      return redusxBalance + handlePrice()
    }
    
  }
  // 返回
  const handleBack = ()=>{
    histroy.go(-1)
  }
  // 付款
  const handleSubmit = async()=>{
    // 付款
    if(is_share == 0){
      const {data,code,msg} = await payment({
        join_id:paramter.join_id
      })
      if(code != 0)return 
      histroy.replace(`/sharing/paymentStatus/?is_share=${is_share}`)
    }else{ // 請求支付
      const {data,code,msg} = await requestPayment({
        wait_pay_money:handlePrice(),
        join_id:paramter.join_id
      })
      if(code != 0)return 
      refresh()
    }
  }
  // 调整时分
  const handleAdjust = async()=>{
    adjust()
  }
  return (
    <div className="online-transaction">
      <div className="cart-content-item">
        <div className="content-item_left">
          <div className="content-item_left-img">
            <img src={api.url + details.banner} alt="" />
          </div>
          <div className="content-item_left-info">
            <div className="item-info-title">
              <span>{details.title}</span>
            </div>
            <div className="item-info-container">
              <div className="item-info-container_num">
                {/* <IconButton disabled={is_share == 0 || num <= 1} color="primary" onClick={()=>setNum(num-1)}>
                  <img src={subtract} alt="" />
                </IconButton>
                <div className="container_amount">
                  {num}
                </div>
                <IconButton disabled={is_share == 0} color="primary" onClick={()=>setNum(num+1)}>
                  <img src={add} alt="" />
                </IconButton> */}
              </div>
              <div className="content-item_right-money">
                {handlePrice()} <span className='right-money_unit'>時分</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-compute">
        <div className="cart-compute_statistics">
          <div className="cart-compute_statistics-label">
            總計
          </div>
          <div className="cart-compute_statistics-money">
            <span className="statistics-money_amount">
              {handlePrice()}
            </span>
            <span className="statistics-money_unit">
              時分 
            </span>
          </div>
        </div>
        {paramter.is_share == '1' && <div className="collection_main-btn">
          <Button variant="outlined" color="primary"  onClick={()=>handleAdjust()}>調整時分</Button>
        </div>}
        <Divider />
        <div className="cart-compute_Remaining">
          <div className="cart-compute_statistics-label">
            交易後的時分總額  
          </div>
          <div className="cart-compute_statistics-money">
            <span className="statistics-money_amount">
              {handleRemaining()}
            </span>
            <span className="statistics-money_unit">
              時分
            </span>
          </div>
        </div>
        {paramter.is_share != '1' && <div className="cart-button">
          <Button variant="outlined" color="primary"  onClick={()=>handleBack()}>返回</Button>
          <Button variant="contained" color="primary" onClick={()=>handleSubmit()}>確認</Button>
        </div>}
      </div>
    </div>
  )
}