
import { useEffect,useState,forwardRef,useRef } from 'react';
import {useHistory} from 'react-router-dom'
import IconAdd from '@/assets/images/shear/add.svg'
import api from '@/api'
import Button from '@mui/material/Button';
import ShearCard from '../Card'
import ServiceDialog from '../ServiceDialog'
import { useInView } from "react-cool-inview";
export default function Participate(){
  const history = useHistory()
  const [serviceList,setServiceList] = useState([])
  const [total,setTotal] = useState(0)
  const ServiceDialogDiv = forwardRef(ServiceDialog)
  const ServiceDialogRef = useRef(null)
  const [formData,setFormData] = useState({
    limit:5,
    page:1,
    is_share:0,
  })
  useEffect(()=>{
    handleSecondhand()
  },[formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= serviceList.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  useEffect(()=>{
    console.log(serviceList)
    setServiceList(serviceList)
  },[serviceList])
  const handleSecondhand = async ()=>{
    console.log(serviceList)
    const {data,code,msg,total:count} = await api.shear.GetUserEventJoinList(formData);
    setTotal(count)
    if(data.length == undefined){
      // setServiceList([])
      return 
    }
    setServiceList([...serviceList,...data])
  }
  const refresh = (e)=>{
    var list = serviceList.filter(item => item.event_id !== e.event_id)
    setTotal(total-1)
    setServiceList(list)
  }
  const handleService = (e)=>{
    ServiceDialogRef.current.handleOpen(e)
  }
  const handleMarketplace = ()=>{
    history.replace(
      `/home/?type=all`,
    )
  }
  return (
    <>
      <ServiceDialogDiv ref={ServiceDialogRef} />
      {serviceList.length <= 0 && <div className="shear-box">
        <div className="shear-box_add">
          {/* <img src={IconAdd} alt="" /> */}
          <span>你還沒有接取請求</span>
        </div>
      </div>}
      {serviceList.map((item,index)=><div key={item.event_id + index} ref={index === serviceList.length - 1 ? observe : null}><ShearCard refresh={refresh} item={item} type={'service'} handleService={handleService}/></div>)}
      <div className='shear-btn'>
        <Button variant="contained" color="primary" onClick={()=>handleMarketplace()}>
          去市場逛逛
        </Button>
      </div>
    </>
  )
}

