import './index.less'
import React,{useEffect,useRef} from 'react'
import BScroll from 'better-scroll'
import ProductCard from '@/components/ProductCard'
const DataList = ({data}) =>{
  const scrollRef = useRef(null)
  const bsRef = useRef(null);
  useEffect(()=>{

  },[])
  return (
    <>
      <div className="data-list" ref={scrollRef}>
        <div className="data-list_main">
          {data.map((row,index)=><ProductCard key={index} data={row}/>)}
        </div>
      </div>
    </>
  )
}

export default React.memo(DataList)