import './index.less'
import React,{useEffect,useState} from 'react'
import Dialog from '@mui/material/Dialog';
import {useHistory,Link } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Icon2ndhand from '@/assets/images/shear/2ndhand.svg'
import IconHandmade from '@/assets/images/shear/handmade.svg'
import IconClass from '@/assets/images/shear/class.svg'
import IconCard from '@/assets/images/shear/card.svg'
import IconResidential from '@/assets/images/shear/residential.svg'
import IconPersonal from '@/assets/images/shear/personal.svg'
import IconOther from '@/assets/images/shear/other.svg'
import {shareObj,requestObj} from '@/utils/district.js'
function ShearDialog({shearOpen,handleDialogDetails,value}){
  const [categoryObj,setCategoryObj] = useState(shareObj)
  const histroy = useHistory()
  useEffect(()=>{
    if(value == 1){
      setCategoryObj(shareObj)
    }else{
      setCategoryObj(requestObj)
    }
  },[value])
  const handleClose = ()=>{
    handleDialogDetails(false)
  }
  const handleForm = (e)=>{
    histroy.push(`/sharing/shearForm/?type=${e.value}&id=${value}&sort=${e.sort}`)
  }
  return (
    <>
      <Dialog className="shear-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={shearOpen}>
        <div className="shear-dialog_main">
          <div className="dialog-main_title">
            與我的社群分享
          </div>
          <div className="dialog-main_list">
            {categoryObj.map((item,index)=><div className="list-item" key={index} onClick={()=>handleForm(item)}>
              {item.value == '2nd hand' && <img src={require(`@/assets/images/shear/2ndhand.svg`).default} alt="" />}
              {item.value == 'Hand made' && <img src={require(`@/assets/images/shear/handmade.svg`).default} alt="" />}
              {item.value == 'Class/Workshops' && <img src={require(`@/assets/images/shear/class.svg`).default} alt="" />}
              {item.value == 'Care Service' && <img src={require(`@/assets/images/shear/card.svg`).default} alt="" />}
              {item.value == 'Residential Service' && <img src={require(`@/assets/images/shear/residential.svg`).default} alt="" />}
              {item.value == 'Personal Service' && <img src={require(`@/assets/images/shear/personal.svg`).default} alt="" />}
              {item.value == 'other' && <img src={require(`@/assets/images/shear/other.svg`).default} alt="" />}
              <div className="list-item_info">
                <div className="list-item_info-title">
                  {item.label}
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default React.memo(ShearDialog)