function tipsReveal({type,message,icon}){
  return {
    type:"TIPSREVEAL",
    value:{type,message,icon}
  }
}
function tipsOpen(){
  return {
    type:"TIPSOPEN",
  }
}
export {
  tipsReveal,
  tipsOpen
}