import './index.less'
import api from '@/api'
import {useState} from 'react'
import {useFileUpload} from '@/hooks/useFileUpload.js'
import useTimeData from '@/hooks/useTimeData.js'
import {useCertificate} from '@/hooks/user.js'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
export default function AptitudeAdd ({refresh}){
  const {upload} = useFileUpload()
  const {pickerData} = useTimeData()
  const {append} = useCertificate()
  const dispatch = useDispatch()
  const [formData,setFormData] = useState({
    acquisition_date:'', // 獲取時間
    cert_name:'', // 證書名稱
    cert_img:'' // 證書圖片
  })
  const handleUploadFile = async(e)=>{
    const {data,code,msg} = await upload(e)
    setFormData({...formData,['cert_img']:data.src})
  }
  const handleSubmit = async()=>{
    if(!formData.cert_name)return dispatch(tipsReveal({message:'請輸入證書名稱',type:'error'}))
    if(!formData.acquisition_date)return dispatch(tipsReveal({message:'請輸選擇證書時間',type:'error'}))
    if(!formData.cert_img)return dispatch(tipsReveal({message:'請上傳證書',type:'error'}))
    const {data,code,msg} = await append(formData);
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:'上傳資歷申請成功，請等候批核',type:'success'}))
    refresh()
    var obj = formData;
    for (let prop in obj) {
      obj[prop] = ''
    }
    console.log(obj)
    setFormData({...obj})
  }
  return (
    <div className="aptitudeadd">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          label={`獲得日期**`}
          name='acquisition_date'
          defaultValue={dayjs(formData.acquisition_date)}
          value={dayjs(formData.acquisition_date)}
          onChange={(event)=>setFormData({...formData,['acquisition_date']:pickerData(event)})}
          format="YYYY/MM/DD"
        />
      </LocalizationProvider>
      <TextField
        label={`資歷名稱*`}
        name='cert_name'
        multiline
        variant="outlined"
        value={formData.cert_name}
        defaultValue={formData.cert_name}
        onChange={(event)=>{setFormData({...formData,[event.target.name]:event.target.value})}}
      />
      <label htmlFor="icon-banner-file" className="aptitudeadd-upload">
        {formData.cert_img && <img src={api.url + formData.cert_img} />}
        {!formData.cert_img && <div htmlFor="icon-banner-file" className="aptitudeadd-upload_empty">
          <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.414062" width="30" height="31" rx="15" fill="#E6FFDC"/>
            <path d="M22.6511 18.2241H17.4311V23.5641H12.5711V18.2241H7.35105V13.6341H12.5711V8.26406H17.4311V13.6341H22.6511V18.2241Z" fill="#056E00"/>
          </svg>
          <span>上載證明</span>
        </div>}
      </label>
      <input accept="image/*" id="icon-banner-file" className="icon-button-file" type="file" onChange={handleUploadFile}/>
      <Button variant="contained" onClick={()=>handleSubmit()}>確認遞交紿機構驗證</Button>
    </div>
  )
}