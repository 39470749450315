import './index.less'
import React,{useState,memo,useImperativeHandle} from 'react'
import Dialog from '@mui/material/Dialog';
import Wc from '@/assets/images/login/OrangeCH.svg'
import Wc2 from '@/assets/images/login/group23.svg'
import Wc3 from '@/assets/images/login/group21.svg'
import { useDispatch,useSelector } from 'react-redux'
import commentIcon from '@/assets/images/icon/comment.svg'
import mailIcon from '@/assets/images/icon/mail.svg'
import callIcon from '@/assets/images/icon/call.svg'
import {whatsContact} from '@/utils/custom.js'
import {tipsReveal} from '@/redux/actions'
const Assist = (props,ref) => {
  const dispatch = useDispatch()
  const [open,setOpen] = useState(false)
  const [whats,setWhate] = useState('5776 0277')
  const [phone,setPhone] = useState('2386 6256')
  const [email,setEmail] = useState('laikok@womencentre.org.hk ')
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setOpen(true)
    }
  }))
  const handleClose = ()=>{
    setOpen(false)
  }
  const handleWhatsApp = (e)=>{
    whatsContact(e)
  }
  const handleEmail = (e)=>{
    window.location.href = `mailto:${e}`;
  }
  const handleCopy = async (e)=>{
    try {
      dispatch(tipsReveal({message:'已複製',type:'success',icon:<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.18941 1.05263V11.5789H2.65285H3.82358H8.21378V3.42105V2.36842V1.05263H1.18941ZM1.18941 0H8.21378C8.85768 0 9.3845 0.473684 9.3845 1.05263V5V5.78947V11.5789C9.3845 12.1579 8.85768 12.6316 8.21378 12.6316H2.06797H1.18941C0.545505 12.6316 0.0186768 12.1579 0.0186768 11.5789V1.05263C0.0186768 0.473684 0.545505 0 1.18941 0Z" fill="#CE4C09"/>
      <path d="M3.82358 11.5789V13.9474H10.8479V3.42105H8.21378V2.36842H10.8479C11.4918 2.36842 12.0187 2.84211 12.0187 3.42105V7.36842V8.15789V13.9474C12.0187 14.5263 11.4918 15 10.8479 15H4.70214H3.82358C3.17968 15 2.65285 14.5263 2.65285 13.9474V11.5789H3.82358Z" fill="#CE4C09"/>
      </svg>}))
      await navigator.clipboard.writeText(e);
    } catch (err) { 
      dispatch(tipsReveal({message:'Failed to copy',type:'error'}))
    }
  }
  return (
    <>
      <Dialog className="dialog-contact" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <img className="dialog-contact_logo" src={redusxUser.user_type == 1 && Wc || redusxUser.user_type == 2 && Wc2 || redusxUser.user_type == 3 && Wc3} alt="" />
        <div className="dialog-contact_title">
          揾小幫手幫幫您
        </div>
        <div className="dialog-contact_list">
          <div className="list-item" onClick={()=>handleWhatsApp('57760277')}>
            <div className="list-item_icon">
              <img src={commentIcon} alt="" />
            </div>
            <div className="list-item_info">
              <div className="info-text">
                WhatsApp
              </div>
              <div className="info-number">
                {whats}
              </div>
            </div>
          </div>
          <a href={`tel:${phone}`} className="list-item">
            <div className="list-item_icon">
              <img src={callIcon} alt="" />
            </div>
            <div className="list-item_info">
              <div className="info-text">
                中心熱線
              </div>
              <div className="info-number">
                {phone}
              </div>
            </div>
          </a>
          <div className="list-item">
            <div className="list-item_icon">
              <img src={mailIcon} alt="" />
            </div>
            <div className="list-item_info" onClick={()=>handleCopy(email)}>
              <div className="info-text">
                電郵
              </div>
              <div className="info-number info-email">
                {email}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default Assist