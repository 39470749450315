import { useState } from "react";
import api from '@/api'
import axios from 'axios'
import {baseURL} from '@/api/request'
export function useFileUpload(){
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const upload = async(e)=>{
    const imageUrl = e.target.files[0]
    const formDataFile = new FormData();
    formDataFile.append('file', imageUrl);
    // axios.post(baseURL + '/common/file', formDataFile, {
    //   onUploadProgress: progressEvent => {
    //     const { loaded, total } = progressEvent;
    //     const progress = Math.round((loaded * 100) / total);
    //     setProgress(progress);
    //   }
    // })
    // .then(response => {
    //   // 文件上传成功的处理
    //   console.log(response);
    //   setFile(response)
    // })
    // .catch(error => {
    //   // 文件上传失败的处理
    //   console.error(error);
    // });
    const {data,code,msg} = await api.user.PostCommonFile(formDataFile)
    setTimeout(()=>{
      e.target.value = ""
    },500)
    return {data,code,msg}
  }
  return {upload}
}