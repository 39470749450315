import React,{useState,useEffect} from 'react';
import './index.less'
import { useTranslation } from 'react-i18next'
import countryObj from '@/utils/countryCodeAndPhoneCode.json'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
const PhoneNumber = ({disabled,code,status,phone,setFormData,formData})=>{
  const { t } = useTranslation()
  return (
    <>
      <TextField
        label={t('login.mobilePhoneNumber')}
        name='phone_number'
        multiline
        variant="outlined"
        value={phone}
        disabled={disabled}
        defaultValue={phone}
        onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
        // error={state.phone?true:false}
        helperText={phone || status?'':t('login.pleaseEnter') + t('login.mobilePhoneNumber')}
        InputProps={{
          startAdornment:
          <FormControl className="control-phone_cpde">
            <Select
              name='phone_code'
              value={code}
              defaultValue={code}
              disabled={disabled}
              onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            >
              {countryObj.map((item,index)=>
                <MenuItem key={index} value={item.phone_code}>
                  +{item.phone_code}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        }}
      />
    </>
  )
}

export default React.memo(PhoneNumber)