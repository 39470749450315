import './index.less';
import {useState,useEffect,useRef,forwardRef} from 'react'
import api from '@/api'
import {useUserInfo} from '@/hooks/user.js'
import {useParameter} from '@/hooks/useParameter.js'
import { useApplicant } from '@/hooks/sharing.js'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import TopHead from '@/components/TopHead'
import Button from '@mui/material/Button';
import wtsIcon from '@/assets/images/icon/wts.png'
import {whatsContact} from '@/utils/custom.js'
import { useInView } from "react-cool-inview";
import Personal from '@/components/Personal'
export default function Applicant(){
  const paramter = useParameter()
  const {acquire} = useUserInfo()
  const {request,handle,cancel} = useApplicant()
  const [value,setValue] = useState(0)
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const [formData,setFormData] = useState({
    limit:10,
    page:1,
    event_id:paramter.id,
  })
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  useEffect(()=>{
    requestList()
  },[paramter.id,value,formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  const requestList = async()=>{
    const {data,code,msg,total:count} = await request({
      ...formData,
      is_choose:value
    })
    setTotal(count)
    if(data.length == undefined)return setList([])
    setList([...list,...data])
  }
  const refresh = (e)=>{
    var new_list
    console.log(e.length)
    for(var i in e){
      new_list = list.filter(item => item.uid !== e[i])
      console.log(new_list)
    }
    setTotal(total-e.length)
    setList(new_list)
  }
  const handleChange = (event, newValue) => {
    setList([])
    setTotal(0)
    setFormData({ ...formData, ['page']: 1 })
    setValue(newValue);
  };
  const handleGroupChange = (event,index) => {
    var obj = list;
    console.log(obj[index].gilad)
    obj[index].gilad= event.target.checked,
    setList([
      ...obj
    ]);
  };
  // 同意/拒绝申请
  const handleSubmit = async(e)=>{
    var obj = []
    for(let i in list){
      if(list[i].gilad){
        obj.push(list[i].uid)
      }
    }
    if(obj.length <= 0)return 
    const {data,code,msg} = await handle({
      event_id:paramter.id,
      uid_list:obj,
      is_choose:e,
    })
    if(code != 0)return 
    refresh(obj)
    // requestList()
  }
  // 申请者详情
  const handlePersonal = (e)=>{
    personalRef.current.handleOpen(e)
  }
  // 聯絡買家
  const handleContact = async(e)=>{
    const {data,code,msg} = await acquire(e.uid)
    whatsContact(data?.phone_number)
  }
   // 取消交易
  const handleCancel = async(e)=>{
    const {data,code,msg} = await cancel(e.join_id)
    if(code != 0)return 
    refresh([e.uid])
    // requestList()
  }
  return (
    <div className="applicant">
      <PersonalDiv ref={personalRef} />
      <TopHead title='申請者' />
      <div className="applicant-main">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="申請者請求" />
          <Tab label="已同意的申請" />
        </Tabs>
        <div className="applicant-main_list">
          {value == 0 && <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormGroup>
              {list.map((item,index)=><div className="main-list_card" key={item.uid} ref={index === list.length - 1 ? observe : null}>
                <Avatar alt={item?.username} src={api.url+item.avatar} onClick={()=>handlePersonal(item)} />
                <div className="main-list_card-info">
                  <div className="main-list_card-info-name">
                    {item.username}
                  </div>
                  <div className="main-list_card-info-des">
                    已向你申請交易及聯絡
                  </div>
                </div>
                <FormControlLabel
                control={
                  <Checkbox checked={item.gilad} onChange={(event)=>handleGroupChange(event,index)} name="gilad" />
                }
              /></div>)}
            </FormGroup>
          </FormControl>}
            {value == 1 && <div className="applicant-main_list-agree">{list.map((item,index)=><div className="main-list_card" ref={index === list.length - 1 ? observe : null}>
              <Avatar alt={item?.username} src={api.url+item.avatar} onClick={()=>handlePersonal(item)}/>
              <div className="main-list_card-info">
                <div className="main-list_card-info-name">
                  {item.username}
                </div>
              </div>
              <div className="main-list_card-btn">
                <div onClick={()=>handleCancel(item)}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2.1875L20 19.8772" stroke="#056E00" stroke-width="3" />
                    <path d="M2 20.1875L20 2.49785" stroke="#056E00" stroke-width="3" />
                  </svg>
                  <span>取消交易</span>
                </div>
                <div onClick={()=>handleContact(item)}>
                  <img src={wtsIcon} />
                  <span>聯絡買家</span>
                </div>
              </div>
            </div>)}
          </div>}
        </div>
      </div>
      {value == 0 && <div className="applicant-btn">
        <Button variant="outlined" color="primary" onClick={()=>handleSubmit(2)}>
          拒絕申請
        </Button>
        <Button variant="contained" color="primary" onClick={()=>handleSubmit(1)}>
          同意申請
        </Button>
      </div>}
    </div>
  )
}