import React,{ Component,Suspense,useEffect } from 'react'
// import { BrowserRouter,HashRouter} from 'react-router-dom'
import { HashRouter as Router,Route,Redirect,Switch } from 'react-router-dom'
import DynamicRouter from './router'
// import Loding from '@/components/Loding'
// import RouterAuth from './RouterAuth'
import {getToken} from '@/utils/auth'
import Home from '@/views/home'
import EntranceMain from "@/views/entrance/main"
import EntranceLogin from "@/views/entrance/login"
import EntranceRegister from "@/views/entrance/register"
import EntranceCode from "@/views/entrance/code"
import EntranceForgot from "@/views/entrance/forgot"
export default class indexRouter extends Component {
  props
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Redirect from="/" to="/home/?type=all" exact />
            <Route path="/home" component={Home} />
            <Route path="/entrance/main" component={EntranceMain} />
            <Route path="/entrance/login" component={EntranceLogin} />
            <Route path="/entrance/register" component={EntranceRegister} />
            <Route path="/entrance/code" component={EntranceCode} />
            <Route path="/entrance/forgot" component={EntranceForgot} />
            {DynamicRouter.map((item, index) => 
              <Route
                key={index}
                path={item.path}
                exact={item.exact}
                // component={item.component}
                render={()=>{
                  return getToken() && item.redirct ? <item.component/> :<Redirect to="/entrance/login"></Redirect>
                }}
              />
            )}
          </Switch>
          {this.props?.children}
        </Router>
      </>
    )
  }
}

