import { useEffect } from "react";
import { useDispatch } from 'react-redux'
import {show,hide} from '@/redux/actions'
export function useNavigation(){
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(show())
    return(()=>{
      dispatch(hide())
    })
  },[])
}