
function pairNum(num) {
  return (String(num).length == 1 ? '0' : '') + num;
}

export const shiftTime = timestamp => {
  if (!timestamp) return;
  var timestamp = (new Date(timestamp).getTime()/1000)
  var curTimestamp = parseInt(new Date().getTime()/1000); 
  var timestampDiff = curTimestamp - timestamp; 
  var curDate = new Date(curTimestamp * 1000); 
  var tmDate = new Date(timestamp * 1000);  
  var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
  var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();
  if (timestampDiff < 3600) { 
    if(parseInt(timestampDiff / 60) == 0){
      return '剛剛'
    }
    return parseInt(timestampDiff / 60) +'分鐘前'
    return pairNum(H)+':'+pairNum(i);
  } else if (timestampDiff > 3600 &&timestampDiff < 36000) { 
    return parseInt(timestampDiff / 3600) + '小時前'
  }else {
      var newDate = new Date((curTimestamp - 86400) * 1000); 
      if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
        return '昨天' + pairNum(H) + ':' + pairNum(i);
      } else if (curDate.getFullYear() == Y) {
        return parseInt(timestampDiff / (3600 * 24)) + '天前'
          // return pairNum(m) + '月' + pairNum(d) + '日 ' + pairNum(H) + ':' + pairNum(i);
      } else {
          return Y + '年' + pairNum(m) + '月' + pairNum(d) + '日 ' + pairNum(H) + ':' + pairNum(i);
      }
  }
};

export const addTime = timestamp => {
  if (!timestamp) return;
  var timestamp = (new Date(timestamp).getTime()/1000)
  var curTimestamp = parseInt(new Date().getTime()/1000); 
  var timestampDiff = curTimestamp - timestamp; 
  var curDate = new Date(curTimestamp * 1000); 
  var tmDate = new Date(timestamp * 1000);  
  var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
  var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();
  if (timestampDiff < 3600) { 
    if(parseInt(timestampDiff / 60) == 0){
      return '剛剛'
    }
    return parseInt(timestampDiff / 60) +'分鐘'
    return pairNum(H)+':'+pairNum(i);
  } else if (timestampDiff > 3600 &&timestampDiff < 36000) { 
    return parseInt(timestampDiff / 3600) + '小時'
  }else {
    var newDate = new Date((curTimestamp - 86400) * 1000); 
    if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
      return '昨天' + pairNum(H) + ':' + pairNum(i);
    } else if (curDate.getFullYear() == Y) {
      return parseInt(timestampDiff / (3600 * 24)) + '天'
        // return pairNum(m) + '月' + pairNum(d) + '日 ' + pairNum(H) + ':' + pairNum(i);
    } else {
      return parseInt(timestampDiff / (3600 * 24 * 30)) + '個月'
    }
  }
};