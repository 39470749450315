import './index.less'
import React, { useEffect,useState } from 'react'
import api from '@/api'
import {useHistory} from 'react-router-dom'
import {useCollect} from '@/hooks/home.js'
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import {DateFormat,DateWeeks} from '@/utils/custom'
import like from '@/assets/images/card/like.svg'
import IconlikeActive from '@/assets/images/user/like.svg'
import view from '@/assets/images/card/view.svg'
import { useTranslation } from 'react-i18next'
import {shiftTime} from '@/utils/shifttime'
import {ServiceType} from '@/utils/custom.js'
function ProductCard ({data}){
  const history = useHistory()
  const { t } = useTranslation()
  const [detail,setDetail ] = useState(data)
  const { collect } = useCollect()
  const handleDetail = (e)=>{
    if(e.type == 'event' || e.is_share != undefined){
      history.push(`/sharing/details/?id=${e.event_id || e.id}`)
    }else if(e.type == 'product'|| e.new_product_type){
      history.push(`/shopping/productDetail/?id=${e.id || e.product_id}`)
    }else{
      history.push(`/shopping/vegetable/?id=${e.event_id || e.id}`)
    }
    
    window.scrollTo(0, 0);
  }
  const handleCollect = async (e)=>{
    console.log(e)
    const {data,msg,code} = await collect(e)
    if(code!=0)return
    if(detail.collect_id){
      setDetail({ ...detail, ['collect_id']: '' })
    }else{
      setDetail({ ...detail, ['collect_id']: data.collect_id })
    }
  }
  const handleType = ()=>{
    var type;
    if(detail?.type || detail?.new_product_type){
      type = ServiceType((detail?.product_type || detail?.type || detail?.new_product_type),'name')
      if(!type){
        type = detail?.product_type == 'Product' && '現成商品' ||  detail?.product_type == 'vegetable' && '蔬菜日'
      }
    }else{
      type = detail?.event_type == 'Product' && '現成商品' ||  detail?.event_type == 'vegetable' && '蔬菜日'
    }
    return type
  }
  const handlePrice = ()=>{
    var type;
    if(detail?.price){
      type = detail?.price + t('my.hm')
    }else{
      type = DateWeeks(detail?.start_time)
    }
    return type
  }
  return (
    <Card className="card">
      <div className="card-backdrop">
        <Avatar src={api.url + detail?.banner} alt={detail?.title} />
        <img className="card-backdrop_like" src={detail.collect_id?IconlikeActive:like} alt="" onClick={()=>handleCollect(detail)} />
      </div>
      <div className="card-info">
        <Avatar src={api.url + (detail?.avatar || detail?.image)} alt={detail?.title} />
        <div className="card-info_text">
          <div className="card-info_text-title">
            {detail?.title || detail?.vegetable_name}
          </div>
          <div className="card-info_text-tag">
            <span>
              {handleType()}
            </span>
            <Divider orientation="vertical" flexItem />
            <span>
              {handlePrice()}
            </span>
            <Divider orientation="vertical" flexItem />
            <span>
              {shiftTime(detail?.create_time)}
            </span>
          </div>
          <div className="card-info_text-date">
            {((detail?.event_type || detail?.product_type) != 'Product' && (detail?.event_type || detail?.product_type) != 'vegetable' )&&<div className="">
              到期日：
              {detail?.end_time || detail?.end_date || detail?.product_arrival_date}
            </div>}
            {((detail?.event_type || detail?.product_type) == 'Product' || (detail?.event_type || detail?.product_type) == 'vegetable' )&& <>
              <div className="">
                開始時間：
                {detail?.start_time}
              </div>
              <div className="">
                結束時間：
                {detail?.end_time}
              </div>
              </>
            }
            
            {/* {DateFormat(detail?.date || detail?.start_date)}
            {detail?.start_time} - {detail?.end_time} PM */}
          </div>
            {/*  onClick={()=>handleDetail(detail)} */}
          <Button variant="contained" color="primary" onClick={()=>handleDetail(detail)}>
            <img src={view} alt="" />
            {t('home.viewDetails')}
          </Button>
          {/* {!detail.is_share&&<Link to={`/shopping/vegetable/?id=${detail.event_id}`}>
            <Button variant="contained" color="primary">
              <img src={view} alt="" />
              {t('home.viewDetails')}
            </Button>
          </Link>} */}
        </div>
      </div>
    </Card>
  )
}

export default React.memo(ProductCard)