import './index.less'
import React ,{useState,useImperativeHandle} from 'react'
import {useApplicant} from '@/hooks/sharing.js'
import {useParameter} from '@/hooks/useParameter.js'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const ApplyFor = (props,ref) => {
  const {singup} = useApplicant()
  const paramter = useParameter()
  const [accreditOpen,setAccreditOpen] = useState(false)
  const [accreditBool,setAccreditBool] = useState(false)
  const [successOpen,setSuccessOpen] = useState(false)
  useImperativeHandle(ref,()=>({
    handleAccreditOpen:() => {
      setAccreditOpen(true)
    }
  }))
  const handleAccreditClose = ()=>{
    setAccreditOpen(false)
    setAccreditBool(false)
  }
  const handleSuccessClose = ()=>{
    setSuccessOpen(false)
  }
  const handleVerify = async()=>{
    console.log(paramter.id)
    if(!accreditBool)return 
    const {data,code,msg} = await singup(paramter.id)
    if(code == 0) {
      setSuccessOpen(true)
      props.refresh()
    }
    handleAccreditClose()
  }
  return (
    <>
      <div className="apply-for">
        <Dialog
          className="apply-for_dialog"
          open={accreditOpen}
          onClose={handleAccreditClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
                                                                                                
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={<Checkbox checked={accreditBool}  name="accreditBool" color="primary" />} onChange={(event)=>setAccreditBool(event.target.checked)}/>
              <span>
                請勾選以確認您可以將您的手機和聯絡資訊分享給賣家。*
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAccreditClose}>取消</Button>
            <Button onClick={handleVerify} autoFocus>
              確認
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="apply-for_dialog"
          open={successOpen}
          onClose={handleSuccessClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="apply-for_dialog-top"></div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span>
                已成功發送申請，請等待賣家同意。
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSuccessClose}>繼續購物</Button>
            <Button onClick={handleVerify} autoFocus>
              前往我的購物頁面
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
export default ApplyFor