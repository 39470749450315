import './index.less'
import React,{useEffect, useState} from 'react'
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import photo_camera from '@/assets/images/user/photo_camera.svg'
import photo_library from '@/assets/images/user/photo_library.svg'
import Dots from '@/assets/images/user/Dots.svg'
import api from '@/api'
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {UserAction,tipsReveal} from '@/redux/actions'
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
export default function UserContact(){
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const histroy = useCustomHistory()
  const paramter = useParameter()
  const [progress,setProgress] = useState(0)
  const [status,setStatus] = useState(true)
  const [state,setState] = useState({
    nickname: "", 
    image: "", 
    first_name: "", 
    last_name: "", 
    gender: "", 
    birth: "", 
    relation_type: paramter.id, 
    relation: "", 
    relation_uid: "" 
  })
  useEffect(()=>{
    if(paramter.family_id){
      handleDetail()
    }
  },[])
  const handleDetail = async ()=>{
    const {data,code,msg} = await api.user.GetUserFamilyDetail({
      family_id:paramter.family_id
    })
    setState(data)
  }
  const handleCancel = ()=>{
    if(progress == 0){
      window.history.go(-1)
    }else{
      var num = progress - 100
      setProgress(num)
    }
  }
  const handleNext = async()=>{
    setStatus(true)
    if(progress == 0){
      // if(!state.image){
      //   setOpen(true)
      //   setMessage('請上傳頭像')
      //   return 
      // }
    }else if(progress == 100){
      if(!state.first_name)return setStatus(false)
      if(!state.last_name)return  setStatus(false)
      if(!state.nickname)return setStatus(false)
      if(!state.gender)return  setStatus(false)
      // if(!state.first_name)return setStatus(false)
      // if(!state.relation)return  setStatus(false)
      // if(!state.relation_uid)return setStatus(false)
      const {data,code,msg} = await api.user.PostUserFamily(state)
      if(code != 0){
        dispatch(tipsReveal({message:msg,type:'error'}))
        return 
      }
      window.history.go(-1)
    }
    if(progress<100){
      var num = progress + 100
      setProgress(num)
    }
    
  }
  const handleEdit = async()=>{
    setStatus(true)
    if(progress == 0){
      // if(!state.image){
      //   setOpen(true)
      //   setMessage('請上傳頭像')
      //   return 
      // }
    }else if(progress == 100){
      if(!state.first_name)return setStatus(false)
      if(!state.last_name)return  setStatus(false)
      if(!state.nickname)return setStatus(false)
      if(!state.gender)return  setStatus(false)
      // if(!state.first_name)return setStatus(false)
      // if(!state.relation)return  setStatus(false)
      // if(!state.relation_uid)return setStatus(false)
      const {data,code,msg} = await api.user.PutUserFamily(state)
      if(code != 0){
        dispatch(tipsReveal({message:msg,type:'error'}))
        return 
      }
      window.history.go(-1)
    }
    if(progress<100){
      var num = progress + 100
      setProgress(num)
    }
  }
  return (
    <>
      <div className='new-user'>
        <div className="new-user_main">
          {progress == 0 && <Avatar state={state} />}
          {progress == 100 && <Message state={state} setState={setState} status={status}/>}
        </div>
        <div className="new-user_buttom">
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            {progress == 0?t('public.cancel'):t('public.recoil')}
          </Button>
          {!paramter.family_id && <Button variant="contained" color="primary" onClick={handleNext}>
            {progress != 100?t('public.next'):t('public.economize')}
          </Button>}
          {paramter.family_id&& <Button variant="contained" color="primary" onClick={handleEdit}>
            {t('public.next')}
          </Button>}
        </div>
      </div>
    </>
  )
}

function Avatar({state}){
  const [anchorEl, setAnchorEl] = useState(false);
  const [avatar,setAvatar] = useState(state?.image)
  const handleClick = ()=>{
    setAnchorEl(true);
  }
  const handleUploadImage = async (e)=>{
    const image = e.target.files[0]
    const formData = new FormData();
    formData.append('file', image);
    const {data,code,msg} = await api.user.PostCommonFile(formData)
    if(code != 0)return 
    setAvatar(data.src)
    state.image = data.src
  }
  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <>
    <div className="user-main_avatar">
      <label htmlFor="icon-button-file" className="avatar-box">
        {!state.image?<div className="avatar-box_text">
          Upload<br/> Profile image
        </div>:<img src={api.url + state.image} alt="" />}
      </label>
      <input accept="image/*"  id="icon-button-file" type="file" onChange={handleUploadImage}/>
      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem className="browse-file" onClick={handleClose}>
          Browse File</MenuItem>
        <MenuItem onClick={handleClose}>
          <label htmlFor="icon-button-file">
            <span>Photo Library</span>
            <img src={photo_library} alt="" />
          </label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <span>Take Photo</span>
          <img src={photo_camera} alt="" />
        </MenuItem>
        <MenuItem onClick={handleClose}> 
          <span>Browse</span>
          <img src={Dots} alt="" />
        </MenuItem>
      </Menu>
      
    </div>
    </>
  )
}

function Message({state,setState,status}){
  const { t } = useTranslation()
  const handleChangeDate = (date)=>{
    const dt = date?.$d
    console.log(dt)
    var y = dt.getFullYear();
	  var m = dt.getMonth() + 1;
	  m = m < 10 ? ('0' + m) : m;
	  var d = dt.getDate();
 	  d = d < 10 ? ('0' + d) : d;
    var h = dt.getHours()<10?('0' + dt.getHours()) : dt.getHours();
	  var minute = dt.getMinutes();
	  minute = minute < 10 ? ('0' + minute) : minute;
	  let time = y + '-' + m + '-' + d+' '+h+':'+minute;
    setState({ ...state, ['birth']: time })
  }
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.basicMessage')}
        </div>
        <TextField
          label={`${t('new.lastName')}*`}
          name='first_name'
          multiline
          variant="outlined"
          defaultValue={state.first_name}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.first_name || status?'':t('new.basicMessage') + t('new.lastName')}
        />
        <TextField
          label={`${t('new.theName')}*`}
          name='last_name'
          multiline
          variant="outlined"
          defaultValue={state.last_name}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.last_name || status?'':t('new.basicMessage') + t('new.theName')}
        />
        <TextField
          label={`${t('new.nickname')}*`}
          name='nickname'
          multiline
          variant="outlined"
          defaultValue={state.nickname}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.nickname || status?'':t('new.basicMessage') + t('new.nickname')}
        />
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">
            {t('new.sex')}*
          </InputLabel>
          <Select
            name='gender'
            value={state.gender}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'男'}>男</MenuItem>
            <MenuItem value={'女'}>女</MenuItem>
          </Select>
          {state.gender || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.sex')}</FormHelperText>}
        </FormControl>
        {/* <TextField
          label="出生日期*"
          name='birth'
          multiline
          variant="outlined"
          defaultValue={state.birth}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.birth || status?'':'請輸入出生日期'}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={`${t('contact.relation')}`}
            name='birth'
            defaultValue={dayjs(state.birth)}
            value={dayjs(state.birth)}
            onChange={handleChangeDate}
          />
          {state.birth || status?'':<FormHelperText>{t('new.pleaseEnter')+t('new.dateOfBirth')}</FormHelperText>}
        </LocalizationProvider>
        <TextField
          label={`${t('new.dateOfBirth')}*`}
          name='relation'
          multiline
          variant="outlined"
          defaultValue={state.relation}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          // helperText={state.birth || status?'':'請輸入出生日期'}
        />
        <TextField
          label={`${t('contact.account')}`}
          name='relation_uid'
          multiline
          variant="outlined"
          defaultValue={state.relation_uid}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          // helperText={state.birth || status?'':'請輸入帳號'}
        />
      </div>
    </>
  )
}
