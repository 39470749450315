document.addEventListener("plusready", function() {
  var first = null; 
  var webview = plus.webview.currentWebview();
  plus.key.addEventListener("backbutton", function() {
    webview.canBack(function(e) {
      // console.log(e);
      if (e.canBack) {
        // webview.back();
        window.history.go(-1) 
      } else {
        //首次按键，提示‘再按一次退出应用’
        if (!first) {
          first = new Date().getTime(); //获取第一次点击的时间戳
          // //通过H5+ API 调用Android 上的toast 提示框
          plus.nativeUI.toast("再按一次退出应用", {
            duration: "short",
          }); 
      // 一秒后重置first
          setTimeout(function() {
            first = null;
          }, 2000);
        } else {
          if (new Date().getTime() - first < 2000) {
            //获取第二次点击的时间戳, 两次之差 小于 1000ms 说明1s点击了两次,则退出登录
            plus.runtime.quit(); 
          }
        }
      }
    });
  });
});
