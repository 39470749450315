import { useEffect,useState,forwardRef,useRef } from 'react';
import api from '@/api'
import ShearCard from '../Card'
import Button from '@mui/material/Button';
import ServiceDialog from '../ServiceDialog'
import { useInView } from "react-cool-inview";
export default function Shear({handleDialogDetails,value}){
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const ServiceDialogDiv = forwardRef(ServiceDialog)
  const ServiceDialogRef = useRef(null)
  const [formData,setFormData] = useState({
    is_share:1,
    limit:5,
    page:1,
  })
  useEffect(()=>{
    handleSecondhand()
  },[formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  const handleSecondhand = async ()=>{
    const {data,code,msg,total:count} = await api.shear.GetUserEvent(formData);
    setTotal(count)
    if(data.length == undefined){
      setList([])
      return 
    }
    setList([...list,...data])
  }
  const refresh = ()=>{
    setList([])
    handleSecondhand()
  }
  const handleService = (e)=>{
    ServiceDialogRef.current.handleOpen(e)
  }
  return (
    <>
      <ServiceDialogDiv ref={ServiceDialogRef} />
      {list.length <= 0 && <div className="shear-box" onClick={()=>handleDialogDetails(true)}>
        <div className="shear-box_add">
          {/* <img src={IconAdd} alt="" /> */}
          你還沒有分享你的技能
        </div>
      </div>}
      {list.map((item,index)=><div key={item.event_id + index} ref={index === list.length - 1 ? observe : null}><ShearCard refresh={refresh} item={item} type={'shear'} handleService={handleService}/></div>)}
      <div className='shear-btn'>
        <Button variant="contained" color="primary" onClick={()=>handleDialogDetails(true)}>
          立即開始分享
        </Button>
      </div>
    </>
  )
}

