// food
import './index.less'
import {useState,useEffect,useRef,forwardRef} from 'react'
import api from '@/api'
import {useParameter} from '@/hooks/useParameter.js'
import IconCabbage from '@/assets/images/food/cabbage.svg'
import IconBabyChineseCabbage from '@/assets/images/food/BabyChineseCabbage.svg'
import IconCarrot from '@/assets/images/food/carrot.svg'
import IconCucumber from '@/assets/images/food/cucumber.svg'
import IconGinger from '@/assets/images/food/ginger.svg'
import IconGreenPepper from '@/assets/images/food/greenPepper.svg'
import IconHotPepper from '@/assets/images/food/hotPepper.svg'
import IconLettuce from '@/assets/images/food/lettuce.svg'
import IconOkra from '@/assets/images/food/okra.svg'
import IconPapaya from '@/assets/images/food/papaya.svg'
import IconPrpper from '@/assets/images/food/prpper.svg'
import IconWaterspout from '@/assets/images/food/waterspout.svg'
import IconwhiteRadish from '@/assets/images/food/whiteRadish.svg'
import FoodDialog from './foodDialog'
export default function Food(){
  const paramter = useParameter()
  const FoodDialogDiv = forwardRef(FoodDialog)
  const foodDialogRef = useRef(null)
  const [list,setList] = useState([])
  const [detail,setDetail] = useState({
    title:''
  })
  useEffect(()=>{
    handleEventDetail()
    handleShopEvent()
  },[])
  const handleBack = ()=>{
    window.history.go(-1)
  }
  const handleEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventDetail({
      event_id:paramter.id
    })
    setDetail(data)
  }
  const handleShopEvent = async ()=>{
    const {data,code,msg} = await api.home.GetCommonProduct({
      event_id:paramter.id
    })
    console.log(data,code,msg)
    if(data.length == undefined)return 
    setList(data)
  }
  const handleFoodDialog = (e)=>{
    foodDialogRef.current.handleOpen(e)
  }
  return (
    <div className='food'>
      <div className="food-head">
        <svg className="food-head-back" onClick={handleBack} xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
          <path d="M12 1L2 10L12 19" stroke="black" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <div className="food-head-content">
          <div className="food-head_title">
            {detail.title}
          </div>
          <div className="food-head_status">
            online
          </div>
        </div>
        
      </div>
      <div className="food-main">
        {list.map((item)=><div key={item.product_id} className="food-main_item" onClick={()=>handleFoodDialog(item)}>
          {item.product_type == '菜心' && <img src={IconCabbage} alt="" />}
          {item.product_type == '唐生菜' && <img src={IconLettuce} alt="" />}
          {item.product_type== '秋葵' && <img src={IconCucumber} alt="" />}
          {item.product_type == '青椒' && <img src={IconGreenPepper} alt="" />}
          {item.product_type == '通菜' && <img src={IconWaterspout} alt="" />}
          {item.product_type == '娃娃菜' && <img src={IconBabyChineseCabbage} alt="" />}
          {item.product_type == '辣椒' && <img src={IconPrpper} alt="" />}
          {item.product_type == '尖椒' && <img src={IconHotPepper} alt="" />}
          {item.product_type == '木瓜' && <img src={IconPapaya} alt="" />}
          {item.product_type == '青瓜' && <img src={IconOkra} alt="" />}
          {item.product_type == '紅蘿蔔 ' && <img src={IconCarrot} alt="" />}
          {item.product_type == '白蘿蔔' && <img src={IconwhiteRadish} alt="" />}
          {item.product_type == '姜' && <img src={IconGinger} alt="" />}
          <span>{item.product_type} </span>
        </div>)}
      </div>
      <FoodDialogDiv ref={foodDialogRef}  />
    </div>
  )
}


