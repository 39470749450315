import api from '@/api'
const pagehidefun = (()=>{
  var date = new Date();
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear(); //getFullYear()代替  getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1;
  //日 getDate()：(1 ~ 31)
  var day = date.getDate();
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours();
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes();
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds();
  item2.value = year +'-'+addzero(month)+'-'+addzero(day)+' '+addzero(hour)+':'+addzero(minute)+':'+second
  item.value = Date.parse(new Date())/1000
})
const addzero = (s=>{
  return s<10?('0'+s):s
})

function BurialPoint (){
  let startTime = null;
  let endTime = null;
  // 页面加载时记录开始时间
  window.addEventListener('load', () => {
    startTime = new Date();
    console.log('进入')
  });

  // 页面关闭或切换时记录结束时间并计算停留时间
  window.addEventListener('beforeunload', async() => {
    endTime = new Date();
    const timeSpent = endTime - startTime; // 停留时间，以毫秒为单位
    console.log(`用户在页面停留了 ${timeSpent} 毫秒`);
    const {data,code,msg} = await api.user.PostUserOnlineLog({
      online_time:timeSpent
    })
    console.log(data,code,msg)
  });
}

export default BurialPoint