import './index.less';
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Logo from '@/assets/images/login/TExLogo.svg';
import api from '@/api'
import {tipsReveal} from '@/redux/actions'
import useSubject from '@/hooks/useSubject.js'
import { useDispatch } from 'react-redux'
import login1 from '@/assets/images/login/login1.svg'
import login2 from '@/assets/images/login/login2.svg'
import login3 from '@/assets/images/login/login3.svg'
const passwordRE =/^(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
import PhoneNumber from '@/components/PhoneNumber'
export default function Register(){
  const histroy = useCustomHistory()
  const dispatch = useDispatch()
  const paramter = useParameter()
  useSubject(paramter.id)
  const [status,setStatus] = useState(true)
  const [ text2 ,setText2] = useState('至少8個字符，1個數字和1個大寫字母')
  const [ text ,setText] = useState('至少8個字符，1個數字和1個大寫字母')
  const [state, setState] = useState({
    id:1,
    phone_number:'',
    phone_code:'852',
    password:'',
    re_password:'',
    user_type:paramter.id,
    bool:false,
  });
  useEffect(()=>{
    return()=>{
      var main = '#F16526'
      var background = '#FFEDE7'
      var border = '#D8C2BC'
      var card = '#FDEEE7'
      document.documentElement.style.setProperty(
        "--primary-color",
        main,
      );
      document.documentElement.style.setProperty(
        "--primary-background",
        background
      );
      document.documentElement.style.setProperty(
        "--primary-border",
        border
      );
      document.documentElement.style.setProperty(
        "--primary-card-background",
        card
      );
    }
  },[])
  const handleBack = ()=>{
    histroy.replace({pathname:`/entrance/main`})
  }
  const handleSumit = async()=>{
    if(!state.phone_number)return setStatus(false)
    if(!state.password)return setStatus(false)
    if(!passwordRE.test(state.password)){
      setText2('至少8個字符，1個數字和1個大寫字母')
      setStatus(false)
      return
    }
    if(!state.re_password){
      setText('至少8個字符，1個數字和1個大寫字母')
      setStatus(false)
      return 
    }
    if(state.re_password!=state.password){
      setText('兩次密碼不一致')
      setStatus(false)
      return
    }
    const {data,code,msg} = await api.user.GetUseSmsCode({
      phone_number:state.phone_number,
      is_register:1,
    })
    console.log(data,code,msg)
    if(code != 0){
      dispatch(tipsReveal({message:msg,type:'error'}))
      return 
    }
    histroy.replace({pathname:`/entrance/code`,state:{state}})
  }
  return (
    <div className='register'>
      <div className="register-back">
        <span onClick={handleBack}>
          <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1L2 10L12 19" stroke="black" stroke-width="2" stroke-linecap="round"/>
          </svg>
          返回
        </span>
      </div>
      <div className="register-logo">
        {paramter.id == 1 && <img src={login1} alt="" />}
        {paramter.id == 2 && <img src={login3} alt="" />}
        {paramter.id == 3 && <img src={login2} alt="" />}
      </div>
      <div className="register-form">
        <form action="">
          <PhoneNumber code={state.phone_code} phone={state.phone_number} status={status} formData={state} setFormData={setState}/>
          <TextField
            id="outlined-textarea"
            label="密碼"
            name="password"
            type="password"
            variant="outlined"
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            helperText={state.password&&state.password.length>=8 && passwordRE.test(state.password) || status  ?'':text2}
          />
          <TextField
            id="outlined-textarea"
            label="密碼"
            name="re_password"
            type="password"
            variant="outlined"
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            helperText={state.re_password&&state.re_password.length>=8&&state.re_password==state.password || status?'':text}
          />
          <div className="register-form_checkbox">
            <FormControlLabel
              control={<Checkbox checked={state.bool}  name="bool" color="primary" onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.checked })}}/>}
              label="記住密碼"
            />
          </div>
          <Button variant="contained" color="primary" onClick={handleSumit}>
            註冊
          </Button>
          <div className="register-form_login">
            <span onClick={()=>histroy.replace('/entrance/login')}>
              登入
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}
