import {tipsOpen} from '@/redux/actions'
import { useDispatch,useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './index.less'
export default function AlertTips(){
  const dispatch = useDispatch()
  const redusxMessage = useSelector((state)=>{
    return state.TipsRd.message
  })
  const redusxOpen = useSelector((state)=>{
    return state.TipsRd.open
  })
  const redusxType = useSelector((state)=>{
    return state.TipsRd.type
  })
  const redusxIcon = useSelector((state)=>{
    return state.TipsRd.icon
  })
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(tipsOpen());
  };
  return (
    <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={redusxOpen} autoHideDuration={3000} onClose={handleClose}>
      <Alert icon={redusxIcon || false} severity={redusxType}>
        {redusxMessage}
      </Alert>
    </Snackbar>
  )
}