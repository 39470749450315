import './index.less';
import {useParameter} from '@/hooks/useParameter.js'
import { useEffect,useState } from 'react'
import {useHistory} from 'react-router-dom'
import {useUserInfo} from '@/hooks/user.js'
import { useApplicant } from '@/hooks/sharing.js'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
export default function ScanResult(){
  const dispatch = useDispatch()
  const histroy = useHistory()
  const paramter = useParameter()
  const {acquire,append} = useUserInfo()
  const {payment} = useApplicant()
  // 扫码结果类型
  const [type,setType] = useState(paramter.value.split('?')[2])
  const [value,setValue] = useState(paramter.value.split('?')[1])
  useEffect(()=>{
    setValue(paramter.value.split('?')[1])
    setType(paramter.value.split('?')[2])
  },[paramter])
  useEffect(()=>{
    console.log(type,value)
    handleResult()
  },[type,value])
  const handleResult = ()=>{
    switch (type){
      case 'addFamily':
        handleAddFamily()
        break;
      case 'payment':
        handlePayment()
        break;
      default :
        // dispatch(tipsReveal({message:'識別錯誤',type:'error'}))
        histroy.replace(`/user/scanOtherCode/?value=${paramter.value}`)
      }
  }
  // 添加家庭成員
  const handleAddFamily = async()=>{
    console.log(paramter.id)
    await append({
      relation_uid:value,
      relation_type:paramter.id == 'undefined' ? 1 : paramter.id
    })
    histroy.go(-1)
  }
  // 付款
  const handlePayment = async()=>{
    const {data,code,msg} = await payment({
      join_id:value
    })
    if(code != 0)return histroy.go(-1)
    histroy.replace(`/sharing/paymentStatus/?is_share=${paramter.value.split('?')[3]}`)
  }
  return (
    <>
      <div className="scan-result">
        <CircularProgress />
      </div>
    </>
  )
}