import './index.less'
import api from '@/api'
import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useCertificate} from '@/hooks/user.js'
import {useUserInfo} from '@/hooks/user.js'
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TopHead from '@/components/TopHead'
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import AptitudeAdd from './aptitudeAdd'
import Button from '@mui/material/Button';
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
export default function Intro(){
  const dispatch = useDispatch()
  const {edit} = useUserInfo()
  const histroy = useHistory()
  const {acquire} = useCertificate()
  const [certBool,setCertBool] = useState(false)
  const [certList,setCertList] = useState([])
  const [aptitudeList,setAptitudeList] = useState([
    {
      date:'2024年7月21日',
      name:'香港理工大學互動設計系畢業',
      img:'',
    },
    {
      date:'2024年7月21日',
      name:'香港理工大學互動設計系畢業',
      img:'',
    }
  ])
  // 個人簡介
  const [introduction,setIntroduction] = useState('')
  // 興趣愛好
  const [hobby,setHobby] = useState([])
  const [hobbyBool,setHobbyBool] = useState(false)
  // 個人信息
  const [state,setState] = useState({})
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    setState({...redusxUser})
    setHobby(redusxUser?.hobby || [])
    setIntroduction(redusxUser.introduction)
  },[redusxUser])
  useEffect(()=>{
    requestList()
  },[])
  // 輸入框失去焦點
  const handleBlur = (event)=>{
    if(event.target.value === state.introduction)return
    state.introduction = event.target.value
    setIntroduction(event.target.value)
    handleEdit()
  }
  const handleEdit = async()=>{
    const {data,code,msg} = await edit({...state,hobby})
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    setHobbyBool(false)
    dispatch(tipsReveal({message:'存儲成功',type:'success'}))
  }
  // 獲取資質列表
  const requestList = async()=>{
    setCertBool(false)
    const {code,msg,data} = await acquire();
    if(data.length == undefined)return 
    setCertList(data)
  }
  const handleAotitudeAdd = ()=>{
    setCertBool(true)
    if(certBool)return 
    var obj = {
      acquisition_date:'', // 獲取時間
      cert_name:'', // 證書名稱
      cert_img:'' // 證書圖片
    }
    setAptitudeList([obj,...aptitudeList])
  }
  const handleHobbyAdd = ()=>{
    setHobby([...hobby,''])
  }
  const handleHobbyChange = (event,index)=>{
    setHobbyBool(true)
    hobby[index] = event.target.value
  }
  // 存儲
  const handleStore = ()=>{
    handleEdit()
  }
  const handleUser = (e)=>{
    histroy.push(`/user/new/?progress=${e}`)
  }
  return (
    <div className="intro">
      <TopHead title='個人簡介' />
      <div className="intro-main">
        <div className="intro_card">
          <div className="intro_card-left">
            <div className="intro_card-left-avatar" onClick={()=>handleUser(0)}>
              <Avatar src={api.url + state.image} sx={{ width: 86, height: 86 }}/>
              <svg className="intro_card-left-avatar_edit" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.6245" cy="12.6245" r="12.6245" fill="#FFEDE7"/>
                <path d="M5.31257 19.6793L5.32967 15.9218L8.85002 19.4743L5.31257 19.6793Z" fill="#CE4C09"/>
                <path d="M9.62837 18.7044L6.10802 15.1519L14.656 6.68135L18.1763 10.2339L9.62837 18.7044ZM19.0645 9.3538L15.5441 5.80126L16.7656 4.59082L20.286 8.14336L19.0645 9.3538Z" fill="#CE4C09"/>
              </svg>
            </div>
            <div className="intro_card-left-name" onClick={()=>handleUser(25)}>
              {state.username}
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.79164 13.348L1.80654 10.0741L4.8739 13.1695L1.79164 13.348Z" fill="#CE4C09"/>
                <path d="M5.55167 12.4986L2.48431 9.40324L9.93252 2.02251L12.9999 5.11791L5.55167 12.4986ZM13.7737 4.35107L10.7064 1.25567L11.7705 0.201172L14.8379 3.29657L13.7737 4.35107Z" fill="#CE4C09"/>
              </svg>
            </div>
          </div>
          <div className="intro_card-right">
            <div className="intro_card-right-standing">
              香港婦女中心協會會員
            </div>
            <Divider />
            <div className="intro_card-right-info">
              <div className="right-info_num">
                1個
              </div>
              <div className="right-info_des">
                已獲機構親自認證的資歷
              </div>
            </div>
            <Divider />
            <div className="intro_card-right-info">
              <div className="right-info_num">
                3個月
              </div>
              <div className="right-info_des">
                加入時間
              </div>
            </div>
          </div>
        </div>
        <TextField
          name="introduction"
          label="個人簡介"
          multiline
          variant="outlined"
          value={introduction}
          defaultValue={introduction}
          onBlur={handleBlur}
        />
        <div className="intro_aptitude">
          <div className="intro_aptitude-head">
            <label>
              認證的資歷
            </label>
            <svg onClick={()=>handleAotitudeAdd()} width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.414062" width="30" height="31" rx="15" fill="#FFEDE7"/>
              <path d="M22.6511 18.2241H17.4311V23.5641H12.5711V18.2241H7.35105V13.6341H12.5711V8.26406H17.4311V13.6341H22.6511V18.2241Z" fill="#CE4C09"/>
            </svg>
          </div>
          <div className="intro_aptitude-content">
            {certBool && <div className="aptitude-content-item">
              <AptitudeAdd refresh={requestList} />
            </div>}
            {certList.map((item,index)=><div key={item.certificate_id} className="aptitude-content-item">
              <div className="aptitude-content-item_head">
                <div className="item_head-info"> 
                  <div className="item_head-info-date">
                    {item.acquisition_date}
                  </div>
                  <div className="item_head-info-des">
                    {item.cert_name}
                  </div>
                </div>
                <div className="item_head-status">
                  {item.is_approve == 1 && <Tooltip title="驗證成功" disableHoverListener arrow  placement="top" enterTouchDelay="0">
                    <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.914062" width="27" height="27.5" rx="13.5" fill="#DCFFCF"/>
                      <path d="M6 15.4141L10.5 19.9141L21 9.41406" stroke="#056E00"/>
                    </svg>
                  </Tooltip>
                  }
                  {item.is_approve == 0 && <Tooltip title="驗證中" disableHoverListener arrow  placement="top" enterTouchDelay="0"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="#FFF3CF"/>
                    <path d="M14.924 7.99L14.69 16.72H12.98L12.746 7.99H14.924ZM13.898 20.626C13.526 20.626 13.214 20.5 12.962 20.248C12.71 19.996 12.584 19.684 12.584 19.312C12.584 18.94 12.71 18.628 12.962 18.376C13.214 18.124 13.526 17.998 13.898 17.998C14.258 17.998 14.564 18.124 14.816 18.376C15.068 18.628 15.194 18.94 15.194 19.312C15.194 19.684 15.068 19.996 14.816 20.248C14.564 20.5 14.258 20.626 13.898 20.626Z" fill="#FFC800"/>
                  </svg></Tooltip>}
                  {item.is_approve == 2 && <Tooltip title="驗證失敗" disableHoverListener arrow  placement="top" enterTouchDelay="0"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="#FFEDE7"/>
                  <line x1="10.1105" y1="10.1112" x2="17.8886" y2="17.8894" stroke="#FF3030" stroke-width="3"/>
                  <line y1="-1.5" x2="11" y2="-1.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 9.0498 16.8281)" stroke="#FF3030" stroke-width="3"/>
                  </svg></Tooltip>}
                </div>
              </div>
              <div className="aptitude-content-item_main">
                <Avatar
                   alt={item.cert_name}
                   src={api.url + item.cert_img}
                   sx={{ width: 87, height: 110 }}
                   variant="rounded"
                />
              </div>
            </div>)}
          </div>
          <div className="intro_aptitude-head">
            <label>
              愛好/ 興趣
            </label>
            <svg onClick={()=>handleHobbyAdd()} width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.414062" width="30" height="31" rx="15" fill="#FFEDE7"/>
              <path d="M22.6511 18.2241H17.4311V23.5641H12.5711V18.2241H7.35105V13.6341H12.5711V8.26406H17.4311V13.6341H22.6511V18.2241Z" fill="#CE4C09"/>
            </svg>
          </div>
          <div className="intro_aptitude-content">
            {hobby?.length > 0 && hobby.map((item,index)=><TextField
              key={index}
              label={`愛好/ 興趣`}
              name='hobby'
              multiline
              variant="outlined"
              defaultValue={item}
              onChange={event=>handleHobbyChange(event,index)}
              />)}
              {hobbyBool && <Button variant="contained" onClick={()=>handleStore()}>存儲</Button>}
          </div>
        </div>
      </div>
    </div>
  )
}