import React from 'react'
// import {useRoutes} from 'react-router-dom'
import RouterAuth from './RouterAuth'
// const Home = React.lazy(()=>import("@/views/home"))
// const Shopping = React.lazy(()=>import("@/views/shopping"))
// const ShoppingShop = React.lazy(()=>import("@/views/shopping/shop"))
// const ShoppingCart = React.lazy(()=>import("@/views/shopping/cart"))
// const ShoppingOrder = React.lazy(()=>import("@/views/shopping/order"))
// const ShoppingVegetable = React.lazy(()=>import("@/views/shopping/vegetable"))
// const ShoppingFood = React.lazy(()=>import("@/views/shopping/food"))
// const Entrance = React.lazy(()=>import("@/views/entrance"))
// const EntranceMain = React.lazy(()=>import("@/views/entrance/main"))
// const EntranceLogin = React.lazy(()=>import("@/views/entrance/login"))
// const EntranceRegister = React.lazy(()=>import("@/views/entrance/register"))
// const EntranceCode = React.lazy(()=>import("@/views/entrance/code"))
// const Sharing = React.lazy(()=>import("@/views/sharing"))
// const SharingHome = React.lazy(()=>import("@/views/sharing/home"))
// const SharingForm = React.lazy(()=>import("@/views/sharing/form"))
// const SharingPreview = React.lazy(()=>import("@/views/sharing/preview"))
// const SharingDetails = React.lazy(()=>import("@/views/sharing/details"))
// const SharingPayment = React.lazy(()=>import("@/views/sharing/payment"))
// const SharingMark = React.lazy(()=>import("@/views/sharing/mark"))
// const User = React.lazy(()=>import("@/views/user"))
// const UserNew = React.lazy(()=>import("@/views/user/new"))
// const UserMy = React.lazy(()=>import("@/views/user/my"))
// const UserContact = React.lazy(()=>import("@/views/user/contact"))
// const UserTransition = React.lazy(()=>import("@/views/user/transition"))
// const UserQrCode = React.lazy(()=>import("@/views/user/qrCode"))
// const UserCheck = React.lazy(()=>import("@/views/user/check"))
// const UserCollect = React.lazy(()=>import("@/views/user/collect"))
import Home from '@/views/home'
// import Shopping from "@/views/shopping"
import ShoppingShop from "@/views/shopping/shop"
import ShoppingCart from "@/views/shopping/cart"
import ShoppingOrder from "@/views/shopping/order"
import ShoppingVegetable from "@/views/shopping/vegetable"
import ShoppingFood from "@/views/shopping/food"
import ProductDetail from "@/views/shopping/productDetail"
// import Entrance from "@/views/entrance"
import EntranceMain from "@/views/entrance/main"
import EntranceLogin from "@/views/entrance/login"
import EntranceRegister from "@/views/entrance/register"
import EntranceCode from "@/views/entrance/code"
import EntranceForgot from "@/views/entrance/forgot"
// import Sharing from "@/views/sharing"
import SharingHome from "@/views/sharing/home"
import SharingForm from "@/views/sharing/shearForm"
import SharingPreview from "@/views/sharing/preview"
import SharingDetails from "@/views/sharing/details"
import SharingPayment from "@/views/sharing/payment"
import SharingSendPayment from "@/views/sharing/sendPayment"
import SharingMark from "@/views/sharing/mark"
import SharApplicant from "@/views/sharing/applicant"

import SharingPaymentStatus from "@/views/sharing/paymentStatus"
// import User from "@/views/user"
import UserNew from "@/views/user/new"
import UserMy from "@/views/user/my"
import UserContact from "@/views/user/contact"
import UserTransition from "@/views/user/transition"
import UserQrCode from "@/views/user/qrCode"
import UserScanCode from "@/views/user/scanCode"
import UserCheck from "@/views/user/check"
import UserCollect from "@/views/user/collect"
import UserExchange from "@/views/user/exchange"
import UserTransfer from "@/views/user/transfer"
import UserIntro from '@/views/user/intro'
import UserScanResult from '@/views/user/scanResult'
import UserOtherCode from '@/views/user/otherCode'
// const DynamicRouter = [
//   {path:'/',element:<RouterAuth><Home/></RouterAuth>},
//   {path:'/entrance',element:<Entrance/>},
//   {path:'/entrancemain',element:<EntranceMain />},
//   {path:'/entrancelogin',element:<EntranceLogin/>},
//   {path:'/entranceregister',element:<EntranceRegister/>},
//   {path:'/entrancecode',element:<EntranceCode/>},
//   {path:'/home',element:<RouterAuth><Home/></RouterAuth>},
//   {path:'/shopping',element:<RouterAuth><Shopping/></RouterAuth>,children:[
//     {path:'shop',element:<ShoppingShop />},
//     {path:'card',element:<ShoppingCart />},
//     {path:'order',element:<ShoppingOrder />},
//     {path:'vegetable',element:<ShoppingVegetable />},
//     {path:'food',element:<ShoppingFood />},
//   ]},
//   {path:'/user',element:<RouterAuth><User/></RouterAuth>,children:[
//     {path:'new',element:<UserNew />},
//     {path:'my',element:<UserMy />},
//     {path:'contact',element:<UserContact />},
//     {path:'transition',element:<UserTransition />},
//     {path:'qrcode',element:<UserQrCode />},
//     {path:'check',element:<UserCheck />},
//     {path:'collect',element:<UserCollect />}
//   ]},
//   {path:'/sharing',element:<RouterAuth><Sharing/></RouterAuth>},
//   {path:'/sharinghome',element:<SharingHome />},
//   {path:'/sharingform',element:<SharingForm />},
//   {path:'/sharingpreview',element:<SharingPreview />},
//   {path:'/sharingdetails',element:<SharingDetails />},
//   {path:'/sharingpayment',element:<SharingPayment />},
//   {path:'/sharingmark',element:<SharingMark />},
// ]

const DynamicRouter = [
  {path:'/',component:Home,exact:true,redirct:true},
  {path:'/entrance/main',component:EntranceMain,redirct:false},
  {path:'/entrance/login',component:EntranceLogin,redirct:false},
  {path:'/entrance/register',component:EntranceRegister,redirct:false},
  {path:'/entrance/code',component:EntranceCode,redirct:false},
  {path:'/entrance/forgot',component:EntranceForgot,redirct:false},
  {path:'/home',component:Home,redirct:false},
  {path:'/shopping/shop',component:ShoppingShop,redirct:true },
  {path:'/shopping/cart',component:ShoppingCart,redirct:true },
  {path:'/shopping/order',component:ShoppingOrder,redirct:true },
  {path:'/shopping/vegetable',component:ShoppingVegetable,redirct:true},
  {path:'/shopping/food',component:ShoppingFood,redirct:true},
  {path:'/shopping/productDetail',component:ProductDetail,redirct:true},
  {path:'/user/new',component:UserNew,redirct:true},
  {path:'/user/my',component:UserMy,redirct:true},
  {path:'/user/contact',component:UserContact,redirct:true},
  {path:'/user/transition',component:UserTransition,redirct:true},
  {path:'/user/qrcode',component:UserQrCode,redirct:true},
  {path:'/user/scancode',component:UserScanCode,redirct:true},
  {path:'/user/check',component:UserCheck,redirct:true},
  {path:'/user/collect',component:UserCollect,redirct:true},
  {path:'/user/exchange',component:UserExchange,redirct:true},
  {path:'/user/transfer',component:UserTransfer,redirct:true},
  {path:'/user/intro',component:UserIntro,redirct:true},
  {path:'/user/scanResult',component:UserScanResult,redirct:true},
  {path:'/user/scanOtherCode',component:UserOtherCode,redirct:true},
  {path:'/sharing/home',component:SharingHome,redirct:true},
  {path:'/sharing/shearForm',component:SharingForm,redirct:true},
  {path:'/sharing/preview',component:SharingPreview,redirct:true},
  {path:'/sharing/details',component:SharingDetails,redirct:true},
  {path:'/sharing/payment',component:SharingPayment,redirct:true},
  {path:'/sharing/send/payment',component:SharingSendPayment,redirct:true},
  {path:'/sharing/mark',component:SharingMark,redirct:true},
  {path:'/sharing/applicant',component:SharApplicant,redirct:true},
  {path:'/sharing/paymentStatus',component:SharingPaymentStatus,redirct:true},
]
export default DynamicRouter
// export default function DynamicRouter(){
//   const elements = useRoutes(router)
//   return elements
// }

