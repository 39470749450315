// Preview
import './index.less';
import React ,{useState,useEffect,useImperativeHandle} from 'react'
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import api from '@/api'
import TopHead from '@/components/TopHead'
import ProhibitBack from '@/components/ProhibitBack'
import { useTranslation } from 'react-i18next'
import {ServiceType} from '@/utils/custom.js'
const PreviewDrawer =(props,ref)=>{
  const { t } = useTranslation()
  const [open,setOpen] = useState(false)
  const [details,setDetails] = useState({
    type:'',
    title:'',
    price:'',
    detail:'',
    banner:'',
    image:'',
    location:'',
    start_date:'',
    start_time:'',
    end_time:'',
    tb_c:'',
  })
  const [dateList,setDateList] = useState([])
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setDetails(e)
      setDateList(e?.key)
      setOpen(true)
    }
  }))

  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const handleClose = ()=>{
    setOpen(false)
  }
  // usePageReturn(state,handleClose)
  const handleEdit = () =>{

  }
  const handleGet = ()=>{

  }
  // 數量
  const handleNumber = () =>{
    {/*is_share是1則是分享  is_product如果是1則是產品，如果是2則是活動服務*/}
    var type = details?.type && ServiceType(details?.type)
    var name;
    if(type == '產品' && details?.is_share == 1) name = '剩餘數量'
    else if(type == '服務' && details?.is_share == 1) name = '可報名人數'
    // {/* is_share是0則是請求 */}
    else if (type == '服務' && details?.is_share == 0) name = '可請求人數'
    else if(type == '產品' || details?.is_share == 0) name = '請求的物品數量'
    return name
  }
  const handleDateLabel = ()=>{
    var type = details?.type && ServiceType(details?.type)
    var name;
    if(type == '產品' && details?.is_share == 1) name = '截止購買時間'
    else if(type == '服務' && details?.is_share == 1) name = '截止請求時間'
    // {/* is_share是0則是請求 */}
    else if (type == '服務' && details?.is_share == 0) name = '截止申請時間'
    else if(type == '產品' || details?.is_share == 0) name = '截止申請時間'
    return name
  }
  return (
    <>
      <Drawer className='preview-drawer' anchor={'right'} open={open} onClose={handleClose}>
        <TopHead/>
        <ProhibitBack open={open} close={handleClose} />
        <div className="drawer-main">
          <div className="preview-head">
            <div className="preview-head_avatar">
              <Avatar src={api.url + redusxUser.image} alt={redusxUser.first_name} />
              <div className="avatar-name">
                {redusxUser.username}
              </div>
            </div>
            {/* <div className="preview-head_close" onClick={handleClose}>
              <img src={IconClose} alt="" />
            </div> */}
          </div>
          <div className="preview-title">
            {details?.title}
          </div>
          <div className="preview-tag">
            <span>{details?.type && ServiceType(details?.type,'name')}</span>
            <Divider orientation="vertical" flexItem />
            <span>{details?.price} {t('my.hm')}</span>
            <Divider orientation="vertical" flexItem />
            <span>刚刚</span>
          </div>
          <div className="preview-content">
            {details?.detail}
          </div>
          <div className="preview-img">
            {details.banner && <img src={api.url + details.banner} alt="" />}
            {details?.image.split(",").map(item=>item && 
            <img key={item} src={api.url + item}/>)}
          </div>
          <div className="preview-list">
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                價格:
              </label>
              <div className="list-card_value">
                {details?.price} 時分
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {handleNumber()}：
              </label>
              <div className="list-card_value">
                {details?.num_of_people}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                交收地點：
              </label>
              <div className="list-card_value">
                {details?.location}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {handleDateLabel()}：
              </label>
              <div className="list-card_value">
                {details?.end_time}
              </div>
            </div>
            {details?.is_share == 1 && <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {details?.type && ServiceType(details?.type) == '產品' ? '產品期限' : '活動期限'}：
              </label>
              <div className="list-card_value">
                {dateList[0]?.start_time} - {dateList[0]?.start_date}
              </div>
            </div>}
            {details?.is_share == 0 && <>
              {dateList.map((item,index)=><div key={index} className="list-card">
              <label htmlFor="" className="list-card_label">
                服務日期範圍{index + 1}：
              </label>
              <div className="list-card_value">
                {item?.start_time} - {item?.start_date}
              </div>
            </div>)}
            </>}
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.selectTime')}:
              </label>
              <div className="list-card_value">
                {details?.start_date}
              </div>
            </div>
            {/* <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.selectTime')}:
              </label>
              <div className="list-card_value">
                {details?.tb_c}
              </div>
            </div> */}
          </div>
          {/* <div className="preview-button">
            <Button variant="outlined" color="primary" onClick={handleEdit}>
              修改
            </Button>
            <Button variant="contained" color="primary" onClick={handleGet}>
              獲取批准
            </Button>
          </div> */}
        </div>
      </Drawer>
    </>
  )
}
export default PreviewDrawer

