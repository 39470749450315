function hide(){
  return {
    type:"NAVIGATIONHIDE",
    value:false
  }
}
function show(){
  return {
    type:"NAVIGATIONSHOW",
    value:true
  }
}
export {
  hide,
  show
}