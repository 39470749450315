export default function useTimeData(){
  // 年月份
  const pickerDataTime = (date)=>{
    const dt = date?.$d
    var y = dt.getFullYear();
	  var m = dt.getMonth() + 1;
	  m = m < 10 ? ('0' + m) : m;
	  var d = dt.getDate();
 	  d = d < 10 ? ('0' + d) : d;
    var h = dt.getHours()<10?('0' + dt.getHours()) : dt.getHours();
	  var minute = dt.getMinutes();
	  minute = minute < 10 ? ('0' + minute) : minute;
	  let time = y + '-' + m + '-' + d + ' ' + h+':'+minute;
    return time
  } 
  const pickerData = (date)=>{
    const dt = date?.$d
    var y = dt.getFullYear();
	  var m = dt.getMonth() + 1;
	  m = m < 10 ? ('0' + m) : m;
	  var d = dt.getDate();
 	  d = d < 10 ? ('0' + d) : d;
    var h = dt.getHours()<10?('0' + dt.getHours()) : dt.getHours();
	  var minute = dt.getMinutes();
	  minute = minute < 10 ? ('0' + minute) : minute;
	  let time = y + '-' + m + '-' + d;
    return time
  }
  return {pickerDataTime,pickerData}
}