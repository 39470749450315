import React ,{useState,useImperativeHandle} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
const Authority = (props,ref) => {
  const [title,setTitle] = useState('')
  const [authorityOpen,setAuthorityOpen] = useState(false)
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setTitle(e)
      setAuthorityOpen(true)
    }
  }))
  const handleAuthorityClose = ()=>{
    // setAuthorityOpen(false)
  }
  const handleVerify = async()=>{
    var Intent = plus.android.importClass("android.content.Intent");
		var Settings = plus.android.importClass("android.provider.Settings");
		var Uri = plus.android.importClass("android.net.Uri");
		var mainActivity = plus.android.runtimeMainActivity();
		var intent = new Intent();
		intent.setAction(Settings.ACTION_APPLICATION_DETAILS_SETTINGS);
		var uri = Uri.fromParts("package", mainActivity.getPackageName(), null);
		intent.setData(uri);
		mainActivity.startActivity(intent)
  }
  return (
    <>
      <div className="apply-for">
        <Dialog
          className="apply-for_dialog"
          open={authorityOpen}
          onClose={handleAuthorityClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        > 
          <div className="apply-for_dialog-top"></div>                                                                                
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              您还没有开启{title}权限，请前往设置！
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleVerify} autoFocus>
              確認
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
export default Authority