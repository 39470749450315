import './index.less';
import {useParameter,useCustomHistory} from '@/hooks/useParameter.js'
import {useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Logo from '@/assets/images/login/TExLogo.svg';
import api from '@/api'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
const passwordRE =/^(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
import { useTranslation } from 'react-i18next'
import PhoneNumber from '@/components/PhoneNumber'
export default function Register(){
  const { t } = useTranslation()
  const histroy = useCustomHistory()
  const paramter = useParameter()
  const dispatch = useDispatch()
  const [status,setStatus] = useState(true)
  const [ text2 ,setText2] = useState('至少8個字符，1個數字和1個大寫字母')
  const [ text ,setText] = useState('至少8個字符，1個數字和1個大寫字母')
  const [state, setState] = useState({
    id:2,
    phone_number:'',
    phone_code:'852',
    password:'',
    re_password:'',
    user_type:paramter.id,
    bool:false,
  });
  useEffect(()=>{
    
  },[])
  const handleSumit = async()=>{
    if(!state.phone_number)return setStatus(false)
    if(!state.password)return setStatus(false)
    if(!passwordRE.test(state.password)){
      setText2('至少8個字符，1個數字和1個大寫字母')
      setStatus(false)
      return
    }
    if(!state.re_password){
      setText('至少8個字符，1個數字和1個大寫字母')
      setStatus(false)
      return 
    }
    if(state.re_password!=state.password){
      setText('兩次密碼不一致')
      setStatus(false)
      return
    }
    const {data,code,msg} = await api.user.GetUseSmsCode({
      phone_number:state.phone_number,
      is_register:0,
    })
    if(code != 0){
      dispatch(tipsReveal({message:msg,type:'error'}))
      return 
    }
    histroy.replace({pathname:`/entrance/code`,state:{state}})
  }
  return (
    <div className='forgot'>
      <div className="forgot-logo">
        <img src={Logo} alt="" />
      </div>
      <div className="forgot-form">
        <form action="">
          <PhoneNumber code={state.phone_code} phone={state.phone_number} status={status} formData={state} setFormData={setState}/>
          <TextField
            id="outlined-textarea"
            label="密碼"
            name="password"
            type="password"
            variant="outlined"
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            helperText={state.password&&state.password.length>=8 && passwordRE.test(state.password) || status  ?'':text2}
          />
          <TextField
            id="outlined-textarea"
            label="密碼"
            name="re_password"
            type="password"
            variant="outlined"
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            helperText={state.re_password&&state.re_password.length>=8&&state.re_password==state.password || status?'':text}
          />
          <div className="forgot-form_checkbox">
            {/* <FormControlLabel
              control={<Checkbox checked={state.bool}  name="bool" color="primary" onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.checked })}}/>}
              label="記住密碼"
            /> */}
          </div>
          <Button variant="contained" color="primary" onClick={handleSumit}>
            {t('login.changePassword')}
          </Button>
          <div className="forgot-form_login">
            <span onClick={()=>histroy.replace('/entrance/login')}>
              登入
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}
