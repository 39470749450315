const TipsReducers = (prevState={
  message:'',
  type:'success',
  open:false,
  icon:false,
},action)=>{
  var newStete = {...prevState}
  switch(action.type){
    case "TIPSREVEAL" :
      newStete.type = action.value.type
      newStete.message = action.value.message
      newStete.icon = action.value.icon
      newStete.open = true
      return newStete 
    case "TIPSOPEN" :
      newStete.open = false
      return newStete 
    default:
      return newStete
  }
}
export default TipsReducers