import api from '@/api'
import QRCode from 'qrcode-react';
import jsQR from 'jsqr'
import { daDK } from '@mui/x-date-pickers/locales'
import {useState,useEffect} from 'react'
import {tipsReveal,UserAction} from '@/redux/actions'
import { useDispatch } from 'react-redux';
// 個人信息
export function useUserInfo(){
  const dispatch = useDispatch()
  // 獲取個人信息
  const acquire = async(uid)=>{
    const {data,code,msg} = await api.shear.PostUserInfoView({uid})
    return {data,code,msg}
  }
  // 修改個人資料
  const edit = async(formData)=>{
    const {data,code,msg} = await api.user.PostUserInfo(formData)
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(UserAction())
    return {data,code,msg}
  }
  // 添加联系人
  const append = async(formData)=>{
    const {data,code,msg} = await api.user.PostUserFamily(formData)
    if(code != 0) dispatch(tipsReveal({message:msg,type:'error'}))
    return {data,code,msg}
  }
  return {acquire,edit,append}
}
// 證書
export function useCertificate(){
  // 獲取證書
  const acquire = async()=>{
    const {data,code,msg} = await api.user.GetUserCert()
    return {data,code,msg}
  }
  // 添加證書
  const append = async (formData)=>{
    const {data,code,msg} = await api.user.PostUserCert(formData)
    return {data,code,msg}
  }
  return {acquire,append}
}

// 錢包賬單
export function useWalletBill(){
  /**
   * 獲取錢包賬單
   * @param {string} bills_type 	0 消费 1 收入 2 全部
   */
  const acquire = async(formData)=>{
    const {data,code,msg,total} = await api.shop.GetUserWalletBills(formData);
    return {data,code,msg,total}
  }
  return {acquire}
}

// 識別圖中二維碼
export function useJsqr(){
  const dispatch = useDispatch()
  const recognize = (event)=>{
    return new Promise(function(resolve) {
    const canvas = document.getElementById('qrCanvas');
    const context = canvas.getContext('2d');
    if(!event.target?.files[0])return
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0);
          const imageData = context.getImageData(0, 0, img.width, img.height);
          const code = jsQR(imageData.data, img.width, img.height);
          console.log(code)
          if (code) {
            resolve(code?.data)
          } else {
            dispatch(tipsReveal({message:'識別錯誤',type:'error'}))
          }
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
    })
  }
  return {recognize}
}