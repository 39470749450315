// 分享/请求发布
import './index.less'
import React, { useState,useEffect, useRef, useMemo ,forwardRef} from 'react';
import {useFileUpload} from '@/hooks/useFileUpload.js'
import useTimeData from '@/hooks/useTimeData.js'
import {usePublishEvent} from '@/hooks/sharing.js'
import { useSelector } from 'react-redux'
import TopHead from '@/components/TopHead'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import api from '@/api'
import {useParameter} from '@/hooks/useParameter.js'
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FormHelperText from '@mui/material/FormHelperText';
import PreviewDrawer from './components/preview'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import IconAdd from '@/assets/images/shear/add.svg'
import { useTranslation } from 'react-i18next'
import {ServiceType} from '@/utils/custom.js'
import {shareObj,requestObj} from '@/utils/district.js'
import IntegerInput from '@/components/IntegerInput'
// 必填的表單
var requiredObj = {
  title:'',
  detail:'',
  price:'',
  start_time:'',
  end_time:'',
  location:'',
  num_of_people:''
}
const minDate = new Date();
const checkboxTitle = '請勾選以確認您可以將您的手機和聯絡咨詢分享給申請者，他們將協助您處理即將發佈的請求。*'
export default function SharingForm(){
  const { t } = useTranslation()
  const [ActivityType,setActivityType] = useState()
  const [typeList,setTypeList] = useState([])
  // 路由獲參
  const paramter = useParameter()
  const dispatch = useDispatch()
  // 上傳文件
  const {upload} = useFileUpload()
  // 添加修改
  const {publish,edit} = usePublishEvent()
  // 時間轉換
  const {pickerDataTime,pickerData} = useTimeData()
  // 預覽
  const PreviewDrawerDiv = forwardRef(PreviewDrawer)
  const previewDrawerRef = useRef(null)
  // 是否提示表單驗證
  const [status,setStatus] = useState(true)
  const [imageList,setImageList] = useState([])
  // 是否需要現金支付
  const [cashBool,setCashBool] = useState('不會')
  // 勾選協議
  const [agreement,setAgreement] = useState(true)
  // 編輯的時候内容是否修改
  const [forbidden,setForbidden] = useState(true)
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const [formData,setFormData] = useState({
    event_id:'',
    banner: "", 
    image: "", 
    type: paramter.type, 
    title: "", 
    key: [{
      start_date:'',
      start_time:''
    }], 
    detail: "", 
    price: '', 
    pay_method: "", 
    start_date: "", 
    start_time: "", // 上架日期
    end_time: "", // 下架日期
    duration: "", 
    location: "", 
    tb_c: "", 
    which_marketplace: redusxUser.user_type,
    is_share:paramter.id, // 0請求 1分享
    num_of_people:'',
    is_product:paramter.sort,
  })
  const currencies = [
    {
      value: 0,
      label: '區區通巿集',
    },
    {
      value: redusxUser.user_type,
      label: '本區巿集',
    },
  ];
  useEffect(()=>{
    setForbidden(false)
  },[formData])
  useEffect(()=>{
    // 判斷服務類型
    setActivityType(ServiceType(paramter.type))
    // 獲取類型列表
    setTypeList(paramter.id == 1 ? shareObj : requestObj)
    // 如果是編輯則獲取詳情
    console.log(paramter)
    if(paramter.event_id!= null){
      handleEventDetail()
    }
  },[])
  const handleEventDetail = async ()=>{
    const {data,code,msg} = await api.home.GetCommonEventActiveDetail({
      event_id:paramter.event_id
    })
    console.log(data.image.split(','))
    setImageList(data.image.split(','))
    if(data.add_cash>=0 || data.add_cash){
      setCashBool('會')
    }
    data.key = JSON.parse(data.key) 
    setFormData({...data})
  }

  const handlePreview = ()=>{
    // histroy('/sharing/preview')
    previewDrawerRef.current.handleOpen(formData)
  } 
  const handleUploadBanner = async (e)=>{
    const {data,code,msg,progress} = await upload(e)
    if(code != 0)return 
    setFormData({ ...formData, ['banner']: data.src })
  }
  // 删除banner图
  const handleDeleteBanner = (event)=>{
    event.stopPropagation()
    setFormData({ ...formData, ['banner']: '' })
  }
  const handleUploadImage = async (e)=>{
    const {data,code,msg,progress} = await upload(e)
    if(code != 0)return 
    var obj = [...imageList]
    obj.push(data.src)
    setImageList(obj)
    setTimeout(()=>{
      e.target.value = ""
    },500)
  }
  const handleDeleteImage = (index)=>{
    var arr = [...imageList];
    arr.splice(index, 1);
    setImageList(arr)
  }
  // 請求添加日期
  const handlePushDate = ()=>{
    var obj = formData;
    obj.key.push({
      start_date:'',
      start_time:''
    })
    setFormData({ 
      ...obj
    })
  }
  const handleDeleteDate = (index)=>{
    var obj = formData;
    obj.key.splice(index,1)
    setFormData({ 
      ...obj
    })
  }
  // 開始時間
  const handleDueDate = (date,index)=>{
    var obj = formData;
    obj.key[index].start_date = pickerData(date)
    setFormData({ 
      ...obj
     })
  }
  // 結束時間
  const handleStartingDate = (date,index)=>{
    var obj = formData
    obj.key[index].start_time = pickerData(date)
    setFormData({ 
      ...obj
     })
  }
  // 是否需要現金支付
  const handleCashPayment = (event)=>{
    setFormData({ ...formData, ['add_cash']: '' })
    setCashBool(event.target.value)
  }
  // 所需現金
  const handleCash = (event)=>{
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }
  const handleSave = async()=>{
    console.log(formData)
    // if(!formData.banner)return dispatch(tipsReveal({message:t('sharing.upload'),type:'error'}))
    for(let i in requiredObj){
      if(!formData[i])return setStatus(false)
    }
    if(ActivityType == '產品'){
      if(formData.is_share == 1){
        for(let i in formData.key){
          if(!formData.key[i].start_time)return setStatus(false)
        }
      }
    }else{
      for(let i in formData.key){
        if(!formData.key[i].start_date)return setStatus(false)
        if(!formData.key[i].start_time)return setStatus(false)
      }
    }
    if(formData.is_share == 0){
      if(!agreement)return dispatch(tipsReveal({message:'請勾選協議',type:'error'}))
    }
    formData.image = imageList.toString()
    const {data,code,msg} = await publish(formData)
    if(code != 0)return
    window.history.go(-1)
  }
  // 編輯
  const handleEdit = async () =>{
    console.log(formData)
    // if(!formData.banner)return dispatch(tipsReveal({message:t('sharing.upload'),type:'error'}))
    for(let i in requiredObj){
      if(!formData[i])return setStatus(false)
    }
    if(ActivityType == '產品'){
      if(formData.is_share == 1){
        for(let i in formData.key){
          if(!formData.key[i].start_time)return setStatus(false)
        }
      }
    }else{
      for(let i in formData.key){
        if(!formData.key[i].start_date)return setStatus(false)
        if(!formData.key[i].start_time)return setStatus(false)
      }
    }
    if(formData.is_share == 0){
      if(!agreement)return dispatch(tipsReveal({message:'請勾選協議',type:'error'}))
    }
    formData.image = imageList.toString()
    const {data,code,msg} = await edit(formData)
    if(code != 0)return
    window.history.go(-1)
  }
  const handleStateDataLabel = (type,index)=>{
    return ActivityType == '活動' && `活動${type == 1 ? '舉行' : '結束'}日期及時間*` || `${formData.is_share == 1 ?ActivityType:'請求'}${type == 1 ? '開始' : '結束'}日期及時間${formData.key.length > 1 ? (index+1) : ''}*`
  }

  // 上架日期最小日期
  const handleShelfDateMin = ()=>{
    var min;
    if(ActivityType == '產品'){
      min = formData.key[0].start_time ? dayjs(formData.key[0].start_time):formData.end_time ? dayjs(formData.end_time) : dayjs(minDate) ;
    }else{
      min = formData.key[0].start_date ? dayjs(formData.key[0].start_date):formData.end_time ? dayjs(formData.end_time) : dayjs(minDate) 
    }
    return min 
  }
  // 上架日期最大日期
  const handleShelfDateMax = ()=>{
    var max;
    if(ActivityType == '產品'){
      max = formData.key[0].start_date ? dayjs(formData.key[0].start_date):formData.end_time ? dayjs(formData.end_time) : dayjs(minDate);
    }else{
      max = formData.key[0].start_time ? dayjs(formData.key[0].start_time):formData.end_time ? dayjs(formData.end_time) : dayjs(minDate);
    }
    
    return max 
  }
  return (
    <>
      <input accept="image/*" id="icon-banner-file" className="icon-button-file" type="file" onChange={handleUploadBanner}/>
      <input accept="image/*" id="icon-image-file" className="icon-button-file" type="file" onChange={handleUploadImage}/>
      <div className="sharing-form">
        <TopHead title={`填寫${formData.is_share == 1 ?ActivityType:'請求'}資料`} />
        {/* <PageCache> */}
        <div className="sharing-form_main">
          <form action="">
            <div className="form-upload" htmlFor="icon-banner-file">
              {formData.banner && <div className="form-uiload_img">
                <svg onClick={handleDeleteBanner} t="1731226469731" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1568" width="200" height="200"><path d="M827.392 195.584q65.536 65.536 97.792 147.456t32.256 167.936-32.256 167.936-97.792 147.456-147.456 98.304-167.936 32.768-168.448-32.768-147.968-98.304-98.304-147.456-32.768-167.936 32.768-167.936 98.304-147.456 147.968-97.792 168.448-32.256 167.936 32.256 147.456 97.792zM720.896 715.776q21.504-21.504 18.944-49.152t-24.064-49.152l-107.52-107.52 107.52-107.52q21.504-21.504 24.064-49.152t-18.944-49.152-51.712-21.504-51.712 21.504l-107.52 106.496-104.448-104.448q-21.504-20.48-49.152-23.04t-49.152 17.92q-21.504 21.504-21.504 52.224t21.504 52.224l104.448 104.448-104.448 104.448q-21.504 21.504-21.504 51.712t21.504 51.712 49.152 18.944 49.152-24.064l104.448-104.448 107.52 107.52q21.504 21.504 51.712 21.504t51.712-21.504z" p-id="1569" fill="#d81e06"></path></svg>
                <img src={api.url + formData.banner} alt="" />
              </div>}
              {!formData.banner &&<label className="form-uiload_text" htmlFor="icon-banner-file">
                <div className="upload-text_title">
                  <img src={IconAdd} alt="" />
                  <span>{t('sharing.upload')}</span>
                </div>
                <div className="upload-text_des">
                  {t('sharing.uploadDetails')} <br />
                  {t('sharing.uploadDes')}
                </div>
              </label>}
            </div>
            <div className="form-list">
              {imageList.map((item,index)=>item && <div key={item} className="list-item">
                <svg onClick={()=>handleDeleteImage(index)} t="1731227451376" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1996" width="200" height="200"><path d="M887.982 353.621c-20.511-48.423-49.759-91.872-87.139-129.251s-80.828-66.628-129.251-87.139c-50.123-21.238-103.402-31.918-158.257-31.918s-108.134 10.801-158.257 31.918c-48.423 20.511-91.872 49.759-129.251 87.139-37.379 37.379-66.628 80.828-87.139 129.251-21.238 50.123-31.918 103.402-31.918 158.257s10.801 108.134 31.918 158.257c20.511 48.423 49.759 91.872 87.139 129.251 37.379 37.379 80.828 66.628 129.251 87.139 50.123 21.238 103.402 31.918 158.257 31.918s108.134-10.801 158.257-31.918c48.423-20.511 91.872-49.759 129.251-87.139 37.379-37.379 66.628-80.828 87.139-129.251 21.238-50.123 31.918-103.402 31.918-158.257s-10.681-108.134-31.918-158.257zM683.364 645.499c11.286 11.286 11.286 29.855 0 41.142s-29.855 11.286-41.142 0l-132.528-132.406-132.406 132.528c-11.286 11.286-29.855 11.286-41.142 0s-11.286-29.855 0-41.142l132.406-132.406-132.528-132.528c-11.286-11.286-11.286-29.855 0-41.142 11.286-11.286 29.855-11.286 41.142 0l132.406 132.406 132.406-132.406c11.286-11.286 29.855-11.286 41.142 0s11.286 29.855 0 41.142l-132.164 132.406 132.406 132.406z" fill="#d81e06" p-id="1997"></path></svg>
                <img src={api.url + item}/>
              </div>)}
              {formData.image.split(",").length<= 4 &&<label className="list-add" htmlFor="icon-image-file">
                <img src={IconAdd} alt="" />
              </label>}
            </div>
            <div className="form-input">
              <TextField
                select
                label={t('sharing.category')}
                variant="outlined"
                value={formData.type}
                name="type"
                disabled
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.type || status?'':t('sharing.selectCategory')}
              >
                {typeList.map((option,index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={`${ActivityType}名稱*`}
                multiline
                variant="outlined"
                name="title"
                value={formData.title}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.title || status?'':`請輸入${ActivityType}名稱`}
              />
              <TextField
                label={ActivityType == '產品'?'產品詳情*':'内容/詳情*'}
                multiline
                rows={4}
                variant="outlined"
                name="detail"
                value={formData.detail}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.detail || status?'':'請輸入詳細内容'}
              />
              <div className="form-input_flex">
                <IntegerInput label={'所需時分*'}  value={formData.price} name="price"  status={status} formData={formData} setFormData={setFormData} />
                {ActivityType == '產品' && <IntegerInput label={'數量'}  value={formData.num_of_people} name="num_of_people"  status={status} formData={formData} setFormData={setFormData} />}
              </div>
              <div className="form-input_flex">
                <TextField
                  className="form-input_flex_2"
                  select
                  label={'需要額外現金支付？*'}
                  variant="outlined"
                  name="cashBool"
                  value={cashBool}
                  onChange={(event)=>{handleCashPayment(event)}}
                  helperText={cashBool || status?'':'請選擇是否需要額外現金支付'}
                >
                  <MenuItem value="會">
                    會
                  </MenuItem>
                  <MenuItem value="不會">
                    不會
                  </MenuItem>
                </TextField>
                {cashBool == '會' && <IntegerInput label={'$'} value={formData.add_cash} name={"add_cash"} status={status} formData={formData} setFormData={setFormData} />}
              </div>
              {ActivityType != '產品' && <IntegerInput label={'可報名人數'} value={formData.num_of_people} name="num_of_people" status={status} formData={formData} setFormData={setFormData} />}
              {/* 產品 */}
              {(ActivityType == '產品' && formData.is_share == 1) &&  formData.key.map((item,index)=><>
                <div><LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label={'產品到期日'} name="start_date" onChange={(event)=>handleDueDate(event,index)} defaultValue={dayjs(formData.key[index].start_date)} value={dayjs(formData.key[index].start_date)} minDate={formData.key[index].start_time ? dayjs(formData.key[index].start_time) : dayjs(1999-1-1)}/>
                  {/* {formData.key[index].start_date || status?'':<FormHelperText>請選擇產品到期日</FormHelperText>} */}
                </LocalizationProvider></div>
                <div><LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label={'產品製造日期*'} name="start_time" onChange={(event)=>handleStartingDate(event,index)} defaultValue={dayjs(formData.key[index].start_time)} value={dayjs(formData.key[index].start_time)} maxDate={dayjs(formData.key[index].start_date)}/>
                  {formData.key[index].start_time || status?'':<FormHelperText>請選擇產品製造日期</FormHelperText>}
                </LocalizationProvider></div>
              </>)}
              {/* 活動 / 服務 */}
              {ActivityType != '產品' && formData.key.map((item,index)=><div className="date-list">
                <div className="date-list_left">
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker label={handleStateDataLabel(1,index)} name="start_date" onChange={(event)=>handleDueDate(event,index)} defaultValue={dayjs(formData.key[index].start_date)} value={dayjs(formData.key[index].start_date)} minDateTime={dayjs(minDate)} maxDateTime={dayjs(formData.key[index].start_time)}/>
                    {formData.key[index].start_date || status?'':<FormHelperText>請選擇{handleStateDataLabel(1,index)}</FormHelperText>}
                </LocalizationProvider></div>
                <div><LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker label={handleStateDataLabel(2,index)}  name="start_time" onChange={(event)=>handleStartingDate(event,index)} defaultValue={dayjs(formData.key[index].start_time)} value={dayjs(formData.key[index].start_time)} minDateTime={formData.key[index].start_date ? dayjs(formData.key[index].start_date) : dayjs(minDate)}/>
                  {formData.key[index].start_time || status?'':<FormHelperText>請選擇{handleStateDataLabel(2,index)}</FormHelperText>}
                </LocalizationProvider></div>
                </div>
                {index >= 1 && <div className="date-list_delete">
                  <svg onClick={()=>handleDeleteDate(index)} t="1731230288162" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3322" width="200" height="200"><path d="M512 56.888889C261.063111 56.888889 56.888889 261.063111 56.888889 512s204.174222 455.111111 455.111111 455.111111 455.111111-204.174222 455.111111-455.111111-204.174222-455.111111-455.111111-455.111111m0-56.888889a512 512 0 1 1 0 1024A512 512 0 0 1 512 0z" fill="#C82529" p-id="3323"></path><path d="M811.406222 463.189333v93.525334H208.327111V463.189333z" fill="#C82529" p-id="3324"></path></svg>
                </div>}
              </div>)}
              {/* 只有請求有添加時間按鈕 */}
              {(formData.is_share == 0 && ActivityType != '產品') && <div className="request-form_add" onClick={handlePushDate}>
                <div className="request-form_add-title">按”+”可增加需要幫助的日子</div>
                <div className="request-form_add-div">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 13.6602H13V20.6602H11V13.6602H4V11.6602H11V4.66016H13V11.6602H20V13.6602Z" fill="#CE4C09"/>
                  </svg>
                </div>
              </div>}
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker  label={'上架日期及時間*'}  name="start_time" onChange={(date)=>setFormData({ ...formData, ['start_time']: pickerDataTime(date) })} defaultValue={dayjs(formData.start_time)} value={dayjs(formData.start_time)} minDateTime={handleShelfDateMin()} maxDateTime={handleShelfDateMax()}/>
                  {formData.start_time || status?'':<FormHelperText>請選擇上架日期及時間</FormHelperText>}
                </LocalizationProvider>
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker label={'下架日期及時間*'}  name="end_time" onChange={(date)=>setFormData({ ...formData, ['end_time']: pickerDataTime(date) })} defaultValue={dayjs(formData.end_time)} value={dayjs(formData.end_time)} minDateTime={formData.start_time ? dayjs(formData.start_time) : formData.key[0].start_date ? dayjs(formData.key[0].start_date) : dayjs(minDate)}/>
                  {formData.end_time || status?'':<FormHelperText>請選擇下架日期及時間</FormHelperText>}
                </LocalizationProvider>
              </div>
              <TextField
                label={'交易地點*'}
                multiline
                variant="outlined"
                name="location"
                value={formData.location}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.location || status?'':'請輸入交易地點'}
              />
              <TextField
                select
                label={t('sharing.whichMarket')}
                variant="outlined"
                name="which_marketplace"
                value={formData.which_marketplace}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
              >
                {currencies.map((option,index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* 只有請求才有此選項 */}
              {formData.is_share == 0 && <div className="request-form_checkbox">
                <FormControlLabel
                  label={checkboxTitle}
                  control={<Checkbox checked={agreement} name="agreement" color="primary" onChange={(event)=>{setAgreement(event.target.checked)}}/>}
                />
              </div>}
            </div>
            <div className="form-button">
              <Button variant="outlined" color="primary" onClick={handlePreview}>
                {t('sharing.preview')}
              </Button>
              {paramter.event_id == null && <Button variant="contained" color="primary" onClick={handleSave}>
                獲取批准
              </Button>}
              {paramter.event_id != null && <Button variant="contained" disabled={forbidden} color="primary" onClick={handleEdit}>
                {t('sharing.editor')}
              </Button>}
            </div>
          </form>
        </div>
        {/* </PageCache> */}
      </div>
      <PreviewDrawerDiv ref={previewDrawerRef} />
    </>
  )
}
