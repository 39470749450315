import { useDispatch } from 'react-redux'
import {logoutAction,UserClearAction} from '@/redux/actions'
import {useHistory} from 'react-router-dom'
const useLogout = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const logout = ()=>{
    history.replace('/entrance/login')
    dispatch(logoutAction())
    dispatch(UserClearAction())
  }
  return {logout}
}
export default useLogout