import './index.less'
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import api from '@/api'
import {useLocation,useHistory } from 'react-router-dom'
import {useUserInfo} from '@/hooks/user.js'
import {shiftTime} from '@/utils/shifttime'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux';
import {ServiceType,whatsContact} from '@/utils/custom.js'
import {useApplicant} from '@/hooks/sharing.js'
export default function ShearCard({item,handleService,type,refresh}){
  const { t } = useTranslation()
  const {cancel} = useApplicant()
  const histroy = useHistory()
  const {acquire} = useUserInfo()
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const handleSumit = ()=>{

  }
  // 开始服务
  const handleCardService = ()=>{
    handleService(item)
  }

  
  const handleDetail = ()=>{
    histroy.push(`/sharing/details/?id=${item.event_id}`)
  }
  // 處理申請
  const handleApplyFor = ()=>{
    histroy.push(`/sharing/applicant/?id=${item.event_id}`)
  }
  // 取消交易
  const handleCancel = async()=>{
    const {data,code,msg} = await cancel(item.join_id)
    if(code != 0)return 
    refresh(item)
  }
  /**
   * 如果是我发布的 is_share 是1 则是让别人付款
   * 如果is_share是0则是给别人付款
   */
  const handlePayment = ()=>{
    histroy.push(`/sharing/payment/?id=${item.event_id}&is_share=${item.is_share}`)
  }
  // 请求付款
  const handleRequestPayment = ()=>{
    histroy.push(`/sharing/send/payment/?id=${item.event_id}&join_id=${item.join_id}&is_share=${1}`)
  }
  const handleStart = ()=>{
    histroy.push(`/sharing/send/payment/?id=${item.event_id}&join_id=${item.join_id}&is_share=${0}`)
  }
  // 联络对方
  const handleContact = async()=>{
    const {data,code,msg} = await acquire(item.uid)
    whatsContact(data?.phone_number)
  }
  return (
    <>
      <div className="shear-card">
        <div className="card-main" onClick={handleDetail}>
          {/* 
            is_approve 審核狀態 0-待審核 1-審核通過 2-審核失敗
            wait_approve_num 報名的人數
           */}
            {(type == 'shear' || type == 'request') && <>
              {item.is_approve == 0 && <div className="shear-card_tips shear-card_mistake">
                等待獲得批準
              </div>}
              {(item.is_approve == 1 && item.count_choose_0<=0) && <div className="shear-card_tips">
                已成功上架，等待買家申請交易中
              </div>}
              {(item.is_approve == 1 && item.count_choose_0>0) && <div className="shear-card_tips shear-card_applicant">
                有申請者提出協助
              </div>}
              {item.is_approve == 2 && <div className="shear-card_tips shear-card_mistake">
                已拒絕批準，請查看指引要求
              </div>}
            </>}
            {/* 
              is_choose 審核狀態 0 提交申請 1 同意申請 2 拒絕申請
             */}
            {(type == 'service' || type == 'participate') && <>
              {(item?.bills_id && item.is_choose == 1) && <div className="shear-card_tips shear-card_applicant">交易完成</div>}
              {item.is_choose == 0 && <div className="shear-card_tips shear-card_mistake">
                已提出協助請求
              </div>}
              {(item.is_choose == 1 && !item.bills_id) && <div className="shear-card_tips shear-card_applicant">
                對方已接受你的協助申請
              </div>}
              {item.is_choose == 2 && <div className="shear-card_tips shear-card_mistake">
                對方已拒絕您的協助申請
              </div>}
            </>}  
            {/* {type == 'participate' && <>
              <div className="shear-card_tips shear-card_applicant">
                賣方已同意了你的申請及聯絡
              </div>
            </>} */}
          <div className="shear-card_img">
            <img src={api.url + item.banner} alt="" />
          </div>
          <div className="shear-card_info">
            <div className="info-title">
              {item.title}
            </div>
            <div className="info-tag">
              <span>{ServiceType(item?.type,'name')}</span>
              <Divider orientation="vertical" flexItem />
              <span>{item.price} {t('my.hm')}</span>
              <Divider orientation="vertical" flexItem />
              <span>{shiftTime(item.create_time)}</span>
            </div>
            <div className="info-intro">
              <span className="info-intro_dec">
                {item.detail}
              </span>
              <span>
                {t('sharing.readMore')}
              </span> 
            </div>
          </div>
        </div>
        <div className="card-button">
          {/* 自己的分享 */}
          {type == 'shear' && <>
            {
              <>
                <Button variant="contained" color="primary" disabled={item.count_choose_0<=0 || item.is_approve == 0 || item.is_approve == 2} onClick={handleApplyFor}>
                  處理申請
                </Button>
                <Button variant="contained" color="primary" disabled={(item.count_choose_1<=0 || item.is_approve == 0 || item.is_approve == 2) || item.bills_id} onClick={handlePayment}>
                  請求付款
                </Button>
              </>
            }
          </>}
          {/* 提出請求 */}
          {type == 'request' && <>
            {
              <>
                <Button variant="contained" color="primary" disabled={item.count_choose_0<=0 || item.is_approve == 0 || item.is_approve == 2} onClick={handleApplyFor}>
                  處理申請
                </Button>
                {item.bills_id != '' && <Button variant="contained" color="primary" disabled={item.count_choose_1<=0 || item.is_approve == 0 || item.is_approve == 2} onClick={handlePayment}>
                  付款
                </Button>}
                {item.bills_id == '' && <Button variant="contained" color="primary" disabled>
                  已付款
                </Button>}
              </>
            }
          </>
          }
          {/* 我的購買 */}
          {type == 'participate' && <>
            <div className="card-button_flex">
              <Button className="card-button_cancel" variant="contained" color="primary" onClick={handleCancel}>
                取消交易
              </Button>
              <Button variant="contained" color="primary" disabled={item.is_choose != 1} onClick={handleContact}>
                聯絡對方
              </Button>
            </div>
            <Button variant="contained" color="primary" disabled={(Number(item?.wait_pay_money) <= 0  || item.bills_id)} onClick={handleStart}>
              發送付款
            </Button>
          </>}
          {/* 我的幫助 */}
          {type == 'service' && <>
          <div className="card-button_flex">
              <Button className="card-button_cancel" variant="contained" color="primary" onClick={handleCancel}>
                取消交易
              </Button>
              <Button variant="contained" color="primary" disabled={item.is_choose != 1} onClick={handleContact}>
                聯絡對方
              </Button>
            </div>
            <Button variant="contained" color="primary" disabled={item.is_choose != 1 || item.bills_id} onClick={handleRequestPayment}>
              請求付款
            </Button>
          </>}
        </div>
      </div>
    </>
  )
}