import './index.less'
import React from 'react'
import {useHistory} from 'react-router-dom'
import Back from '@/assets/images/shop/back.svg'
function TopHead(props){
  const histroy = useHistory()
  const handleBack = ()=>{
    window.history.go(-1)
  }
  const handleRight = ()=>{

  }
  return (
    <>
      <div className="top-head">
        <img src={Back} className="top-head_left" alt="" onClick={handleBack} />
        <div className="top-head_title">
          {props.title}
        </div>
        <div className="top-head_right" onClick={handleRight}>
          {props?.children}
        </div>
      </div>
    </>
  )
}

export default React.memo(TopHead)