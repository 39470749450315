
import { useEffect,useState,forwardRef,useRef } from 'react';
import {useHistory} from 'react-router-dom'
import Button from '@mui/material/Button';
import api from '@/api'
import ShearCard from '../Card'
import ServiceDialog from '../ServiceDialog'
import { useInView } from "react-cool-inview";
export default function Participate(){
  const history = useHistory()
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const ServiceDialogDiv = forwardRef(ServiceDialog)
  const ServiceDialogRef = useRef(null)
  const [formData,setFormData] = useState({
    limit:10,
    page:1,
    is_share:1
  })
  useEffect(()=>{
    handleSecondhand()
  },[formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  const handleSecondhand = async ()=>{
    const {data,code,msg,total:count} = await api.shear.GetUserEventJoinList(formData);
    console.log(data,code,msg)
    setTotal(count)
    if(data.length == undefined){
      setList([])
      return 
    }
    setList([...data])
  }
  const refresh = (e)=>{
    var list = serviceList.filter(item => item.event_id !== e.event_id)
    setTotal(total-1)
    setList(list)
  }
  const handleService = (e)=>{
    ServiceDialogRef.current.handleOpen(e)
  }
  const handleMarketplace = ()=>{
    history.replace(
      `/home/?type=all`,
    )
  }
  return (
    <>
      <ServiceDialogDiv ref={ServiceDialogRef} />
      {list.length <= 0 && <div className="shear-box">
        <div className="shear-box_add">
          {/* <img src={IconAdd} alt="" /> */}
          <span>你還沒有購買任何東西</span>
        </div>
      </div>}
      {list.map((item,index)=><div key={item.event_id + index} ref={index === list.length - 1 ? observe : null}><ShearCard refresh={refresh} item={item} type={'participate'} handleService={handleService}/></div>)}
      <div className='shear-btn'>
        <Button variant="contained" color="primary" onClick={()=>handleMarketplace()}>
          去市場逛逛
        </Button>
      </div>
    </>
  )
}

