const UserReducer = (prevState={
  user:{},
  balance:0,
  sysBalance:0,
  address:'',
},action)=>{
  var newStete = {...prevState}
  switch(action.type){
    case "USER" :
      newStete.user = action.value
      return newStete 
    case "BALANCE" :
      newStete.address = action?.value.wallet_id;
      if(action?.value == NaN){
        newStete.balance = 0;
        newStete.sysBalance = 0
      }else{
        newStete.balance = Number(action.value.tb);
        newStete.sysBalance = Number(action.value.sys_tb)
      }
      
      return newStete
    case "USERCLEAR" :
      newStete.user = {}
      return newStete
    default:
      return newStete
  }
}
export default UserReducer